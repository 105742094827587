import { Epic } from "redux-observable";
import { EMPTY, from, of } from "rxjs";
import { filter, catchError, switchMap, map } from "rxjs/operators";
import { RootAction, RootState, isActionOf } from "typesafe-actions";
import {
  CustomerResponseOptions,
  DetailsAPIResponse,
  IOSEvent,
  Meeting,
  Note,
} from "../../../models/";
import {
  addNewEvent,
  addNewOrderNote,
  assingMounter,
  cancelOrder,
  deleteEvent,
  deleteNewOrderNote,
  editEvent,
  editNewOrderNote,
  filterCalendars,
  getAllEvents,
  getCustomerResponseOption,
  getEvents,
  getGBSAction,
  getOrderDetailAction,
  initDetails,
  reAssignToGB,
  setCustomerResponseOption,
} from "./actions";
import {
  fullAddress,
  fullName,
  getRandomColor,
  handleEventToCalendar,
} from "../../../helpers/";
import { isPlatform } from "@ionic/react";
import { getDeviceCalendarEvent } from "../../../helpers/deviceCalendar";
import { showNotification } from "../../Notification/redux/actions";
import { API_NOTE, API_ORDER_DETAIL, OFFER } from "../../../models/apiModels";
import moment from "moment";
import {
  getAssignedGBMeetings,
  apiVersionWrapper,
  getAllMeetings,
} from "../../../helpers/api";
import { createOffer, updateOffer } from "../../OfferDetail/redux/actions";
import { deleteLead } from "../../Leads/redux/actions";

export interface API_GB {
  id_gb_user: number;
  id_shop: number;
  id_gb_user_role: number;
  id_gb_user_team: number;
  name: string;
  lastname: string;
  email: string;
  color: string;
}

// const pouchDb = initDetailsDB();

const shouldDeleteLead = (id_customer_response: number) => {
  const leadDeletionsIDs = [
    1, 2, 5, 6, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
  ];
  return leadDeletionsIDs.includes(Number(id_customer_response)) ? true : false;
};
const getCustomerResponseOptionsAPI = (token: string) => {
  const API_URL = `${process.env.REACT_APP_API_URL}getCustomerResponseOptions`;
  return apiVersionWrapper(API_URL, "GET", {
    Authorization: `Bearer ${token}`,
  }).pipe(
    map((response) => {
      const customerOptions: CustomerResponseOptions[] = response.response;
      return customerOptions;
    }),
    catchError((error: Error) => {
      return of(error);
    })
  );
};
const setCustomerResponseOptionsAPI = (
  token: string,
  order_id: number,
  id_gardinbus_customer_response: number,
  order_note: string,
  alarm?: string
) => {
  const API_URL = `${process.env.REACT_APP_API_URL}setCustomerResponse/${order_id}`;
  return apiVersionWrapper(
    API_URL,
    "PUT",
    {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    JSON.stringify({
      id_gardinbus_customer_response,
      order_note,
      alarm,
    })
  ).pipe(
    map((response) => {
      const allOk: boolean = response.response;
      return allOk;
    }),
    catchError((error: Error) => {
      return of(error);
    })
  );
};

const rescheduleMeasurring = (
  token: string,
  id_order: number,
  id_fitter: number,
  gardinbus_measuring_date: string,
  gardinbus_measuring_date_end: string,
  notify: boolean,
  order_note?: string
) => {
  const API_URL = `${process.env.REACT_APP_API_URL}rescheduleMeasuring/${id_order}`;
  return apiVersionWrapper(
    API_URL,
    "PUT",
    {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    JSON.stringify({
      id_fitter,
      gardinbus_measuring_date,
      gardinbus_measuring_date_end,
      order_note: order_note ? order_note : null,
      notify,
    })
  ).pipe(
    map((response) => {
      const allOk: boolean = response.response;
      return allOk;
    }),
    catchError((error: Error) => {
      return of(error);
    })
  );
};
const assignMounterAPI = (
  token: string,
  id_mounter: number,
  id_order: number,
  gardinbus_mounting_date?: string
) => {
  //
  const API_URL = `${process.env.REACT_APP_API_URL}assignMounter/${id_order}`;
  return apiVersionWrapper(
    API_URL,
    "PUT",
    {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    JSON.stringify({
      id_mounter: id_mounter,
      gardinbus_mounting_date: gardinbus_mounting_date,
    })
  ).pipe(
    map((response) => {
      const allOk: boolean = response.response;
      return allOk;
    }),
    catchError((error: Error) => {
      return of(error);
    })
  );
};
const addNoteAPI = (token: string, id_order: number, note: string) => {
  const API_URL = `${process.env.REACT_APP_API_URL}addNote`;
  return apiVersionWrapper(
    API_URL,
    "POST",
    {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    JSON.stringify({
      id_order,
      note,
    })
  ).pipe(
    map((response) => {
      const note_id: number = response.response;
      return note_id;
    }),
    catchError((error: Error) => {
      return of(error);
    })
  );
};
const editNoteAPI = (token: string, id_note: number, note: string) => {
  const API_URL = `${process.env.REACT_APP_API_URL}updateNote/${id_note}`;
  return apiVersionWrapper(
    API_URL,
    "PUT",
    {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    JSON.stringify({
      note,
    })
  ).pipe(
    map((response) => {
      const allOk: boolean = response.response;
      return allOk;
    }),
    catchError((error: Error) => {
      return of(error);
    })
  );
};
const deleteNoteAPI = (token: string, id_note: number) => {
  const API_URL = `${process.env.REACT_APP_API_URL}deleteNote/${id_note}`;
  return apiVersionWrapper(API_URL, "DELETE", {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  }).pipe(
    map((response) => {
      const allOk: boolean = response.response;
      return allOk;
    }),
    catchError((error: Error) => {
      return of(error);
    })
  );
};
const getGBs = (token: string) => {
  const API_URL = `${process.env.REACT_APP_API_URL}getUsers/1`; // 1- fitter 2- mounter
  //TODO Hardcoded value
  return apiVersionWrapper(API_URL, "GET", {
    Authorization: `Bearer ${token}`,
  }).pipe(
    map((response) => {
      const gbs: API_GB[] = response.response;

      return gbs;
    }),
    catchError((error: Error) => {
      return of(error);
    })
  );
};

const getOrderDetail = (token: string, orderID: number) => {
  const API_URL = `${process.env.REACT_APP_API_URL}getOrderDetails/${orderID}`;
  return apiVersionWrapper(API_URL, "GET", {
    Authorization: `Bearer ${token}`,
  }).pipe(
    map((response) => {
      const orderDetail: API_ORDER_DETAIL = response.response;
      return orderDetail;
    }),
    catchError((error: Error) => {
      return of(error);
    })
  );
};

// async function loadFromStorage(orderID: number) {
//   const ordersDetails = await storageGet<OrderDetail[]>("ordersDetails");
//   const storageGbs = await storageGet<GBArray>("gbs");
//   const storageOrderDetail = ordersDetails?.find(
//     (o) => o.order_id === Number(orderID)
//   );
//   if (storageGbs && storageOrderDetail) {
//     const orderDetail = storageOrderDetail;
//     const gbs = storageGbs;
//     const assignedGb =
//       orderDetail && orderDetail.meeting
//         ? gbs[orderDetail.meeting.operatorID]
//         : null;
//     return { orderDetail, gbs, assignedGb };
//   }
//   // else{} do api call
// }

export const getOrderDetailEpic: Epic<RootAction, RootAction, RootState> = (
  action$
) =>
  action$.pipe(
    filter(isActionOf(getOrderDetailAction.request)),
    switchMap((act) => {
      return from(getOrderDetail(act.payload.token, act.payload.id_order)).pipe(
        switchMap((response) => {
          if (response as API_ORDER_DETAIL) {
            const rsp = response as API_ORDER_DETAIL;
            const notes: Note[] = [];
            const role = act.payload.role;
            const assignedGB =
              role === "GBManager" || role === "Admin" || role === "Booker"
                ? {
                    id: rsp.fitter.id_fitter,
                    name: rsp.fitter.first_name + " " + rsp.fitter.last_name,
                  }
                : {
                    id: rsp.mounter.id_mounter,
                    name: rsp.mounter.first_name + " " + rsp.mounter.last_name,
                  };
            rsp.notes.forEach((note: API_NOTE) => {
              const parsedNote: Note = {
                new: false,
                id: note.id_message,
                author: {
                  id: note.id_employee,
                  name: fullName(note.efirstname, note.elastname),
                },
                datetime: note.date_add,
                text: note.message,
              };
              notes.push(parsedNote);
            });
            const parsedObject: DetailsAPIResponse = {
              related_orders: rsp.related_orders,
              orderDetail: {
                assignedEventID: rsp.assignedEventID,
                comment: rsp.gardinbus_comment,
                order_products: rsp.order_products,
                shipping: Object.values(rsp.shipping),
                offer: rsp.offers as unknown as OFFER[],
                notes: notes,
                id: rsp.id_order,
                address_delivery: {
                  id_address: rsp.address_delivery.id_address,
                },
                customer: {
                  customer_name: fullName(
                    rsp.customer.firstname,
                    rsp.customer.lastname
                  ),
                  email: rsp.customer.email,
                  phone: Number(rsp.address_invoice.phone_mobile)
                    ? Number(rsp.address_invoice.phone_mobile)
                    : Number(rsp.address_invoice.phone),
                  address: fullAddress(
                    rsp.address_invoice.address1,
                    rsp.address_invoice.address2
                  ),
                  city:
                    rsp.address_invoice.city &&
                    rsp.address_invoice.city.trim().length > 0
                      ? rsp.address_invoice.city
                      : null,
                  post_code: Number(rsp.address_invoice.postcode),
                  country: rsp.address_invoice.country,
                  company:
                    rsp.address_invoice.company &&
                    rsp.address_invoice.company.trim().length > 0
                      ? rsp.address_invoice.company
                      : null,
                  CRV: undefined,
                  EAN: Number(rsp.address_invoice.ean),
                  customer_id: rsp.customer.id_customer,
                },
                order_id: rsp.id_order,
                assignedGBID: assignedGB.id,
              },
              assignedGb: {
                id: assignedGB.id,
                name: assignedGB.name,
                meetings: [],
                color: getRandomColor(),
                isSelected: true,
                assignedToThisOrder: true,
              },
              gbs: {},
            };

            return of(
              getOrderDetailAction.success({
                response: parsedObject,
                user: {
                  id_user: assignedGB.id,
                  token: act.payload.token,
                },
              })
            );
          }
          return EMPTY;
        }),
        catchError((err) => {
          console.log("err", err);
          return of(getOrderDetailAction.failure(err));
        })
      );
    })
  );

// const getFromPouchDb = async () => {
//   return await pouchDb
//     .allDocs({ include_docs: true })
//     .then((rsp) => rsp)
//     .catch((err) => err);
// };

// export const getOfflineDetailEpic: Epic<RootAction, RootAction, RootState> = (
//   action$
// ) =>
//   action$.pipe(
//     filter(isActionOf(getOfflineDetail.request)),
//     switchMap((act) => {
//       return from(getFromPouchDb()).pipe(
//         switchMap((response) => {
//           if (response as DetailsAPIResponse) {
//             const rsp: DetailsAPIResponse = response.rows[0]
//               .doc as DetailsAPIResponse;
//             return of(getOfflineDetail.success(rsp));
//           }
//           return EMPTY;
//         }),
//         catchError((err) => of(getOfflineDetail.failure(err)))
//       );
//     })
//   );

export const getAllGBsEpic: Epic<RootAction, RootAction, RootState> = (
  action$
) =>
  action$.pipe(
    filter(isActionOf(getOrderDetailAction.success)),
    switchMap((act) => {
      return from(getGBs(act.payload.user.token)).pipe(
        switchMap((response) => {
          if (response as API_GB[]) {
            const rsp: API_GB[] = response as API_GB[];
            return of(getGBSAction.success(rsp));
          }
          return EMPTY;
        }),
        catchError((err) => of(getGBSAction.failure(err)))
      );
    })
  );

export const requestAllGBsEpic: Epic<RootAction, RootAction, RootState> = (
  action$
) =>
  action$.pipe(
    filter(isActionOf(getGBSAction.request)),
    switchMap((act) => {
      return from(getGBs(act.payload.token)).pipe(
        switchMap((response) => {
          if (response as API_GB[]) {
            const rsp: API_GB[] = response as API_GB[];
            return of(getGBSAction.success(rsp));
          }
          return EMPTY;
        }),
        catchError((err) => of(getGBSAction.failure(err)))
      );
    })
  );

export const getGBMeetingsEpic: Epic<RootAction, RootAction, RootState> = (
  action$
) =>
  action$.pipe(
    filter(isActionOf(getEvents.request)),
    switchMap((act) => {
      return from(
        getAssignedGBMeetings(
          act.payload.token,
          act.payload.id_user,
          moment().subtract(1, "months").format("YYYY-MM-DD")
        )
      ).pipe(
        switchMap((response) => {
          if (response as Meeting[]) {
            return of(getEvents.success(response as Meeting[]));
          }
          return EMPTY;
        }),
        catchError((err) => of(getEvents.failure(err)))
      );
    })
  );

export const getEventsEpic: Epic<RootAction, RootAction, RootState> = (
  action$
) =>
  action$.pipe(
    filter(isActionOf(getOrderDetailAction.success)),
    switchMap((act) => {
      return from(
        getAssignedGBMeetings(
          act.payload.user.token,
          act.payload.user.id_user,
          moment().subtract(1, "months").format("YYYY-MM-DD")
        )
      ).pipe(
        switchMap((response) => {
          if (response as Meeting[]) {
            return of(getEvents.success(response as Meeting[]));
          }
          return EMPTY;
        }),
        catchError((err) => of(getEvents.failure(err)))
      );
    })
  );

export const getGBCalendarEpic: Epic<RootAction, RootAction, RootState> = (
  action$
) =>
  action$.pipe(
    filter(isActionOf(filterCalendars.request)),
    switchMap((act) => {
      return from(
        getAssignedGBMeetings(
          act.payload.token,
          act.payload.id_user,
          moment().subtract(1, "months").format("YYYY-MM-DD")
        )
      ).pipe(
        switchMap((response) => {
          if (response as Meeting[]) {
            return of(getEvents.success(response as Meeting[]));
          }
          return EMPTY;
        }),
        catchError((err) => of(getEvents.failure(err)))
      );
    })
  );

export const initDetailsEpic: Epic<RootAction, RootAction, RootState> = (
  action$
) =>
  action$.pipe(
    filter(isActionOf(initDetails.request)),
    switchMap((act) => {
      if (isPlatform("cordova")) {
        getDeviceCalendarEvent().then((events) => {
          if (events as IOSEvent[]) {
          }
        });
      }
      // return from(loadFromStorage(act.payload)).pipe(
      //   map((response) => {
      //     return initDetails.success(response!);
      //   }),
      //   catchError((message: string) => of(initDetails.failure(message)))
      // );
      return from(getOrderDetail(act.payload.token, act.payload.id)).pipe(
        switchMap((response) => {
          if (response as API_ORDER_DETAIL) {
            const api_order_detail: API_ORDER_DETAIL =
              response as API_ORDER_DETAIL;
            const notes: Note[] = [];
            api_order_detail.notes.forEach((note: API_NOTE) => {
              const parsedNote: Note = {
                new: false,
                author: {
                  id: note.id_employee,
                  name: fullName(note.efirstname, note.elastname),
                },
                datetime: note.date_add,
                text: note.message,
              };
              notes.push(parsedNote);
            });

            const transformedObject: DetailsAPIResponse = {
              related_orders: api_order_detail.related_orders,
              orderDetail: {
                address_delivery: {
                  id_address: api_order_detail.address_delivery.id_address,
                },
                order_products: api_order_detail.order_products,
                notes: notes,
                id: api_order_detail.id_order,
                customer: {
                  address:
                    api_order_detail.address_gardinbus.address1 +
                    " " +
                    api_order_detail.address_gardinbus.address2,
                  city: api_order_detail.address_gardinbus.city,
                  company: api_order_detail.address_gardinbus.company,
                  country: api_order_detail.address_gardinbus.country,
                  CRV: undefined,
                  EAN: Number(api_order_detail.address_gardinbus.ean),
                  customer_id: api_order_detail.customer.id_customer,
                  customer_name: fullName(
                    api_order_detail.customer.firstname,
                    api_order_detail.customer.lastname
                  ),
                  email: api_order_detail.customer.email,
                  phone: Number(api_order_detail.address_gardinbus.phone),
                  post_code: Number(
                    api_order_detail.address_gardinbus.postcode
                  ),
                },
                order_id: api_order_detail.id_order,
                // notes needs to be send another format
                // meetings you get from another endpoint
              },
              assignedGb: {
                id: api_order_detail.fitter.id_fitter,
                name: fullName(
                  api_order_detail.fitter.first_name,
                  api_order_detail.fitter.last_name
                ),
                meetings: [],
              },
              gbs: {},
            };
            return of(initDetails.success(transformedObject));
          }
          return EMPTY;
        }),
        catchError((message: string) => of(initDetails.failure(message)))
      );
    })
  );

// export const reAssignToGBEpic: Epic<RootAction, RootAction, RootState> = (
//   action$
// ) =>
//   action$.pipe(
//     filter(isActionOf(reAssignToGB.request)),
//     switchMap((action) => {
//       // API CALL action.payload
//       const {
//         id_order,
//         id_user,
//         token,
//         startDate,
//         endDate,
//         orderNote,
//         notify,
//       } = action.payload;
//       return from(
//         rescheduleMeasurring(
//           token,
//           id_order,
//           id_user,
//           startDate,
//           endDate,
//           orderNote,
//           notify
//         )
//       ).pipe(
//         switchMap((response) => {
//           return of(reAssignToGB.success(action.payload));
//         }),
//         catchError((err) => of(reAssignToGB.failure(err)))
//       );
//       // return of(reAssignToGB.success(action.payload));
//     }),
//     catchError((err) => of(reAssignToGB.failure(err)))
//   );
export const reAssignToGBEpic: Epic<RootAction, RootAction, RootState> = (
  action$
) =>
  action$.pipe(
    filter(isActionOf(reAssignToGB.request)),
    switchMap((action) => {
      // API CALL action.payload
      return of(reAssignToGB.success(action.payload));
    }),
    catchError((err) => of(reAssignToGB.failure(err)))
  );

export const cancelOrderEpic: Epic<RootAction, RootAction, RootState> = (
  action$
) =>
  action$.pipe(
    filter(isActionOf(cancelOrder.request)),
    switchMap((action) => {
      // API CALL action.payload
      const observables = of(
        showNotification({
          type: "Toast",
          message: "Order canceled successfully",
          color: "success",
        }),
        showNotification({
          type: "Top Bar",
          message: `Order canceled`,
          color: "danger",
        }),
        cancelOrder.success(action.payload)
      );
      return observables;
    }),
    catchError((err) => of(cancelOrder.failure(err)))
  );

export const newEventEpic: Epic<RootAction, RootAction, RootState> = (
  action$
) =>
  action$.pipe(
    filter(isActionOf(addNewEvent.request)),
    switchMap((action) => {
      const { title, start, end, note, orderID, notify } = action.payload.event;
      const { token, operatorId } = action.payload;
      const formattedStartDate = moment(start).format("YYYY-MM-DD HH:mm:ss");
      const formattedEndDate = moment(end).format("YYYY-MM-DD HH:mm:ss");
      if (isPlatform("cordova")) {
        handleEventToCalendar(
          title ? title : "Event from app",
          "dummy",
          undefined,
          start!,
          end!
        );
      }
      return from(
        rescheduleMeasurring(
          token,
          Number(orderID),
          operatorId,
          formattedStartDate,
          formattedEndDate,
          notify,
          note ? note : undefined
        )
      ).pipe(
        switchMap(() => of(addNewEvent.success(action.payload))),
        catchError((err) => of(addNewEvent.failure(err)))
      );
      // return of(addNewEvent.success(action.payload));
    }),
    catchError((err) => of(addNewEvent.failure(err)))
  );

// export const assignedEpic: Epic<RootAction, RootAction, RootState> = (
//   action$
// ) =>
//   action$.pipe(
//     filter(isActionOf([addNewEvent.success])),
//     switchMap((action) => {
//
//       // need to change this lead
//       if (action.payload.event.orderID) {
//         return of(
//           leadStatusUpdate({
//             orderNumber: Number(action.payload.event.orderID),
//           })
//         );
//       }
//       return EMPTY;
//     })
//   );

export const editEventEpic: Epic<RootAction, RootAction, RootState> = (
  action$
) =>
  action$.pipe(
    filter(isActionOf(editEvent.request)),
    switchMap((action) => {
      //updated event
      //PUT https://www.googleapis.com/calendar/v3/calendars/calendarId/events/eventId
      return of(editEvent.success(action.payload));
    }),
    catchError((err) => of(editEvent.failure(err)))
  );

export const deleteEventEpic: Epic<RootAction, RootAction, RootState> = (
  action$
) =>
  action$.pipe(
    filter(isActionOf(deleteEvent.request)),
    switchMap((action) => {
      // API CALL action.payload
      // if (action.payload.googleCalendar) {
      // // DELETE https://www.googleapis.com/calendar/v3/calendars/calendarId/events/eventId
      //   const myHeaders = new Headers({
      //     Authorization: `${googleCalendar.token}`,
      //   });
      //   const deleteHeaders = { method: "DELETE", headers: myHeaders };
      //   fetch(
      //     "https://www.googleapis.com/calendar/v3/calendars/" +
      //       googleCalendar.calendarID +
      //       "/events/" + action.payload.meetingID,
      //     {
      //       ...deleteHeaders,
      //     }
      //   )
      // }
      return of(deleteEvent.success(action.payload));
    }),
    catchError((err) => of(deleteEvent.failure(err)))
  );

export const assignMounterEpic: Epic<RootAction, RootAction, RootState> = (
  action$
) =>
  action$.pipe(
    filter(isActionOf(assingMounter.request)),
    switchMap((action) => {
      const date = moment(action.payload.mounting_date)
        .format("YYYY-MM-DD HH:mm:ss")
        .toString();
      return from(
        assignMounterAPI(
          action.payload.token,
          action.payload.id_mounter,
          action.payload.id_order,
          date
        )
      ).pipe(
        switchMap(() =>
          of(
            assingMounter.success({
              id_order: action.payload.id_order,
              mounting_date: date,
            })
          )
        ),
        catchError((err) => of(assingMounter.failure(err)))
      );
    }),
    catchError((err) => of(assingMounter.failure(err)))
  );

export const notifcationMounterAssignSuccessEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$) =>
  action$.pipe(
    filter(isActionOf(assingMounter.success)),
    switchMap(() =>
      of(
        showNotification({
          color: "success",
          message: "Assigned Succefully",
          type: "Toast",
        })
      )
    )
  );

export const notifcationMounterAssignFailEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$) =>
  action$.pipe(
    filter(isActionOf(assingMounter.failure)),
    switchMap(() =>
      of(
        showNotification({
          color: "danger",
          message: `Error!`,
          type: "Toast",
        })
      )
    )
  );

export const notifcationAddNewEventSuccessEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$) =>
  action$.pipe(
    filter(isActionOf(addNewEvent.success)),
    switchMap(() =>
      of(
        showNotification({
          color: "success",
          message: "Re-Scheduled Succefully",
          type: "Toast",
        })
      )
    )
  );

export const notifcationAddNewEventFailEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$) =>
  action$.pipe(
    filter(isActionOf(addNewEvent.failure)),
    switchMap(() =>
      of(
        showNotification({
          color: "danger",
          message: `Error!`,
          type: "Toast",
        })
      )
    )
  );

export const addNewNoteEpic: Epic<RootAction, RootAction, RootState> = (
  action$
) =>
  action$.pipe(
    filter(isActionOf(addNewOrderNote.request)),
    switchMap((action) =>
      from(
        addNoteAPI(
          action.payload.token,
          action.payload.order_id,
          action.payload.note.text
        )
      ).pipe(
        switchMap((rsp) => {
          const note_id = rsp as number;
          action.payload.note.id = note_id;
          return of(addNewOrderNote.success(action.payload.note));
        }),
        catchError((err) => of(addNewOrderNote.failure(err)))
      )
    )
  );

export const editNewNoteEpic: Epic<RootAction, RootAction, RootState> = (
  action$
) =>
  action$.pipe(
    filter(isActionOf(editNewOrderNote.request)),
    switchMap((action) =>
      from(
        editNoteAPI(
          action.payload.token,
          action.payload.id_note,
          action.payload.note.text
        )
      ).pipe(
        switchMap(() => of(editNewOrderNote.success(action.payload.note))),
        catchError((err) => of(editNewOrderNote.failure(err)))
      )
    )
  );

export const deleteNewNoteEpic: Epic<RootAction, RootAction, RootState> = (
  action$
) =>
  action$.pipe(
    filter(isActionOf(deleteNewOrderNote.request)),
    switchMap((action) =>
      from(deleteNoteAPI(action.payload.token, action.payload.id_note)).pipe(
        switchMap(() => of(deleteNewOrderNote.success(action.payload.id_note))),
        catchError((err) => of(deleteNewOrderNote.failure(err)))
      )
    )
  );

export const setCustomerResponseEpic: Epic<RootAction, RootAction, RootState> =
  (action$) =>
    action$.pipe(
      filter(isActionOf(setCustomerResponseOption.request)),
      switchMap((action) =>
        from(
          setCustomerResponseOptionsAPI(
            action.payload.token,
            action.payload.id_order,
            action.payload.id_gardinbus_customer_response,
            action.payload.order_note,
            action.payload.alarm
          )
        ).pipe(
          switchMap((rsp) => {
            return shouldDeleteLead(
              action.payload.id_gardinbus_customer_response
            )
              ? of(
                  setCustomerResponseOption.success(rsp as boolean),
                  deleteLead(action.payload.id_order)
                )
              : of(setCustomerResponseOption.success(rsp as boolean));
          }),
          catchError((err) => of(setCustomerResponseOption.failure(err)))
        )
      )
    );

export const getCustomerResponseeEpic: Epic<RootAction, RootAction, RootState> =
  (action$) =>
    action$.pipe(
      filter(isActionOf(getCustomerResponseOption.request)),
      switchMap((action) =>
        from(getCustomerResponseOptionsAPI(action.payload.token)).pipe(
          switchMap((rsp) =>
            of(
              getCustomerResponseOption.success(
                rsp as CustomerResponseOptions[]
              )
            )
          ),
          catchError((err) => of(getCustomerResponseOption.failure(err)))
        )
      )
    );

export const sucessOfferDetailEpic: Epic<RootAction, RootAction, RootState> = (
  action$
) =>
  action$.pipe(
    filter(isActionOf([createOffer.success])),
    switchMap(() =>
      of(
        showNotification({
          color: "success",
          message: "Offer successfully created!",
          type: "Toast",
        })
      )
    ),
    catchError((err) => of(updateOffer.failure(err)))
  );

export const sucessOfferUpdateDetailEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$) =>
  action$.pipe(
    filter(isActionOf([updateOffer.success])),
    switchMap(() =>
      of(
        showNotification({
          color: "success",
          message: "Offer successfully updated!",
          type: "Toast",
        })
      )
    ),
    catchError((err) => of(updateOffer.failure(err)))
  );

export const sucessResponseUpdateDetailEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$) =>
  action$.pipe(
    filter(isActionOf([setCustomerResponseOption.success])),
    switchMap(() =>
      of(
        showNotification({
          color: "success",
          message: "Response successfully saved!",
          type: "Toast",
        })
      )
    ),
    catchError((err) => of(setCustomerResponseOption.failure(err)))
  );

export const getCalendarMeetingsEpic: Epic<RootAction, RootAction, RootState> =
  (action$) =>
    action$.pipe(
      filter(isActionOf(getAllEvents.request)),
      switchMap((act) => {
        return from(
          act.payload.user.userRole === "GBManager" ||
            act.payload.user.userRole === "Mounter"
            ? getAssignedGBMeetings(
                act.payload.user.token,
                act.payload.user.userID,
                act.payload.dateStart
              )
            : getAllMeetings(act.payload.user.token, act.payload.dateStart)
        ).pipe(
          switchMap((response) => {
            if (response as Meeting[]) {
              return of(getAllEvents.success(response as Meeting[]));
            }
            return EMPTY;
          }),
          catchError((err) => of(getAllEvents.failure(err)))
        );
      })
    );
