import React from "react";
import "./PopoverAction.css";
import { IonPopover, IonItem, IonLabel, IonList } from "@ionic/react";
export interface IPopoverAction {
  name: string;
  fn: () => void;
}
interface IProps {
  state: {
    open: boolean;
    event: Event | undefined;
  };
  actions: IPopoverAction[];
  onClose: () => void;
}
const PopoverAction: React.FC<IProps> = props => {
  const {
    state,
    actions,
    onClose
  } = props;
  return <>
      <IonPopover isOpen={state.open} event={state.event} animated={false} onDidDismiss={onClose}>
        <IonList>
          {actions.map(action => <IonItem key={action.name} button onClick={e => {
          e.preventDefault();
          action.fn();
        }}>
              <IonLabel>{action.name}</IonLabel>
            </IonItem>)}
        </IonList>
      </IonPopover>
    </>;
};
export default PopoverAction;