import React from "react";
import "./Toast.css";
import { IonToast } from "@ionic/react";
import { Color } from "../../models";
interface Props {
  open: boolean;
  onClose: () => void;
  message: string;
  color?: Color;
  obj?: Object;
}
const Toast: React.FC<Props> = props => {
  const {
    open,
    onClose,
    color,
    message,
    obj
  } = props;
  return <>
      <IonToast onDidDismiss={onClose} isOpen={open} duration={4000} message={message} color={color ? color : "light"} keyboardClose={true} buttons={obj ? [{
      handler: () => navigator.clipboard.writeText(JSON.stringify(obj)),
      text: "Copy to Clipboard"
    }] : []} />
    </>;
};
export default Toast;