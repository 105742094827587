import { Epic } from "redux-observable";
import { from, of } from "rxjs";
import { AjaxError } from "rxjs/ajax";
import { filter, catchError, switchMap, map } from "rxjs/operators";
import { RootAction, RootState, isActionOf } from "typesafe-actions";
import { PreFilledOffer } from "../../../models";
import { generateRandomID, storageGet } from "../../../helpers";
import { API_OFFER } from "../../../models/apiModels";
import { showNotification } from "../../Notification/redux/actions";
import {
  addNewEmptyProduct,
  createOffer,
  CreateOffer,
  DraftOffer,
  draftOffer,
  getDraft,
  getOffers,
  updateOffer,
} from "./actions";
import { OfferDetailState } from "./reducer";
import { apiVersionWrapper } from "../../../helpers/api";

const parseAPItoPrefilled = (
  offer_id: number,
  order_id: number,
  o: API_OFFER
): PreFilledOffer[] => {
  const parsedOffers: PreFilledOffer[] = [];
  const sent = o.offers[0].sent;
  const offer = o.offers[0].plain_offer;
  const offerActualIndexes = Object.keys(offer.id_product);

  offerActualIndexes.forEach((_objectIndex) => {
    const objectIndex = Number(_objectIndex);
    if (offer.id_product[objectIndex] !== 0) {
      const _offer: PreFilledOffer = {
        comment: offer.comment && offer.comment[objectIndex],
        choosen_attributes_id: offer.attributes
          ? offer.attributes[objectIndex]
          : {},
        product_attributes_id: {},
        sent: sent,
        uuid: generateRandomID(),
        offer_id: offer_id,
        order_id: order_id,
        prefilled: true,
        readyToSend: true,
        product_id: offer.id_product[objectIndex],
        quantity: offer.quantity[objectIndex],
        width: offer.width ? offer.width[objectIndex] : undefined,
        height: offer.height ? offer.height[objectIndex] : undefined,
        width_2: offer.width2 ? offer.width2[objectIndex] : undefined,
        width_a: offer.width_a ? offer.width_a[objectIndex] : undefined,
        width_b: offer.width_b ? offer.width_b[objectIndex] : undefined,
        width_c: offer.width_c ? offer.width_c[objectIndex] : undefined,
        height_2: offer.height2 ? offer.height2[objectIndex] : undefined,
        manual: offer.manual ? offer.manual[objectIndex] : undefined,
      };
      parsedOffers.push(_offer);
    }
  });
  return parsedOffers;
};
const getAPIOffers = (token: string, id_order: number) => {
  const API_URL = `${process.env.REACT_APP_API_URL}getOffers/${id_order}`;
  return apiVersionWrapper(API_URL, "GET", {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  }).pipe(
    map((response) => {
      const OFFERS: API_OFFER = response.response;
      return OFFERS;
    }),
    catchError((error: Error) => {
      return of(error);
    })
  );
};

const createAPIOffer = (
  token: string,
  id_order: number,
  offer: CreateOffer
) => {
  const API_URL = `${process.env.REACT_APP_API_URL}createOffer/${id_order}`;
  return apiVersionWrapper(
    API_URL,
    "POST",
    {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    JSON.stringify({ ...offer })
  ).pipe(
    map((response) => {
      const offer_id: number = response.response;
      return offer_id;
    }),
    catchError((error: Error) => {
      return of(error);
    })
  );
};

const updateAPIOffer = (
  token: string,
  id_order: number,
  id_offer: number,
  offer: CreateOffer
) => {
  const API_URL = `${process.env.REACT_APP_API_URL}updateOffer/${id_order}/${id_offer}`;
  return apiVersionWrapper(
    API_URL,
    "PUT",
    {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    JSON.stringify({ ...offer })
  ).pipe(
    map((response) => {
      const ok: boolean = response.response;
      return ok;
    }),
    catchError((error: Error) => {
      return of(error);
    })
  );
};

const getDraftFromStorage = async () => {
  const draft = await storageGet<{ [index: number]: DraftOffer }>("draftOffers")
    .then((rsp) => rsp)
    .catch((err) => err);
  return draft;
};

export const getDraftDetailEpic: Epic<RootAction, RootAction, RootState> = (
  action$
) =>
  action$.pipe(
    filter(isActionOf(getDraft.request)),
    switchMap((action) => {
      return from(getDraftFromStorage()).pipe(
        switchMap((_rsp) => {
          const typescriptdamerda = _rsp as any;
          const rsp: CreateOffer = typescriptdamerda[
            action.payload
          ] as CreateOffer;
          const parseToState: OfferDetailState = {
            discount: rsp.cart_extra_discount,
            extraMounting: rsp.cart_extra_mounting_price,
            generalComment: rsp.general_comment,
            mountingTime:
              rsp.estimated_mounting_hours.toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
              }) +
              ":" +
              rsp.estimated_mounting_minutes.toLocaleString("en-US", {
                minimumIntegerDigits: 2,
                useGrouping: false,
              }),
            offers: Object.values(rsp.id_product).map((id, i) => {
              return {
                product_id: Number(rsp.id_product[i]),
                choosen_attributes_id: rsp.attributes![i],
                comment: rsp.comment![i] ? rsp.comment![i] : undefined,
                height2: rsp.height2![i] ? rsp.height2![i] : undefined,
                height: rsp.height![i] ? rsp.height![i] : undefined,
                offer_id: 0,
                order_id: 0,
                prefilled: true,
                product_attributes_id: {},
                quantity: rsp.quantity[i] ? rsp.quantity[i] : i,
                readyToSend: rsp.readyToSend[i] ? true : false,
                uuid: generateRandomID(),
                width2: rsp.width2![i] ? rsp.width2![i] : undefined,
                width: rsp.width![i] ? rsp.width![i] : undefined,
              };
            }),
          };
          return of(getDraft.success(parseToState));
        }),
        catchError((err) => of(getDraft.failure(err)))
      );
    }),
    catchError((err) => of(getDraft.failure(err)))
  );

export const getOfferDetailEpic: Epic<RootAction, RootAction, RootState> = (
  action$
) =>
  action$.pipe(
    filter(isActionOf(getOffers.request)),
    switchMap((action) => {
      return from(
        getAPIOffers(action.payload.token, action.payload.id_order)
      ).pipe(
        switchMap((rsp) => {
          const offer = rsp as API_OFFER;
          offer.offers = offer.offers.filter(
            (o) => o.id_gardinbus_offer === action.payload.id_offer
          );
          return of(
            getOffers.success({
              offers: parseAPItoPrefilled(
                action.payload.id_offer,
                action.payload.id_order,
                offer
              ),
              discount: offer.offers[0].plain_offer.cart_extra_discount
                ? offer.offers[0].plain_offer.cart_extra_discount
                : 0,
              extraMounting:
                offer.offers[0].plain_offer.cart_extra_mounting_price,
              mountingTime:
                offer.offers[0].plain_offer.estimated_mounting_hours.toLocaleString(
                  "en-US",
                  {
                    minimumIntegerDigits: 2,
                    useGrouping: false,
                  }
                ) +
                ":" +
                offer.offers[0].plain_offer.estimated_mounting_minutes.toLocaleString(
                  "en-US",
                  {
                    minimumIntegerDigits: 2,
                    useGrouping: false,
                  }
                ),
              generalComment: offer.offers[0].plain_offer.general_comment,
            })
          );
        }),
        catchError((err) => of(getOffers.failure(err)))
      );
    }),
    catchError((err) => of(getOffers.failure(err)))
  );

export const createOfferDetailEpic: Epic<RootAction, RootAction, RootState> = (
  action$
) =>
  action$.pipe(
    filter(isActionOf(createOffer.request)),
    switchMap((action) => {
      return from(
        createAPIOffer(
          action.payload.token,
          action.payload.id_order,
          action.payload.offer
        )
      ).pipe(
        switchMap((rsp) => {
          const rsp_status = rsp as AjaxError;
          if (rsp_status.status === 400) {
            return of(
              showNotification({
                color: "danger",
                message: "Offer not sent! Check products!",
                type: "Toast",
              })
            );
          }
          return of(
            createOffer.success({
              offer: action.payload.offer,
              id_order: action.payload.id_order,
              id_offer: rsp as number,
            })
          );
        }),
        catchError((err) => of(createOffer.failure(err)))
      );
    }),
    catchError((err) => of(createOffer.failure(err)))
  );

export const updateOfferDetailEpic: Epic<RootAction, RootAction, RootState> = (
  action$
) =>
  action$.pipe(
    filter(isActionOf(updateOffer.request)),
    switchMap((action) => {
      return from(
        updateAPIOffer(
          action.payload.token,
          action.payload.id_order,
          action.payload.id_offer,
          action.payload.offer
        )
      ).pipe(
        switchMap((rsp) => {
          const rsp_status = rsp as AjaxError;
          if (rsp_status.status === 400) {
            return of(
              showNotification({
                color: "danger",
                message: `Offer not updated! Reason: ${rsp_status.response.error}`,
                type: "Toast",
              })
            );
          }
          return of(
            updateOffer.success({
              offer: action.payload.offer,
              id_order: action.payload.id_order,
            })
          );
        }),
        catchError((err) => of(updateOffer.failure(err)))
      );
    }),
    catchError((err) => of(updateOffer.failure(err)))
  );

export const sucessOfferDetailEpic: Epic<RootAction, RootAction, RootState> = (
  action$
) =>
  action$.pipe(
    filter(isActionOf([createOffer.success])),
    switchMap(() =>
      of(
        showNotification({
          color: "success",
          message: "Offer successfully created!",
          type: "Toast",
        })
      )
    ),
    catchError((err) => of(updateOffer.failure(err)))
  );

export const sucessOfferUpdateDetailEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$) =>
  action$.pipe(
    filter(isActionOf([updateOffer.success])),
    switchMap((updatedOffer) => {
      return of(
        showNotification({
          color: "success",
          message: "Offer successfully updated!",
          type: "Toast",
        })
      );
    }),
    catchError((err) => of(updateOffer.failure(err)))
  );

export const sucessDraftSavedDetailEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$) =>
  action$.pipe(
    filter(isActionOf([draftOffer])),
    switchMap(() =>
      of(
        showNotification({
          color: "success",
          message: "Draft successfully saved!",
          type: "Toast",
        })
      )
    ),
    catchError((err) => of(updateOffer.failure(err)))
  );

export const newEmptyProductEpic: Epic<RootAction, RootAction, RootState> = (
  action$
) =>
  action$.pipe(
    filter(isActionOf([addNewEmptyProduct.request])),
    switchMap((act) => of(addNewEmptyProduct.success(act.payload))),
    catchError((err) => of(addNewEmptyProduct.failure(err)))
  );
