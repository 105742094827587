import React from "react";
import { IonCheckbox, IonItem, IonLabel } from "@ionic/react";
import { Attribute } from "./AttributeList";
interface Props {
  attribute: Attribute;
  price?: number;
  checkedAttributes: any[];
  group: number;
  onChange: ({
    attribute,
    checked
  }: {
    attribute: Attribute;
    checked: boolean;
    group: number;
  }) => void;
}
function MultiSelectAttributes({
  attribute,
  onChange,
  checkedAttributes,
  group,
  price
}: Props) {
  return <IonItem>
      <IonLabel>
        {attribute.public_name} {price ? `(${price} kr.)` : ""}
      </IonLabel>
      <IonCheckbox checked={checkedAttributes.find(attr => attr === attribute.id_product_attribute) ? true : false} onIonChange={e => {
      onChange({
        attribute,
        checked: e.detail.checked,
        group
      });
    }} key={attribute.public_name} />
    </IonItem>;
}
export default MultiSelectAttributes;