import { useEffect, useRef, useState } from "react";
import { RootState } from "typesafe-actions";
import { useSelector, useDispatch } from "react-redux";
import { IonCol, IonGrid, IonRow, AlertInput, AlertButton, IonSkeletonText, IonButton, IonChip, IonCard, IonCardHeader, IonCardContent, IonCardTitle, IonItem, IonLabel, IonList, IonText } from "@ionic/react";
import { RouteComponentProps } from "react-router";
import { addNewOrderNote, reAssignToGB, getOrderDetailAction, editNewOrderNote, deleteNewOrderNote, getOfflineDetail, getEvents, getAllEvents } from "./redux/actions";
import { Modal, UserDetail, CalendarComponent, Alert, Toast, NoteList, MeetingForm, OrderStatus, OfferCard, RelatedOrders } from "../../components/";
import React from "react";
import { Meeting, SlotInfoEvent, ModalAction, Color, NewEventForm, Note } from "../../models/";
import { validDate, validEvent, fullName, generateRandomID } from "../../helpers/";
import { SaveForm } from "../../components/MeetingForm/MeetingForm";
import { clearNotification, showNotification } from "../Notification/redux/actions";
import { isArray, isEqual } from "lodash";
import { IOffer } from "../../components/OfferCard/OfferCard";
import Delivery from "../../components/Delivery";
import { useTranslation } from "react-i18next";
import ListOrderedProducts from "../../components/OrderedProducts";
import Loading from "../../components/Loading";
import { addNewEvent, editEvent, deleteEvent, filterCalendars, getSuggestions, clearSuggestions, clearFilterCalendars, initCalendars } from "../Calendars/redux/actions";
import { isPlatform } from "@ionic/react";
import ExpandableContainer from "../../components/ExpandableContainer";
import moment from "moment";
import PhotoGallery from "../S3/PhotoGallery";
import { getGBMeetings } from "../Meeting/redux/actions";
import { updateAssignedGB } from "../Leads/redux/actions";
interface IProps {
  editable: boolean;
}
const DetailsFeature: React.FC<IProps & RouteComponentProps<{
  id: string;
}>> = props => {
  // REDUX
  const dispatch = useDispatch();
  const userName = useSelector((state: RootState) => state.authReducer.name);
  const userID = useSelector((state: RootState) => state.authReducer.userID);
  const userRole = useSelector((state: RootState) => state.authReducer.role);
  const token = useSelector((state: RootState) => state.authReducer.token);
  const suggestedMeetings = useSelector((state: RootState) => state.calendarsReducer.suggestedMeetings);
  const online = useSelector((state: RootState) => state.authReducer.workingOnline);
  const isLoading = useSelector((state: RootState) => state.detailsReducer.isLoading);
  const isLoadingCalendar = useSelector((state: RootState) => state.calendarsReducer.isLoading);
  const orderInfo = useSelector((state: RootState) => state.detailsReducer.order_info);
  const notes = useSelector((state: RootState) => state.detailsReducer.order_info?.notes, isEqual);
  const operator = useSelector((state: RootState) => state.calendarsReducer.operator, isEqual);
  const assignedGb = useSelector((state: RootState) => state.detailsReducer.assignedGb);
  const offers = useSelector((state: RootState) => state.detailsReducer.order_info?.offer);
  const auth = useSelector((state: RootState) => state.authReducer);
  const orderList = useSelector((state: RootState) => state.leadsReducer.leads);
  const related_orders = useSelector((state: RootState) => state.detailsReducer.related_orders);
  const all_events = useSelector((state: RootState) => state.detailsReducer.all_events);
  const events = all_events && isArray(all_events) ? all_events.filter(e => e.orderID === Number(props.match.params.id)) : [];
  const [alreadyWasFiltered, setAlreadyWasFiltered] = useState(true);
  useEffect(() => {
    dispatch(getAllEvents.request({
      user: {
        token: token!,
        userID: Number(userID),
        userRole: userRole
      },
      calendarName: "UNIG",
      dateStart: "10-15-1990",
      dateEnd: new Date()
    }));
  }, []);
  useEffect(() => {
    if (!online) {
      dispatch(getOfflineDetail.request());
    }
    if (online) {
      if (events.length > 0) {
        const dateStart = events.sort((a, b) => b.start - a.start)[0].start;
        const dateEnd = moment(dateStart).endOf("week").toDate();
        dispatch(initCalendars.request({
          token: token!,
          userID: Number(userID),
          userRole: userRole,
          calendarName: "UNIG",
          dateStart: dateStart,
          dateEnd: dateEnd
        }));
      }
      !isNaN(Number(props.match.params.id)) && dispatch(getOrderDetailAction.request({
        id_order: Number(props.match.params.id),
        id_user: userID!,
        token: token!,
        role: auth.role
      }));
    }
    return () => {
      dispatch(clearNotification());
      dispatch(clearSuggestions());
    };
    // I want this to run once on component mount only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [all_events]);
  useEffect(() => {
    if (orderInfo?.assignedGBID && Object.values(operator).filter((g: any) => g.isSelected).length === 0) {
      setAlreadyWasFiltered(false);
      dispatch(filterCalendars(orderInfo?.assignedGBID));
    }
    return () => {
      if (!alreadyWasFiltered) {
        dispatch(clearFilterCalendars());
      }
    };
  }, [alreadyWasFiltered, dispatch, orderInfo?.assignedGBID]);
  // States
  const [changeGB, setChangeGB] = useState(false);
  const [modal, setModal] = useState(false);
  const [openNote, setOpenNote] = useState(false);
  const [toast, setToast] = useState<{
    open: boolean;
    message: string;
    color: Color;
  }>({
    open: false,
    message: "",
    color: "light"
  });
  const [editNote, setEditNote] = useState(false);
  const {
    t
  } = useTranslation();
  // Methods
  const GBAlertInputs: AlertInput[] = operator ? Object.values(operator).map((o: any) => {
    return {
      name: o.name,
      type: "radio",
      label: o.name,
      value: o.id,
      checked: o.assignedToThisOrder
    };
  }) : [];
  const GBAlertButtons: AlertButton[] = [{
    text: t("Cancel"),
    role: "cancel",
    cssClass: "secondary",
    handler: () => setChangeGB(false)
  }, {
    text: t("Confirm"),
    handler: (gbID: number) => {
      dispatch(reAssignToGB.request(gbID));
    }
  }];
  const newNoteAlertInputs: AlertInput[] = [{
    placeholder: t("NewNote"),
    name: "newNote",
    type: "text"
  }];
  const newNoteAlertButtons: AlertButton[] = [{
    text: t("Cancel"),
    role: "cancel",
    cssClass: "secondary",
    handler: () => setOpenNote(false)
  }, {
    text: t("Confirm"),
    handler: value => {
      dispatch(addNewOrderNote.request({
        note: {
          text: value["newNote"],
          new: true,
          author: {
            id: userID!,
            name: userName!
          },
          datetime: new Date(Date.now())
        },
        order_id: orderInfo!.order_id,
        token: token!
      }));
    }
  }];
  const handleEditEvent = (slotInfo: SlotInfoEvent) => {
    const start: Date = new Date(slotInfo.start);
    const end: Date = new Date(slotInfo.end);
    // const operatorID = operator
    //   ? Object.values(operator).find((o) => o.assignedToThisOrder)?.id
    //   : undefined;
    slotInfo.event.orderID = orderInfo?.order_id;
    // slotInfo.event.operatorID = operatorID ? operatorID : -1;
    if (!validDate(start, end)) {
      setToast({
        open: true,
        message: t("ErrorDatePast"),
        color: "danger"
      });
    } else {
      dispatch(editEvent.request({
        newStartDate: start,
        newEndDate: end,
        event: slotInfo.event,
        token: auth.token!,
        id_user: Number(slotInfo.event.operatorID!),
        id_address: Number(orderInfo?.address_delivery.id_address)
      }));
    }
  };
  const handleDispatchNewEvent = (newEvent: NewEventForm) => {
    const event: Meeting = {
      operatorID: Number(newEvent.operatorID),
      start: newEvent.startDate,
      end: newEvent.endDate,
      title: newEvent.title,
      meetingID: newEvent.meetingID,
      orderID: newEvent.orderID,
      location: orderInfo?.meeting?.location,
      notify: newEvent.notifyCustomer ? true : false,
      note: newEvent.note
      //set calendar
    };
    if (validEvent(newEvent)) {
      const orderDetail = orderList.find(o => o.id_order === Number(event.orderID));
      dispatch(addNewEvent.request({
        token: auth.token!,
        operatorId: Number(newEvent.operatorID),
        event: event,
        id_address: Number(orderDetail?.address_delivery.id_address),
        postcode: Number(orderDetail?.address_delivery.postcode),
        fullName: orderDetail ? fullName(orderDetail?.customer.firstname, orderDetail?.customer.lastname) : ""
      }));
      if (event.note?.length > 0) {
        if (newEvent.note?.length > 0) {
          dispatch(addNewOrderNote.request({
            note: {
              author: {
                name: auth.name,
                id: auth.userID
              },
              datetime: new Date(),
              new: true,
              text: newEvent.note
            },
            token: auth.token!,
            order_id: newEvent.orderID
          }));
        }
      }
      setDragEvent({
        startDate: undefined,
        endDate: undefined
      });
    } else {
      dispatch(showNotification({
        message: t("ErrorAddEvent"),
        color: "danger",
        type: "Toast"
      }));
    }
  };
  const formRef = useRef<SaveForm>();
  const addNewEventButton: ModalAction = {
    name: t("AddNewMeeting"),
    fn: () => {
      handleDispatchNewEvent(formRef.current!.onSave().newEvent);
      setModal(false);
    },
    type: "button"
  };
  const [dragEvent, setDragEvent] = useState<{
    startDate: Date | string | undefined;
    endDate: Date | string | undefined;
  }>();
  const newEventModal = () => {
    return <Modal actions={[addNewEventButton]} isOpen={modal} onClose={() => setModal(false)} title={t("NewEvent")}>
        <MeetingForm orderList={orderList} startDate={dragEvent?.startDate} endDate={dragEvent?.endDate} ref={formRef} orderInfo={orderInfo} gbList={operator} />
      </Modal>;
  };
  const handleSelectedFilter = (val: number) => {
    dispatch(filterCalendars(val));
  };
  const getOffers = () => {
    const parsedOffers: IOffer[] = [];
    offers?.forEach(offer => {
      const parsedOffer: IOffer = {
        id: offer.id_gardinbus_offer,
        dateCreated: offer.date_offer_created,
        sent: offer.sent === 1 ? true : false,
        generalComment: offer.plain_offer.general_comment,
        offerLink: offer.cart_link,
        productCount: offer.plain_offer.quantity ? Object.values(offer.plain_offer.quantity).reduce((acc, inc) => acc + inc, 0) : 0,
        isDraft: offer.plain_offer.isDraft || !offer.plain_offer.submitOffer ? true : false,
        isLocalDraft: offer.plain_offer.isDraft ? true : false
      };
      parsedOffers.push(parsedOffer);
    });
    return parsedOffers;
  };
  const [StartingDate, setStartingDate] = useState<Date | undefined>(orderInfo?.meeting?.start);

  // useEffect to dispatch when StartingDate changes
  useEffect(() => {
    if (StartingDate) {
      const dateStart = StartingDate;
      const dateEnd = moment(dateStart).endOf("week").toDate();
      dispatch(initCalendars.request({
        token: token!,
        userID: Number(userID),
        userRole: userRole,
        calendarName: "UNIG",
        dateStart: dateStart,
        dateEnd: dateEnd
      }));
    }
  }, [StartingDate, dispatch, token, userID, userRole]);
  const [Open, setOpen] = useState(false);
  return <>
      {modal && newEventModal()}
      {isLoading ? <>
          <Loading loading={isLoading} onGoBack={() => props.history.goBack()} />
        </> : <>
          {toast.open && <Toast open={toast.open} message={toast.message} color={toast.color} onClose={() => setToast({
        open: false,
        message: "",
        color: "light"
      })} />}
          {openNote && <Alert open={openNote} onClose={() => setOpenNote(false)} header={t("WriteBelow")} inputs={newNoteAlertInputs} buttons={newNoteAlertButtons} />}
          {changeGB && <Alert open={changeGB} onClose={() => setChangeGB(false)} header={t("ChooseGB")} inputs={GBAlertInputs} buttons={GBAlertButtons} />}
          <IonGrid id={"details-feature"}>
            <IonRow>
              <IonCol>
                {orderInfo && <UserDetail colSize={3} user={orderInfo.customer} />}
              </IonCol>
            </IonRow>
            {!isLoading && operator && orderInfo && assignedGb ? <>
                {(userRole === "Admin" || userRole === "Booker") && <IonRow>
                    <IonCol>
                      <OrderStatus role={userRole} assignedGb={assignedGb} orderInfo={orderInfo} />
                    </IonCol>
                  </IonRow>}
                {userRole !== "Mounter" && <IonRow>
                    <IonCol>
                      <OfferCard offer={getOffers()} onClickOffer={offer_id => props.history.push(`/leads/offer/${orderInfo!.order_id}/${offer_id}`)} onClickNewOffer={() => {
                props.history.push(`/leads/offer/${orderInfo!.order_id}/new`);
              }} />
                    </IonCol>
                  </IonRow>}
              </> : <IonSkeletonText animated />}
            <IonRow>
              <IonCol>
                <Delivery delivery={orderInfo?.shipping} />
              </IonCol>
              <IonCol>
                <NoteList author={{
              id: userID!,
              name: userName!,
              role: userRole!
            }} notes={notes ? notes : []} editable={editNote} onDelete={note_id => dispatch(deleteNewOrderNote.request({
              token: token!,
              id_note: note_id
            }))} onSave={(newNotes: Note[]) => {
              newNotes.forEach(note => {
                if (note.new && !note.edited) {
                  return dispatch(addNewOrderNote.request({
                    note: note,
                    order_id: orderInfo!.order_id,
                    token: token!
                  }));
                }
                if (!note.new && note.edited) {
                  //update
                  return dispatch(editNewOrderNote.request({
                    note: note,
                    id_note: note.id!,
                    token: token!
                  }));
                }
              });
              setEditNote(false);
            }} onSetEditable={() => setEditNote(!editNote)} />
              </IonCol>
            </IonRow>
            {related_orders.length > 0 && <IonRow>
                <IonCol>
                  <RelatedOrders related_orders={related_orders} onClick={id_order => props.history.push(`/leads/${id_order}`)} />
                </IonCol>
              </IonRow>}
          </IonGrid>
          {orderInfo && orderInfo.order_products.length > 0 && <IonGrid>
              <ExpandableContainer open={Open}>
                <ListOrderedProducts expand={Open} onExpand={setOpen} order_comment={orderInfo.comment && orderInfo.comment.length > 0 ? orderInfo.comment : undefined} orderedProducts={orderInfo.order_products} />
              </ExpandableContainer>
            </IonGrid>}
          {orderInfo && <IonGrid>
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>Mounting photos</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  <PhotoGallery user_role={auth.role} order_id={orderInfo.order_id.toString()} />
                </IonCardContent>
              </IonCard>
            </IonGrid>}
          {operator && Object.keys(operator).length > 0 && (userRole === "Admin" || userRole === "Booker") && orderInfo ? <>
              {!isPlatform("cordova") ? <IonRow>
                  <IonCol size={"9"}>
                    {/* <IonButton
                      fill="clear"
                      onClick={() =>
                        dispatch(
                          getSuggestions.request({
                            token: auth.token!,
                            order_id: orderInfo.order_id,
                          })
                        )
                      }
                     >
                      Get suggestions for meetings
                     </IonButton>
                     <IonRow>
                      {suggestedMeetings.length === 0 && <>No suggestions</>}
                      {suggestedMeetings.map((meeting) => (
                        <IonChip
                          key={meeting.id + generateRandomID()}
                          onClick={() => setStartingDate(meeting.start)}
                        >
                          {moment(meeting.start).format("DD-MM-YYYY HH:mm ")}
                          to {moment(meeting.end).format("HH:mm ")}
                        </IonChip>
                      ))}
                     </IonRow> */}
                    <CalendarComponent isLoading={Boolean(isLoadingCalendar)} popoverEnabled={true} suggestedMeetings={suggestedMeetings} date={StartingDate ? StartingDate : orderInfo?.meeting?.start} onNavigate={date => {
              const startDate = moment(date).startOf("week").toDate();
              setStartingDate(startDate);
            }} onError={message => dispatch(showNotification({
              message: message,
              color: "warning",
              type: "Toast"
            }))} views={{
              work_week: true,
              month: true,
              day: true
            }} gbs={operator} filter={{
              title: auth.role === "GBManager" || auth.role === "Mounter" ? t("MyCalendar") : t("AllCalendars"),
              selectedFilter: id => handleSelectedFilter(parseInt(id)),
              deselectFilter: id => handleSelectedFilter(id)
            }} defaultView={"work_week"} orderDetail={orderInfo} onReschedule={(newStart, newEnd, oldMeeting, gbid) => {
              dispatch(editEvent.request({
                newStartDate: newStart,
                newEndDate: newEnd,
                event: oldMeeting,
                token: auth.token!,
                id_user: Number(oldMeeting.operatorID),
                id_address: Number(orderInfo?.address_delivery.id_address)
              }));
            }} onDeleteEvent={meeting => dispatch(deleteEvent.request({
              event: meeting,
              token: auth.token!
            }))} onSelectNewEvent={slotInfo => {
              setDragEvent({
                startDate: slotInfo.start,
                endDate: slotInfo.end
              });
              setModal(true);
            }} onCreateEvent={(startDate, endDate) => {
              setDragEvent({
                startDate: startDate,
                endDate: endDate
              });
              setModal(true);
              dispatch(clearSuggestions());
            }} onEditEvent={e => handleEditEvent((e as SlotInfoEvent))} />
                  </IonCol>
                  <IonCol size={"3"}>
                    <UserDetail user={orderInfo.customer} />
                    <IonList>
                      <IonCardTitle style={{
                padding: "1rem"
              }}>
                        All events associated with order:
                      </IonCardTitle>
                      {events.map((event, index) => <IonItem key={index}>
                          <IonLabel>
                            <IonText>
                              <strong>
                                {moment(event.start).format("LLL")}
                              </strong>{" "}
                              -{" "}
                              <strong>{moment(event.end).format("LLL")}</strong>
                            </IonText>
                            <p>Meeting title: {event.title}</p>
                            <p>
                              <IonText color="medium">GB:</IonText>{" "}
                              {operator[event.operatorID]?.name || "Unknown"}
                            </p>
                          </IonLabel>
                        </IonItem>)}
                    </IonList>
                  </IonCol>
                </IonRow> : <></>}
            </> : <IonRow style={{
        paddingLeft: "1rem"
      }}>
              {/* <h1>Not assigned</h1> */}
            </IonRow>}
        </>}
    </>;
};
export default DetailsFeature;