import { Epic } from "redux-observable";
import { EMPTY, from, of } from "rxjs";
import { filter, catchError, switchMap, map } from "rxjs/operators";
import { RootAction, RootState, isActionOf } from "typesafe-actions";
import { apiVersionWrapper } from "../../../helpers/api";
import { showNotification } from "../../Notification/redux/actions";
import { Message } from "./types";
import {
  getMessages as getMessagesAction,
  sendSMS as sendSMSAction,
} from "./actions";

const sendSMS = (token: string, id_order: number, id_message: number) => {
  const API_URL = `${process.env.REACT_APP_API_URL}sendSMS/${id_order}`;
  return apiVersionWrapper(
    API_URL,
    "POST",
    {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    JSON.stringify({ id_message })
  ).pipe(
    map((response) => {
      return response.response;
    }),
    catchError((error: Error) => {
      return of(error);
    })
  );
};

const getMessages = (token: string) => {
  const API_URL = `${process.env.REACT_APP_API_URL}getMessages`;
  return apiVersionWrapper(API_URL, "GET", {
    Authorization: `Bearer ${token}`,
  }).pipe(
    map((response) => {
      return response.response as Message[];
    }),
    catchError((error: Error) => {
      return of(
        showNotification({
          color: "warning",
          message: "Failed to send SMS: " + error.message,
          type: "Toast",
        })
      );
    })
  );
};

export const getMessagesEpic: Epic<RootAction, RootAction, RootState> = (
  action$
) =>
  action$.pipe(
    filter(isActionOf(getMessagesAction.request)),
    switchMap((action) => {
      const { token } = action.payload;
      return from(getMessages(token)).pipe(
        switchMap((response) => {
          if (response as Message[]) {
            return of(getMessagesAction.success(response as Message[]));
          }
          return EMPTY;
        }),
        catchError((message: Error) => {
          return of(
            getMessagesAction.failure(message),
            showNotification({
              color: "warning",
              message: "Failed to fetch messages for SMS",
              type: "Toast",
            })
          );
        })
      );
    })
  );

export const sendSMSEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    filter(isActionOf(sendSMSAction.request)),
    switchMap((action) => {
      const { token, id_message, id_order } = action.payload;
      return from(sendSMS(token, id_order, id_message)).pipe(
        switchMap((response) => {
          if (response.message) {
            const error = response as Error;
            return of(
              showNotification({
                color: "warning",
                message: "Failed to send SMS, try again: " + error.message,
                type: "Toast",
              })
            );
          } else if (response as boolean) {
            return of(
              sendSMSAction.success(true),
              showNotification({
                color: "success",
                message: "Sent successfully",
                type: "Toast",
              })
            );
          }
          return EMPTY;
        }),
        catchError((message: Error) => {
          return of(
            showNotification({
              color: "warning",
              message: "Failed to send SMS: " + message.message,
              type: "Toast",
            })
          );
        })
      );
    })
  );
