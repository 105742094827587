import { GroupAttribute, ChoosenAttributes } from "../OfferForm/offerCalculation";
import { PreFilledOffer } from "../../models";
import { Attributes_API, Products_API, PriceList_API } from "../../helpers/api";
import { getAtrributeGroupByGroupId, getAttribute, getFabricPrice, getProductAttributePrice, getProductBasicPrice } from "../OfferForm/offerCalculation";
import { ReactNode } from "react";
import React from "react";
import { isArray } from "lodash";
export const parseToChoosenAttribute = (offer: PreFilledOffer, attributes: Attributes_API, products: Products_API, pricelist: PriceList_API): {
  choosenAttributes: ChoosenAttributes[];
  basicPrice: number;
  productName: string;
  productId: number;
  product: any;
} => {
  const product = findProduct(products, offer);
  const basicPrice = calculateBasicPrice(products, pricelist, offer);
  const groupsState = getGroupsState(offer, attributes);
  const choosenAttrs = buildChoosenAttributes(offer, attributes, product, basicPrice, groupsState);
  return {
    choosenAttributes: choosenAttrs,
    basicPrice: basicPrice ?? 0,
    productName: product?.name ?? "",
    productId: product?.id_product ?? 0,
    product
  };
};
const findProduct = (products: Products_API, offer: PreFilledOffer) => {
  return offer?.product_id ? products.products.find(p => p.id_product === offer.product_id) : undefined;
};
const calculateBasicPrice = (products: Products_API, pricelist: PriceList_API, offer: PreFilledOffer) => {
  return getProductBasicPrice(products.products!, pricelist, offer.product_id!, Number(offer.width), Number(offer.height), Number(offer.width_2), Number(offer.height_2), Number(offer.width_a), Number(offer.width_b), Number(offer.width_c));
};
const getGroupsState = (offer: PreFilledOffer, attributes: Attributes_API) => {
  const groups = offer && offer.choosen_attributes_id && Object.keys(offer.choosen_attributes_id);
  const groupsState: GroupAttribute[] = [];
  offer && groups && groups.forEach(group => {
    const newGroup = getAtrributeGroupByGroupId(attributes, offer.product_id!, Number(group));
    if (newGroup) {
      !groupsState.includes(newGroup) && groupsState.push(newGroup);
    }
  });
  return groupsState;
};
const buildChoosenAttributes = (offer: PreFilledOffer, attributes: Attributes_API, product: any, basicPrice: number | undefined, groupsState: GroupAttribute[]) => {
  const choosenAttrs: ChoosenAttributes[] = [];
  if (!offer) return choosenAttrs;
  offer.choosen_attributes_id && Object.values(offer.choosen_attributes_id).length > 0 && Object.values(offer.choosen_attributes_id).forEach((attr_id, index) => {
    const attrIds = isArray(attr_id) ? processArrayAttribute(attributes, offer, attr_id, index) : [attr_id];
    attrIds.forEach(singleAttrIdObj => {
      const singleAttrId = typeof singleAttrIdObj === "object" ? singleAttrIdObj.id_product_attribute : singleAttrIdObj;
      const attr = getAttribute(attributes, offer.product_id!, Number(Object.keys(offer.choosen_attributes_id)[index]), singleAttrId!);
      const _position = groupsState.find(g => g.attributes[singleAttrId!]);
      if (attr) {
        const position: number = Number(_position!.group_position);
        const fabricPrice = product ? getFabricPrice(Number(_position!.is_color_group), basicPrice!, Number(attr.price_impact), Number(attr.conversion_rate_attribute), product.tax) : undefined;
        const parsedAttr = product ? {
          id: Number(attr.id_attribute),
          price: getProductAttributePrice(basicPrice!, attr.price_impact, Number(attr.price_impact_value), Number(attr.conversion_rate_attribute), product!.tax, Number(offer.height), fabricPrice ? Number(fabricPrice) : 0)!,
          mounting_price: Number(attr.mounting_price),
          attr_group: Number(_position!.id_attribute_group),
          id_product_attribute: Number(attr.id_product_attribute),
          optional: _position?.optional ? true : false
        } : undefined;
        parsedAttr && choosenAttrs.push({
          position: position,
          attr: parsedAttr
        });
      }
    });
  });
  return choosenAttrs;
};
const processArrayAttribute = (attributes: Attributes_API, offer: PreFilledOffer, attr_id: any, index: number): any[] => {
  const arrayAttributes: any[] = [];
  attr_id.forEach((element: any) => {
    const a = getAttribute(attributes, offer.product_id!, Number(Object.keys(offer.choosen_attributes_id)[index]), element);
    if (a) {
      arrayAttributes.push(a);
    }
  });
  return arrayAttributes;
};
export const renderMeasures = (offer: PreFilledOffer): ReactNode => {
  //return a string with widths and heights that are not empty with label
  const widths = [];
  const heights: string[] = [];
  const widths2: string[] = [];
  const heights2: string[] = [];
  const threeWidths: string[] = [];
  if (offer.width) {
    widths.push(`${offer.width}cm`);
  }
  if (offer.width_2) {
    widths2.push(`${offer.width_2}cm`);
  }
  if (offer.width_a) {
    threeWidths.push(`${offer.width_a}cm`);
  }
  if (offer.width_b) {
    threeWidths.push(`${offer.width_b}cm`);
  }
  if (offer.width_c) {
    threeWidths.push(`${offer.width_c}cm`);
  }
  if (offer.height) {
    heights.push(`${offer.height}cm`);
  }
  if (offer.height_2) {
    heights2.push(`${offer.height_2}cm`);
  }
  if (widths.length > 0 && heights.length > 0 && widths2.length > 0 && heights2.length > 0) {
    return <div className="product-card-measures">
        Width, Height -{" "}
        {widths.map((w, index) => <span key={"wh" + index}>
            {w} x {heights[index]}
          </span>)}{" "}
        Width2, Height2 -{" "}
        {widths2.map((w, index) => <span key={"w2" + index}>
            {w} x {heights2[index]}
          </span>)}
      </div>;
  }
  if (widths.length > 0 && heights.length > 0) {
    return <div className="product-card-measures">
        Width, Height -{" "}
        {widths.map((w, index) => <span key={"h" + index}>
            {w} x {heights[index]}
          </span>)}
      </div>;
  }
  if (threeWidths.length > 0) {
    return <div className="product-card-measures">
        Width A, B, C -{" "}
        {threeWidths.map((w, index) => <span key={"a,b,c" + index}>
            {w} {index + 1 !== threeWidths.length ? "x " : ""}
          </span>)}
      </div>;
  }
  return <></>;
};