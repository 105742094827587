import { ActionType, getType, Reducer } from "typesafe-actions";

import * as Mountings from "./actions";
import { ToastObject } from "../../../models";
import { API_LEADS_PREVIEW } from "../../../models/apiModels";

export type MountingsAction = ActionType<typeof Mountings>;

interface MountingState {
  loading: boolean;
  error?: string | Error;
  mountingRows: API_LEADS_PREVIEW[];
  toast: ToastObject;
}

const initialState: MountingState = {
  loading: true,
  mountingRows: [],
  toast: {
    open: false,
    color: "light",
    message: null,
  },
};

export const mountingsReducer: Reducer<MountingState, MountingsAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getType(Mountings.initMountings.success):
      const _rows = action.payload as any;
      return {
        ...state,
        mountingRows: _rows && _rows.orders ? Object.values(_rows.orders) : [],
        loading: false,
      };
    case getType(Mountings.logTimeSpent.request):
      return {
        ...state,
        toast: {
          open: false,
          color: "light",
          message: null,
        },
      };
    case getType(Mountings.logTimeSpent.success):
      const rowsCopy = [...state.mountingRows];
      // updatedRow.status = "Mounted";
      return {
        ...state,
        mountingRows: rowsCopy,
        toast: {
          open: true,
          color: "success",
          message: "Row successfully update!",
        },
      };
    case getType(Mountings.logTimeSpent.failure):
      return {
        ...state,
        error: action.payload.message,
      };
    case getType(Mountings.resetToast):
      return {
        ...state,
        toast: { open: false, message: "", color: "light" },
      };
    default:
      return state;
  }
};
