import { Route, Redirect } from "react-router";
import React from "react";
import { useTranslation } from "react-i18next";
import { OfferDetailsPage } from "../pages";
const LoginPage = React.lazy(() => import("../pages/Login/LoginPage"));
const DashboardPage = React.lazy(() => import("../pages/Dashboard/DashboardPage"));
// const StatisticsPage = React.lazy(() =>
//   import("../pages/Statistics/StatisticsPage")
// );
const MountingsPage = React.lazy(() => import("../pages/Mountings/MountingsPage"));
const SettingsPage = React.lazy(() => import("../pages/Settings/SettingsPage"));
const LeadsPage = React.lazy(() => import("../pages/Leads/LeadsPage"));
const NotFoundPage = React.lazy(() => import("../pages/NotFound/NotFoundPage"));
const DetailsPage = React.lazy(() => import("../pages/Details/DetailsPage"));
const CalendarsPage = React.lazy(() => import("../pages/Calendars/CalendarsPage"));
export const LoginRoutes: React.FC<{}> = () => {
  return <>
      <Route path="/login" render={props => <LoginPage {...props} />} />
      <Route path="*" render={() => <Redirect to="/login" />} exact={true} />
    </>;
};
export const NotFoundRoute: React.FC<{}> = () => {
  const {
    t
  } = useTranslation();
  return <>
      <Route path="/notfound" render={props => <NotFoundPage pageName={t("NotFoundPage")} {...props} />} />
      <Route path="*" render={() => <Redirect to="/notfound" />} exact={true} />
    </>;
};
export const AllRoutes: React.FC<{}> = () => {
  const {
    t
  } = useTranslation();
  return <>
      <Route path="/:tab(mountings)/:id" render={props => <DetailsPage {...props} pageName={t("DetailsPage")} />} />
      <Route path="/:tab(leads)/:id" render={props => <DetailsPage {...props} pageName={t("DetailsPage")} />} exact={true} />
      <Route path="/:tab(leads)/offer/:order_id/:offer_id" render={props => <OfferDetailsPage {...props} pageName={t("OfferDetailsPage")} />} exact={true} />
      <Route path="/offer/:order_id/:offer_id" render={props => <OfferDetailsPage {...props} pageName={t("OfferDetailsPage")} />} exact={true} />
      <Route path="/:tab(leads)/offer/:order_id/new" render={props => <OfferDetailsPage {...props} pageName={t("OfferDetailsPage")} />} exact={true} />

      <Route path="/offer/:order_id/new" render={props => <OfferDetailsPage {...props} pageName={t("OfferDetailsPage")} />} exact={true} />

      <Route path="/dashboard" render={props => <DashboardPage {...props} pageName={t("Today")} />} exact={true} />
      <Route path="/leads" render={props => <LeadsPage {...props} pageName={t("LeadsPage")} />} exact={true} />
      <Route path="/calendars" render={props => <CalendarsPage {...props} pageName={t("CalendarsPage")} />} exact={true} />
      <Route path="/settings" render={props => <SettingsPage {...props} pageName={t("SettingsPage")} />} exact={true} />

      {/* <Route
        path="/statistics"
        render={(props) => (
          <StatisticsPage {...props} pageName={t("StatisticsPage")} />
        )}
        exact={true}
       /> */}
      <Route path="/mountings" render={props => <MountingsPage {...props} pageName={t("MountingsPage")} />} exact={true} />
      <Route path="*" render={() => <Redirect to="/dashboard" />} />
    </>;
};
export const BookerRoutes: React.FC<{}> = () => {
  const {
    t
  } = useTranslation();
  return <>
      <Route path="/leads" render={props => <LeadsPage {...props} pageName={t("LeadsPage")} />} exact={true} />
      <Route path="/calendars" render={props => <CalendarsPage {...props} pageName={t("CalendarsPage")} />} exact={true} />
      <Route path="/settings" render={props => <SettingsPage {...props} pageName={t("SettingsPage")} />} exact={true} />
      <Route path="/:tab(leads)/:id" render={props => <DetailsPage {...props} pageName={t("DetailsPage")} />} exact={true} />
      <Route path="/:tab(leads)/offer/:order_id/:offer_id" render={props => <OfferDetailsPage {...props} pageName={t("OfferPage")} />} exact={true} />{" "}
      <Route path="/offer/:order_id/:offer_id" render={props => <OfferDetailsPage {...props} pageName={t("OfferDetailsPage")} />} exact={true} />
      <Route path="/:tab(leads)/offer/:order_id/new" render={props => <OfferDetailsPage {...props} pageName={t("OfferDetailsPage")} />} exact={true} />
      <Route path="/offer/:order_id/new" render={props => <OfferDetailsPage {...props} pageName={t("OfferDetailsPage")} />} exact={true} />
      <Route path="*" render={() => <Redirect to="/leads" />} />
    </>;
};
export const GBManagerRoutes: React.FC<{}> = () => {
  const {
    t
  } = useTranslation();
  return <>
      <Route path="*" render={() => <Redirect to="/test" />} exact={true} />
      <Route path="/:tab(leads)/:id" render={props => <DetailsPage {...props} pageName={t("DetailsPage")} />} />
      <Route path="/:tab(leads)/offer/:order_id/:offer_id" render={props => <OfferDetailsPage {...props} pageName={t("OfferPage")} />} />{" "}
      <Route path="/offer/:order_id/:offer_id" render={props => <OfferDetailsPage {...props} pageName={t("OfferDetailsPage")} />} />
      <Route path="/:tab(leads)/offer/:order_id/new" render={props => <OfferDetailsPage {...props} pageName={t("OfferDetailsPage")} />} />
      <Route path="/offer/:order_id/new" render={props => <OfferDetailsPage {...props} pageName={t("OfferDetailsPage")} />} />
      <Route path="/dashboard" render={props => <DashboardPage {...props} pageName={t("Today")} />} exact={true} />
      <Route path="/leads" render={props => <LeadsPage {...props} pageName={t("LeadsPage")} />} exact={true} />
      <Route path="/calendars" render={props => <CalendarsPage {...props} pageName={t("CalendarsPage")} />} exact={true} />
      {/* <Route
        path="/statistics"
        render={(props) => (
          <StatisticsPage {...props} pageName={t("StatisticsPage")} />
        )}
        exact={true}
       /> */}
      <Route path="/settings" render={props => <SettingsPage {...props} pageName={t("SettingsPage")} />} exact={true} />
      <Redirect exact from="/" to="/dashboard" />
    </>;
};
export const MounterRoutes: React.FC<{}> = () => {
  const {
    t
  } = useTranslation();
  return <>
      <Route path="/dashboard" render={props => <DashboardPage {...props} pageName={t("Today")} />} exact={true} />
      <Route path="/calendars" render={props => <CalendarsPage {...props} pageName={t("CalendarsPage")} />} exact={true} />
      <Route path="/mountings" render={props => <MountingsPage {...props} pageName={t("MountingsPage")} />} exact={true} />
      <Route path="/:tab(mountings)/:id" render={props => <DetailsPage {...props} pageName={t("DetailsPage")} />} />
      <Route path="/settings" render={props => <SettingsPage {...props} pageName={t("SettingsPage")} />} exact={true} />
      <Route path="/*" render={() => <Redirect to="/dashboard" />} />
    </>;
};