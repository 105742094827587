import { PreFilledOffer } from "../../models";
import {
  getProductBasicPrice,
  ChoosenAttributes,
  getAttribute,
  getProductAttributePrice,
  getFinalPrice,
} from "../../components/OfferForm/offerCalculation";
import { isArray } from "util";

interface General {
  measuring_price: number;
  measuring_price_threshold: number;
  mounting_price: number;
  mounting_price_extra_width: number;
  mounting_price_extra_meter: number;
  shipping_price: number;
  shipping_price_threshold: number;
  shipping_long_goods: number;
  shipping_price_long_goods: number;
}

export const calculateMountingPrice = (
  attr: any,
  config: any,
  productsjson: any[],
  products: PreFilledOffer[]
) => {
  /**
    Calculate mounting price per offer.

    First, get the minimum mountin price from flat_general Json
    Then, loop over the products.
    Check for product mounting price in flat_product JSON
    Check if product max width is not over the extra fee overdimension threshold (mounting_price_extra_width from flat_general)
    If it is over, ceil the difference and multiply but the extra meter price (mounting_price_extra_meter from flat_general JSON)
    Then loop the attributes for each product.
    Check for mountin_price in flat_attribute JSON
    Add all items to return the final mountin price
    **/
  let mounting_price: General = config;
  let _price = mounting_price.mounting_price;
  let product = productsjson as any[];
  products.forEach((p) => {
    if (p.product_id !== undefined) {
      let _width = p.width ? p.width : 0;
      let _width2 = p.width_2 ? p.width_2 : 0;
      let _width_a = p.width_a ? p.width_a : 0;
      let _width_b = p.width_b ? p.width_b : 0;
      let _width_c = p.width_c ? p.width_c : 0;

      const _product = product.find(
        (_p) => Number(_p.id_product) === Number(p.product_id)
      );
      const mountingPrice = _product ? Number(_product.mounting_price) : 0;
      _price += mountingPrice * p.quantity;

      if (_width && _width2) {
        _width = _width2 > _width ? _width2 : _width;
      }
      if (_width_a + _width_b + _width_c > _width) {
        _width = _width_a + _width_b + _width_c;
      }
      const width_to_apply_extra = mounting_price.mounting_price_extra_width;
      const extra_fee_per_meter =
        mounting_price.mounting_price_extra_meter ?? 0;
      if (_width > width_to_apply_extra) {
        const meters_over = Math.ceil((_width - width_to_apply_extra) / 100);
        _price += meters_over * extra_fee_per_meter * p.quantity;
      }
      // p.choosenAttributes.forEach((a) => (_price += a.attr.mounting_price));

      p.choosen_attributes_id &&
        Object.values(p.choosen_attributes_id).forEach((attr_id, i) => {
          if (isArray(attr_id)) return;
          if (attr_id === 1) return;
          const group = Object.keys(p.choosen_attributes_id)[i];
          const attr_price = attr[p.product_id!][group].attributes[attr_id!]
            ? attr[p.product_id!][group].attributes[attr_id!][0].mounting_price
            : 0;
          _price += Number(attr_price) * p.quantity;
          // (_price += p.product_id
          //   ? _product
          //     ? Number(_product.mounting_price)
          //     : 0
          //   : 0)
        });
      // _price = _price * p.quantity;
    }
  });
  return _price;
};

export const calculateMeassuringPrice = (
  config: any,
  productsTotalPrice: number
) => {
  /**
    Calculate measuring price

    Get total_product of the offer.
    Compare against the threshold for free measurement (measuring_price_threshold from flat_general)
    If not smaller, free measuring.
    If smaller, return measuring_price from flat_general.
    **/
  const measuring: General = config;
  if (productsTotalPrice >= measuring.measuring_price_threshold) {
    return 0;
  } else {
    return measuring.measuring_price;
  }
};

export const calculateShippingPrice = (
  config: any,
  orderTotalPrice: number,
  products: PreFilledOffer[]
) => {
  /**
    Calculate shipping price

    Get total of the order (products + mounting + measuring - discounts)
    Compare against the threshold for free shipping (shipping_price_threshold from flat_general)
    If not smaller, free shipping.
    If smaller, check if at least one product has a bigger width than the shipping_long_good from flat_general
    if product are long good, return shipping_price_long_goods from flat_general
    else return shipping_price from flat_general.
    **/
  const shipping_price_threshold = config.shipping_price_threshold;
  // $total_offer = total_products + mounting + measuring - discounts
  if (shipping_price_threshold !== 0) {
    if (orderTotalPrice >= shipping_price_threshold) return 0;
  }
  let long_goods = false;
  products.forEach((p) => {
    let _width = p.width ? p.width : 0;
    let _width2 = p.width_2 ? p.width_2 : 0;
    // let _width_a = p.width_a ? p.width_a : 0;
    // let _width_b = p.width_b ? p.width_b : 0;
    // let _width_c = p.width_c ? p.width_c : 0;
    if (_width && _width2) {
      _width = _width2 > _width ? _width2 : _width;
    }
    // if (_width_a + _width_b + _width_c > _width) {
    //   _width = _width_a + _width_b + _width_c;
    // }
    const shipping_long_good = config.shipping_long_goods;
    if (shipping_long_good !== 0 && _width > shipping_long_good) {
      long_goods = true;
    }
  });
  if (long_goods) {
    return config.shipping_price_long_goods;
  } else {
    return config.shipping_price;
  }
};

export const calculateTotalProductsPrice = (
  configs: any,
  Offers: PreFilledOffer[]
) => {
  let product = configs.products as any;
  let _price = 0;
  Offers.forEach((offer) => {
    if (offer.product_id === 485) {
      _price += offer.manual ? Number(offer.manual.price) * offer.quantity : 0;
    }
    if (offer.product_id !== undefined && offer.product_id !== 485) {
      const productBasicPrice = getProductBasicPrice(
        configs.products.products,
        configs.pricelist,
        offer.product_id!,
        offer.width,
        offer.height,
        offer.width_2,
        offer.height_2,
        offer.width_a,
        offer.width_b,
        offer.width_c
      );
      const choosenAttributes: ChoosenAttributes[] = [];
      offer.choosen_attributes_id &&
        Object.values(offer.choosen_attributes_id).length > 0 &&
        Object.values(offer.choosen_attributes_id).forEach((attr_id, index) => {
          //
          const attr = getAttribute(
            configs.attributes,
            offer.product_id!,
            Number(Object.keys(offer.choosen_attributes_id)[index]),
            attr_id!
          );
          if (attr) {
            const _price = getProductAttributePrice(
              productBasicPrice!,
              attr!.price_impact,
              Number(attr!.price_impact_value),
              Number(attr!.conversion_rate_attribute),
              product.products.find(
                (_p: any) => _p.id_product === offer.product_id!
              )
                ? product.products.find(
                    (_p: any) => _p.id_product === offer.product_id!
                  ).tax
                : 0,
              offer.height,
              attr!.fabric_price ? Number(attr!.fabric_price) : 0
            );
            const choosenAttribute: ChoosenAttributes = {
              position: 0,
              attr: {
                attr_group: 0,
                price: _price ? _price : 0,
                id: 0,
                id_product_attribute: 0,
                mounting_price: 0,
                optional: true,
              },
            };
            choosenAttributes.push(choosenAttribute);
          }
        });
      const finalPrice = getFinalPrice(productBasicPrice!, choosenAttributes);
      const quantity = offer.quantity;
      _price += quantity * finalPrice;
      // offer.choosenAttributes.forEach((attr) => {
      //   _price += attr.attr.price;
      // });
    }
  });
  return _price;
};

export const calculateTotalOrderPrice = (
  productsPrice: number,
  measuringPrice: number,
  mountingPrice: number,
  shippingPrice?: number
) => {
  if (shippingPrice) {
    return productsPrice + shippingPrice + measuringPrice + mountingPrice;
  }
  return productsPrice + measuringPrice + mountingPrice;
};
