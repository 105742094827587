import { createStore, applyMiddleware } from "redux";
import { createEpicMiddleware } from "redux-observable";
import { RootAction, RootState } from "typesafe-actions";

// import { composeEnhancers } from "./utils";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";

import rootReducer from "./root-reducer";
import rootEpic from "./root-epic";

export const epicMiddleware = createEpicMiddleware<
  RootAction,
  RootAction,
  RootState
>({});

// configure middlewares
const middlewares = [epicMiddleware];
// compose enhancers
const composeEnhancers = composeWithDevTools({
  // Specify here name, actionsBlacklist, actionsCreators and other options
  maxAge: 10,
  serialize: true,
  actionSanitizer: (action) =>
    (action.type === "login/DNA_SUCCESS" ||
      action.type === "login/PRODUCT_SUCCESS" ||
      action.type === "login/CONFIG_SUCCESS" ||
      action.type === "login/ATTRIBUTES_SUCCESS" ||
      action.type === "login/PRICE_SUCCESS") &&
    action
      ? { ...action, data: "<<LONG_BLOB>>" }
      : action,
  stateSanitizer: (state) =>
    state ? { ...state, data: "<<LONG_BLOB>>" } : state,
});
const enhancer = composeEnhancers(applyMiddleware(...middlewares));

// rehydrate state on app start
const initialState = {};

// create store
const store = createStore(rootReducer, initialState, enhancer);

epicMiddleware.run(rootEpic);

// export store singleton instance
export default store;
