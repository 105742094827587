import { ActionType, getType, Reducer } from "typesafe-actions";
import { Color } from "../../../models";
import * as notification from "./actions";

export type NotificationAction = ActionType<typeof notification>;

interface NotificationState {
  isOpen: boolean;
  message: string | null;
  type: notification.NotificationType | null;
  color: Color | null;
  obj?: Object;
}

const initialState: NotificationState = {
  isOpen: false,
  message: null,
  type: null,
  color: null,
};
export const notificationReducer: Reducer<
  NotificationState,
  NotificationAction
> = (state = initialState, action) => {
  switch (action.type) {
    case getType(notification.showNotification):
      const { message, type, color, obj } = action.payload;
      return {
        ...state,
        isOpen: true,
        message,
        color,
        type,
        obj,
      };
    case getType(notification.clearNotification):
      return {
        ...state,
        isOpen: false,
        message: null,
      };
    default:
      return state;
  }
};
