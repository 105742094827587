import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginAction } from "./redux/actions";
import { Login, Modal } from "../../components/";
import { RootState } from "typesafe-actions";
import { showNotification } from "../Notification/redux/actions";
import { Keychain } from "@awesome-cordova-plugins/keychain";
import { IonCol, IonRow, IonText } from "@ionic/react";
import { Capacitor } from "@capacitor/core";
const LoginFeatureComponent: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const error = useSelector((state: RootState) => state.authReducer.error);
  const [showModal, setShowModal] = useState<{
    email: string;
    password: string;
    open: boolean;
  } | null>(null);
  const isMounted = useRef(true); // Track component mount state

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);
  useEffect(() => {
    if (error && isMounted.current) {
      dispatch(showNotification({
        type: "Toast",
        color: "danger",
        message: error
      }));
    }
  }, [error, dispatch]);
  const handleSubmit = (email: string, password: string, credentialsChange: boolean) => {
    if (credentialsChange && Capacitor.getPlatform() === "ios") {
      setShowModal({
        open: true,
        email,
        password
      });
    } else if (!credentialsChange || Capacitor.getPlatform() !== "ios") {
      dispatch(loginAction.request({
        email,
        password
      }));
    }
  };
  const handleForgot = () => {
    // TODO
  };
  const handleModalClose = () => {
    if (isMounted.current) {
      setShowModal(null);
    }
  };
  return <>
      <Modal title="Save new credentials?" isOpen={showModal?.open ?? false} onClose={handleModalClose} children={<IonRow style={{
      textAlign: "center",
      fontSize: "1.5rem",
      height: "100%",
      justifyContent: "center",
      alignItems: "center"
    }}>
            <IonCol>
              <IonText>
                This will overwrite saved credentials, are you sure?
              </IonText>
            </IonCol>
          </IonRow>} actions={[{
      name: "Cancel",
      type: "button",
      buttonStyle: "clear",
      fn() {
        if (isMounted.current) {
          setShowModal({
            open: false,
            email: showModal?.email ?? "",
            password: showModal?.password ?? ""
          });
        }
      }
    }, {
      name: "Save",
      type: "button",
      fn: async () => {
        await Keychain.setJson("user-login", {
          email: showModal?.email,
          password: showModal?.password
        }, false).then(val => {
          if (isMounted.current) {
            setShowModal({
              open: false,
              email: showModal?.email ?? "",
              password: showModal?.password ?? ""
            });
          }
        }).catch(err => console.log("error in setJson promise", err.message));
        if (isMounted.current) {
          dispatch(loginAction.request({
            email: showModal?.email ?? "",
            password: showModal?.password ?? ""
          }));
        }
      }
    }]} />
      <Login forgotHandler={handleForgot} loginHandler={({
      email,
      password,
      credentialsChange
    }) => handleSubmit(email, password, credentialsChange)} />
    </>;
};
const LoginFeature = React.memo(LoginFeatureComponent);
export default LoginFeature;