import {
  ChoosenProduct,
  ChoosenAttributes,
  getProductBasicPrice,
  getAttribute,
  getAtrributeGroupByGroupId,
  getFabricPrice,
  getProductAttributePrice,
  getFinalPrice,
  GroupAttribute,
  getDNA,
  ChoosenAttributesWithoutArray,
} from "./offerCalculation";
import { InputObject } from "../InputsMeasures/InputsMeasures";
import { Offer, Configs } from "./OfferForm";
import { flattenDeep } from "lodash";
import { isArray } from "util";

interface ManualProduct {
  production: string;
  customer: string;
  price?: number;
  id_manual_manufacturer?: number;
}

export const readyToBeSent = (
  allAttributes: any[],
  choosenAttributes: any[],
  product_id?: number
) => {
  //* Workaround belts being required even when they are not selected in the multiselect */
  // 13337, 13338, 13339
  // product id 331 - Gardin - Unig
  if (
    product_id &&
    product_id === 331 &&
    allAttributes.find((a) => a.id_attribute_group === 174)
  ) {
    return true;
  }
  return (
    allAttributes.filter((g) => !g.optional && !g.multiselectable_group)
      .length <=
    choosenAttributes
      .filter((c) => c !== undefined)
      .filter((c) => !c.attr.optional).length
  );
};

export const allInputs = (Inputs: InputObject[]) => {
  return [
    Number(Inputs.find((i) => i.inputType === "width")?.value),
    Number(Inputs.find((i) => i.inputType === "height")?.value),
    Number(Inputs.find((i) => i.inputType === "width_2")?.value),
    Number(Inputs.find((i) => i.inputType === "height_2")?.value),
    Number(Inputs.find((i) => i.inputType === "width_a")?.value),
    Number(Inputs.find((i) => i.inputType === "width_b")?.value),
    Number(Inputs.find((i) => i.inputType === "width_c")?.value),
  ];
};

export const offerToSend = (
  choosenAttributes: ChoosenAttributes[],
  choosenProduct: ChoosenProduct,
  Inputs: InputObject[],
  quantity: number,
  comment: string,
  readyToBeSent: boolean,
  finalPrice: number,
  manualProduct: ManualProduct
) => {
  return {
    offer: {
      choosenAttributes: choosenAttributes!,
      choosenProduct: choosenProduct!,
      width: Number(Inputs.find((i) => i.inputType === "width")?.value),
      height: Number(Inputs.find((i) => i.inputType === "height")?.value),
      width2: Number(Inputs.find((i) => i.inputType === "width_2")?.value),
      height2: Number(Inputs.find((i) => i.inputType === "height_2")?.value),
      width_a: Number(Inputs.find((i) => i.inputType === "width_a")?.value),
      width_b: Number(Inputs.find((i) => i.inputType === "width_b")?.value),
      width_c: Number(Inputs.find((i) => i.inputType === "width_c")?.value),
      quantity,
      comment: comment,
      readyToSend: readyToBeSent,
      finalPrice:
        choosenProduct?.id !== 485 ? finalPrice : manualProduct.price ?? 0, //TODO
      manual:
        choosenProduct?.id === 485
          ? {
              width: Number(Inputs.find((i) => i.inputType === "width")?.value),
              production:
                manualProduct && manualProduct.production
                  ? manualProduct.production
                  : "",
              customer:
                manualProduct && manualProduct.customer
                  ? manualProduct.customer
                  : "",
              price:
                manualProduct && manualProduct.price ? manualProduct.price : 0,
              id_manual_manufacturer: manualProduct.id_manual_manufacturer
                ? manualProduct.id_manual_manufacturer
                : 0,
            }
          : undefined,
    },
  };
};

export const getPrice = (
  offer: Offer,
  configs: Configs,
  choosenProduct: ChoosenProduct,
  Inputs: InputObject[],
  basicProductPrice: number
) => {
  if (offer?.product_id === 485) {
    return offer && offer.manual
      ? { price: Number(offer.manual.price) }
      : { price: 0 };
  }
  if (offer && offer.product_id) {
    // TBD this should be in diferent file and function
    const _productBasicPrice = getProductBasicPrice(
      configs?.products?.products!,
      configs?.pricelist,
      choosenProduct?.id!,
      ...allInputs(Inputs)
    );
    const _choosenAttrs: ChoosenAttributesWithoutArray[] = [];
    offer.choosen_attributes_id &&
      Object.values(offer.choosen_attributes_id).length > 0 &&
      Object.values(offer.choosen_attributes_id).forEach((attr_id, index) => {
        const attr = getAttribute(
          configs?.attributes,
          offer.product_id!,
          Number(Object.keys(offer.choosen_attributes_id)[index]),
          attr_id!
        );
        const attrGroup = getAtrributeGroupByGroupId(
          configs?.attributes,
          offer.product_id!,
          Number(Object.keys(offer.choosen_attributes_id)[index])
        );
        if (attr) {
          const position: number = 0;
          const fabricPrice = getFabricPrice(
            Number(attrGroup!.is_color_group),
            basicProductPrice!,
            Number(attr.price_impact),
            Number(attr.conversion_rate_attribute),
            choosenProduct ? choosenProduct!.tax : 0
          );
          const parsedAttr = {
            id: Number(attr.id_attribute),
            price: getProductAttributePrice(
              _productBasicPrice!,
              attr.price_impact,
              Number(attr.price_impact_value),
              Number(attr.conversion_rate_attribute),
              configs?.products?.products.find(
                (p) => p.id_product === offer.product_id!
              )
                ? configs?.products?.products.find(
                    (p) => p.id_product === offer.product_id!
                  )!.tax
                : 0,
              Number(Inputs.find((i) => i.inputType === "height")?.value),
              fabricPrice ? Number(fabricPrice) : 0
            )!,
            mounting_price: Number(attr.mounting_price),
            attr_group: 0,
            id_product_attribute: Number(attr.id_product_attribute),
            optional: attrGroup?.optional ? true : false,
          };
          _choosenAttrs.push({ position: position, attr: parsedAttr });
        }
      });
    return {
      price: _productBasicPrice
        ? getFinalPrice(_productBasicPrice, _choosenAttrs)
        : 0,
    };
  }
  return { price: 0 };
};

export const saveOffer = (
  choosenProduct: ChoosenProduct,
  Inputs: InputObject[],
  choosenAttributes: ChoosenAttributes[],
  quantity: number,
  comment: string,
  readyToSend: boolean,
  finalPrice: number,
  manualProduct?: ManualProduct,
  multipleAttributes?: { [key: number]: number[] }
) => {
  return {
    offer: {
      multipleAttributes: multipleAttributes,
      choosenAttributes: choosenAttributes!,
      choosenProduct: choosenProduct!,
      width: Number(Inputs.find((i) => i.inputType === "width")?.value),
      height: Number(Inputs.find((i) => i.inputType === "height")?.value),
      width2: Number(Inputs.find((i) => i.inputType === "width_2")?.value),
      height2: Number(Inputs.find((i) => i.inputType === "height_2")?.value),
      width_a: Number(Inputs.find((i) => i.inputType === "width_a")?.value),
      width_b: Number(Inputs.find((i) => i.inputType === "width_b")?.value),
      width_c: Number(Inputs.find((i) => i.inputType === "width_c")?.value),
      quantity,
      comment: comment,
      readyToSend,
      finalPrice:
        choosenProduct?.id !== 485 ? finalPrice : manualProduct?.price ?? 0, //TODO
      manual:
        choosenProduct?.id === 485
          ? {
              width: Number(Inputs.find((i) => i.inputType === "width")?.value),
              production:
                manualProduct && manualProduct.production
                  ? manualProduct.production
                  : "",
              customer:
                manualProduct && manualProduct.customer
                  ? manualProduct.customer
                  : "",
              price:
                manualProduct && manualProduct.price ? manualProduct.price : 0,
              id_manual_manufacturer: manualProduct?.id_manual_manufacturer
                ? manualProduct?.id_manual_manufacturer
                : 0,
            }
          : undefined,
    },
  };
};

export const getChoosenProduct = (product: any) => {
  if (product) {
    const choosenProduct: ChoosenProduct = {
      id: product.id_product,
      inputs:
        product.inputs && product.inputs?.length > 0 ? product.inputs : [],
      mounting_price: product.mounting_price,
      name: product.name,
      price: product.price,
      tax: product.tax,
      limits: product.limits,
      product_type: product.product_type,
    };
    return choosenProduct;
  }
  return undefined;
};

export const initOffer = (
  configs: Configs,
  Inputs: InputObject[],
  offer: Offer,
  choosenProduct?: ChoosenProduct
): {
  basicProductPrice: number;
  choosenAttributes: ChoosenAttributes[];
  hiddenAttributes: { id: number; group_id: number; attr_id: number }[];
} => {
  //Gotta update offer on parent on editing !
  const product = offer?.product_id
    ? configs?.products?.products.find((p) => p.id_product === offer.product_id)
    : undefined; //to populate..

  let hiddenAttributes;
  let choosenAttributes;

  if (choosenProduct) {
    let productBasicPrice: number | undefined;
    if (choosenProduct.inputs && choosenProduct.inputs.length > 0) {
      productBasicPrice = getProductBasicPrice(
        configs?.products?.products!,
        configs?.pricelist,
        choosenProduct.id,
        Number(Inputs.find((i) => i.inputType === "width")?.value),
        Number(Inputs.find((i) => i.inputType === "height")?.value),
        Number(Inputs.find((i) => i.inputType === "width_2")?.value),
        Number(Inputs.find((i) => i.inputType === "height_2")?.value),
        Number(Inputs.find((i) => i.inputType === "width_a")?.value),
        Number(Inputs.find((i) => i.inputType === "width_b")?.value),
        Number(Inputs.find((i) => i.inputType === "width_c")?.value)
      );
    } else {
      productBasicPrice = product ? product?.price : 0;
    }

    const groups =
      offer &&
      offer.choosen_attributes_id &&
      Object.keys(offer.choosen_attributes_id);
    const groupsState: GroupAttribute[] = [];
    offer &&
      groups &&
      groups.forEach((group) => {
        const newGroup = getAtrributeGroupByGroupId(
          configs?.attributes,
          choosenProduct.id,
          Number(group)
        );
        if (newGroup) {
          !groupsState.includes(newGroup) && groupsState.push(newGroup);
        }
      });

    const choosenAttrs: ChoosenAttributesWithoutArray[] = [];
    offer &&
      offer.choosen_attributes_id &&
      Object.values(offer.choosen_attributes_id).length > 0 &&
      Object.values(offer.choosen_attributes_id).forEach((attr_id, index) => {
        const attr = getAttribute(
          configs?.attributes,
          choosenProduct.id,
          Number(Object.keys(offer.choosen_attributes_id)[index]),
          attr_id!
        );

        const _position = groupsState.find(
          (g) => g.attributes[attr_id! as number]
        );
        if (attr) {
          const position: number = Number(_position!.group_position); //wrong it has to be group position, i got to sort them..
          const fabricPrice = getFabricPrice(
            Number(_position!.is_color_group),
            productBasicPrice!,
            Number(attr.price_impact),
            Number(attr.conversion_rate_attribute),
            choosenProduct.tax
          );

          const parsedAttr = {
            id: Number(attr.id_attribute),
            price: getProductAttributePrice(
              productBasicPrice!,
              attr.price_impact,
              Number(attr.price_impact_value),
              Number(attr.conversion_rate_attribute),
              choosenProduct.tax,
              Number(Inputs.find((i) => i.inputType === "height")?.value),
              fabricPrice ? Number(fabricPrice) : 0
            )!,
            mounting_price: Number(attr.mounting_price),
            attr_group: Number(_position!.id_attribute_group),
            id_product_attribute: Number(attr.id_product_attribute),
            optional: _position?.optional ? true : false,
          };
          choosenAttrs.push({ position: position, attr: parsedAttr });
        }
      });
    choosenAttributes = choosenAttrs.sort((a, b) => a.position - b.position);
    hiddenAttributes = flattenDeep(
      getDNA(
        configs?.dna,
        choosenProduct?.id!,
        choosenAttrs,
        ...allInputs(Inputs)
      )
    );
  }
  return {
    basicProductPrice: choosenProduct
      ? getProductBasicPrice(
          configs?.products?.products!,
          configs?.pricelist,
          choosenProduct.id,
          ...allInputs(Inputs)
        ) ?? 0
      : 0,
    choosenAttributes: choosenAttributes ?? [],
    hiddenAttributes: hiddenAttributes ?? [],
  };
};

export const offerHasMultiselectAttribute = (offer: Offer) => {
  const obj: { [key: number]: number[] } = {};
  Object.keys(offer.choosen_attributes_id).forEach((key) => {
    if (isArray(offer.choosen_attributes_id[key])) {
      obj[key] = offer.choosen_attributes_id[key];
    }
  });
  return obj;
};
