import React from "react";
import "./Alert.css";
import { AlertButton, AlertInput, IonAlert } from "@ionic/react";
import { useTranslation } from "react-i18next";
interface Props {
  open: boolean;
  onClose: () => void;
  header: string;
  message?: string;
  buttons?: AlertButton[];
  inputs?: AlertInput[];
}
const Alert: React.FC<Props> = props => {
  const {
    t
  } = useTranslation();
  const {
    open,
    onClose,
    header,
    message,
    buttons,
    inputs
  } = props;
  return <>
      <IonAlert isOpen={open} onDidDismiss={() => onClose()} header={t(header)} message={message && t(message)} inputs={inputs ? inputs.map(input => input) : undefined} buttons={buttons ? buttons.map(button => button) : undefined} />
    </>;
};
export default Alert;