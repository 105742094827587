import React from "react";
import { Note } from "./AssignNotesModal";
import { ModalAction } from "../../../models";
const SendSMSFeature = React.lazy(() => import("../../SendSMS/SendSMSFeature"));
const AssignNotesModal = React.lazy(() => import("./AssignNotesModal"));
const UpdateAssignedGB = React.lazy(() => import("./UpdateAssignedGB"));
const RescheduleModal = React.lazy(() => import("./RescheduleModal"));
const CustomerResponseModal = React.lazy(() => import("./CustomerResponseModal"));
const AssingMounterModal = React.lazy(() => import("./AssingMounterModal"));
const ResendAlert = React.lazy(() => import("./ResendAlert"));
export interface ModalsProps {
  updateAssignedGB: {
    open: boolean;
    id_order: number;
    onClose: () => void;
  };
  smsModal: {
    open: boolean;
    id_order: number;
    onClose: () => void;
  };
  assignNotesModal: {
    notes: Note[];
    open: boolean;
    onClose: () => void;
    actions: ModalAction[];
  };
  rescheduleModal: {
    open: boolean;
    order_id: number;
    onClose: () => void;
    isReschedule: boolean;
  };
  customerResponseModal: {
    open: boolean;
    onClose: () => void;
  };
  assingMounterModal: {
    rowID: number;
    onClose: () => void;
    open: boolean;
  };
  resendAlert: {
    id_order: number;
    onClose: () => void;
    open: boolean;
  };
}
export default function Modals(props: ModalsProps) {
  const {
    assignNotesModal,
    assingMounterModal,
    customerResponseModal,
    rescheduleModal,
    resendAlert,
    smsModal,
    updateAssignedGB
  } = props;
  return <>
      <UpdateAssignedGB {...updateAssignedGB} />
      <SendSMSFeature {...smsModal} />
      <AssignNotesModal {...assignNotesModal} />
      <RescheduleModal {...rescheduleModal} />
      <CustomerResponseModal {...customerResponseModal} />
      <AssingMounterModal {...assingMounterModal} />
      <ResendAlert {...resendAlert} />
    </>;
}