import { cloneDeep } from "lodash";
import { ActionType, getType, Reducer } from "typesafe-actions";
import { PreFilledOffer } from "../../../models";
import { generateRandomID, storageGet, storageSet } from "../../../helpers";
import * as offersActions from "./actions";
import { API_LEADS } from "../../../models/apiModels";

export type OffersAction = ActionType<typeof offersActions>;

export type OfferDetailState = {
  offers: PreFilledOffer[];
  mountingTime: string;
  discount: number;
  extraMounting: number;
  generalComment?: string;
};
interface OffersState {
  // order_id: number | undefined;
  offerDetail: OfferDetailState | undefined;
  isLoading: boolean;
  redirect?: boolean;
  offer_id?: number;
  scrollBottom?: boolean;
  selectedOffer?: PreFilledOffer;
  selectedOfferIndex?: number;
}

const emptyProduct = {
  choosen_attributes_id: {},
  offer_id: 0,
  order_id: 0,
  product_attributes_id: {},
  quantity: 1,
  uuid: generateRandomID(),
  prefilled: false,
  expanded: true,
  new: true,
};

const initialState: OffersState = {
  // order_id: undefined,
  offerDetail: undefined,
  isLoading: true,
  redirect: false,
  offer_id: undefined,
  scrollBottom: false,
  selectedOffer: undefined,
  selectedOfferIndex: undefined,
};

// const pouchDb = initDetailsDB();
export const offerDetailReducer: Reducer<OffersState, OffersAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getType(offersActions.updateComment):
      return {
        ...state,
        selectedOffer: {
          ...state.selectedOffer!,
          comment: action.payload,
        },
      };
    case getType(offersActions.newExpandProduct):
      return {
        ...state,
        // selectedOffer: action.payload.offer,
        selectedOfferIndex: action.payload.index,
      };
    case getType(offersActions.collapseProduct):
      return {
        ...state,
        selectedOffer: undefined,
        selectedOfferIndex: undefined,
      };
    case getType(offersActions.init):
      return {
        ...state,
        redirect: false,
        isLoading: false,
        offerDetail: {
          offers: [emptyProduct],
          mountingTime: "",
          discount: 0,
          extraMounting: 0,
        },
      };
    case getType(offersActions.addNewProduct):
      const offerDetailWithNewProduct = cloneDeep(state.offerDetail);
      offerDetailWithNewProduct?.offers.splice(0, 0, action.payload.offer);
      return {
        ...state,
        offerDetail: offerDetailWithNewProduct,
      };
    case getType(offersActions.expandProduct):
      const copyOffers = cloneDeep(state.offerDetail);
      const offerProduct = copyOffers?.offers[action.payload.index];
      if (offerProduct) {
        offerProduct.expanded = !offerProduct.expanded;
      }
      return {
        ...state,
        offerDetail: copyOffers,
      };
    case getType(offersActions.copyProduct):
      const copyOfferDetail = cloneDeep(state.offerDetail);
      action.payload.offer.prefilled = false;
      action.payload.offer.uuid = generateRandomID();
      action.payload.offer.copied = true;
      action.payload.offer.comment = undefined;
      action.payload.offer.expanded = true;
      // TODO Should see the inputs that this product has to avoid putting to 0 inputs that don't exist
      action.payload.offer.height_2 = 0;
      action.payload.offer.width_2 = 0;
      // action.payload.offer.width_a = action.payload.offer.width_a ? 0 : undefined
      // action.payload.offer.width_b = action.payload.offer.width_b ? 0 : undefined
      // action.payload.offer.width_c = action.payload.offer.width_c ? 0 : undefined
      copyOfferDetail?.offers.push(action.payload.offer);
      return {
        ...state,
        offerDetail: copyOfferDetail,
      };
    case getType(offersActions.deleteProduct):
      const copy = cloneDeep(state.offerDetail);

      copy?.offers.splice(action.payload.index, 1);
      return {
        ...state,
        offerDetail: copy,
        scrollBottom: true,
      };
    case getType(offersActions.addNewEmptyProduct.request):
      return {
        ...state,
        isLoading: true,
        scrollBottom: true,
      };
    case getType(offersActions.addNewEmptyProduct.success):
      const addOfferDetail = cloneDeep(state.offerDetail);
      emptyProduct.uuid = generateRandomID();
      if (addOfferDetail) {
        // addOfferDetail?.offers.splice(0, 0, emptyProduct);
        addOfferDetail?.offers.push(emptyProduct);
      }
      const cleanOfferDetail: OfferDetailState = {
        discount: action.payload.discount,
        extraMounting: action.payload.extraMounting,
        generalComment: action.payload.generalComment,
        mountingTime: action.payload.mountingTime,
        offers: [emptyProduct],
      };
      return {
        ...state,
        isLoading: false,
        offerDetail: addOfferDetail ? addOfferDetail : cleanOfferDetail,
        scrollBottom: false,
        selectedOfferIndex: state.offerDetail?.offers.length,
        offer: emptyProduct,
      };
    case getType(offersActions.updateProduct):
      const updateCopy = cloneDeep(state.offerDetail);
      if (updateCopy) {
        action.payload.product.prefilled = true;
        action.payload.product.new = false;
        updateCopy.offers[action.payload.index] = action.payload.product;
      }
      return {
        ...state,
        offerDetail: updateCopy,
        selectedOffer: action.payload.product,
      };
    case getType(offersActions.draftOffer):
      // pouchDb
      //   .get("details")
      //   .then(function (doc) {
      //     // return pouchDb.put({
      //     //   _id: "details",
      //     //   _rev: doc._rev,
      //     // });
      //   })
      //   .then(function (response) {
      //     // handle response
      //   })
      //   .catch(function (err) {
      //     // if it doesnt have create
      //     // return pouchDb.put({
      //     //   _id: "details",
      //     //   ...parsedObject,
      //     // });
      //   });
      // storageGet<{ [index: number]: offersActions.DraftOffer }>(
      //   "draftOffers"
      // ).then((rsp) => {
      //   storageSet("draftOffers", {
      //     ...rsp,
      //     [action.payload.id_order]: action.payload.offer,
      //   });
      // });
      return {
        ...state,
      };
    case getType(offersActions.getDraft.request):
    case getType(offersActions.getOffers.request):
      return { ...state, isLoading: true, redirect: false };
    case getType(offersActions.getDraft.success):
      return {
        ...state,
        offerDetail: action.payload,
        isLoading: false,
      };
    case getType(offersActions.getOffers.success):
      return {
        ...state,
        offerDetail: action.payload,
        isLoading: false,
        offer_id: undefined,
      };
    case getType(offersActions.getOffers.failure):
      return {
        ...state,
        isLoading: false,
      };
    case getType(offersActions.cleanProducts):
      return {
        ...state,
        offerDetail: undefined,
        isLoading: false,
      };
    case getType(offersActions.createOffer.success):
      storageGet<{ [index: number]: offersActions.DraftOffer }>(
        "draftOffers"
      ).then((rsp) => {
        if (rsp) {
          delete rsp![action.payload.id_order];
          storageSet("draftOffers", {
            rsp,
          });
        }
      });
      storageGet<API_LEADS[]>("orders").then((rsp) => {
        if (rsp) {
          const cast = rsp as any;
          const newOrder = cast.orders[action.payload.id_order];
          newOrder.current_state_gardinbus =
            newOrder.current_state_gardinbus + 1;
          storageSet<API_LEADS[]>("orders", {
            ...cast,
            orders: {
              ...cast.orders,
              [action.payload.id_order]: newOrder,
            },
          });
        }
      });
      return {
        ...state,
        redirect: true,
        offer_id: action.payload.offer.saveOffer
          ? action.payload.id_offer
          : undefined,
      };
    case getType(offersActions.updateOffer.success):
      storageGet<{ [index: number]: offersActions.DraftOffer }>(
        "draftOffers"
      ).then((rsp) => {
        if (rsp) {
          delete rsp![action.payload.id_order];
          storageSet("draftOffers", {
            rsp,
          });
        }
      });
      storageGet<API_LEADS[]>("orders").then((rsp) => {
        if (rsp) {
          const cast = rsp as any;
          const newOrder = cast.orders[action.payload.id_order];
          newOrder.current_state_gardinbus =
            newOrder.current_state_gardinbus + 1;
          storageSet<API_LEADS[]>("orders", {
            ...cast,
            orders: {
              ...cast.orders,
              [action.payload.id_order]: newOrder,
            },
          });
        }
      });
      return {
        ...state,
        redirect: true,
        offer_id: action.payload.offer.submitOffer ? undefined : 0,
      };
    default:
      return state;
  }
};
