import { combineEpics } from "redux-observable";
import * as loginEpics from "../features/Login/redux/epics";
import * as leadsEpics from "../features/Leads/redux/epics";
import * as detailsEpics from "../features/Details/redux/epics";
import * as calendarsEpics from "../features/Calendars/redux/epics";
import * as MountingsEpics from "../features/Mountings/redux/epics";
import * as settingsEpics from "../features/Settings/redux/epics";
import * as offerDetailEpics from "../features/OfferDetail/redux/epics";
import * as dashboardEpics from "../features/Dashboard/redux/epics";
import * as sendSMSEpics from "../features/SendSMS/redux/epics";

export default combineEpics(
  ...Object.values(sendSMSEpics),
  ...Object.values(loginEpics),
  ...Object.values(leadsEpics),
  ...Object.values(detailsEpics),
  ...Object.values(calendarsEpics),
  ...Object.values(MountingsEpics),
  ...Object.values(settingsEpics),
  ...Object.values(offerDetailEpics),
  ...Object.values(dashboardEpics)
);
