import React, { useRef, useState } from "react";
import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonPage, IonTitle, IonToolbar, isPlatform } from "@ionic/react";
import "./OfferDetailsPage.css";
import { OfferDetailFeature } from "../../features";
import { RouteComponentProps } from "react-router";
import { saveOutline, sendOutline, duplicateOutline } from "ionicons/icons";
import { OfferActions } from "../../features/OfferDetail/OfferDetailFeature";
import { useTranslation } from "react-i18next";
import { RootState } from "typesafe-actions";
import { useSelector } from "react-redux";
const OfferDetailsPage: React.FC<{
  pageName: string;
} & RouteComponentProps<{
  order_id: string;
  offer_id: string;
}>> = props => {
  const offerActions = useRef<OfferActions>();
  const {
    t
  } = useTranslation();
  const offerDetail = useSelector((state: RootState) => state.offerDetailReducer.offerDetail);
  const [OfferWasSent, setOfferWasSent] = useState(false);
  React.useEffect(() => {
    setOfferWasSent(offerDetail?.offers.filter(offer => !offer.sent).length === 0 ? true : false);
  }, [offerDetail?.offers]);
  return <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonButtons slot={"end"}>
            {!OfferWasSent && <IonButton style={{
            width: "100%"
          }} onClick={() => offerActions.current?.onSaveDraft("New")}>
                <IonIcon slot="icon-only" style={isPlatform("cordova") ? {
              marginRight: "0.4rem"
            } : undefined} icon={duplicateOutline} />
                {isPlatform("cordova") && <>{t("SaveNewDraft")}</>}
              </IonButton>}
            <IonButton style={{
            width: "100%"
          }} onClick={() => {
            offerActions.current?.onSaveDraft("Update");
            if (OfferWasSent) {
              //duplicate this so go back to offer list
              props.history.goBack();
            }
          }}>
              {OfferWasSent ? <IonIcon slot="icon-only" style={isPlatform("cordova") ? {
              marginRight: "0.4rem"
            } : undefined} icon={duplicateOutline} /> : <>
                  <IonIcon slot="icon-only" style={isPlatform("cordova") ? {
                marginRight: "0.4rem"
              } : undefined} icon={saveOutline} />
                </>}
              {isPlatform("cordova") && <>{OfferWasSent ? t("Duplicate") : t("SaveDraft")}</>}
            </IonButton>
            <IonButton style={{
            marginLeft: "1rem",
            width: "100%"
          }} onClick={() => offerActions.current?.onSend()}>
              <IonIcon slot="icon-only" style={isPlatform("cordova") ? {
              marginRight: "0.4rem"
            } : undefined} icon={sendOutline} />

              {isPlatform("cordova") && Number(props.match.params.offer_id) && Number(props.match.params.order_id) ? <>{t("UpdateAndSend")}</> : isPlatform("cordova") && <>{t("SendToClient")}</>}
            </IonButton>
          </IonButtons>
          {!isPlatform("cordova") && <IonTitle>{props.pageName}</IonTitle>}
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {/* <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{props.pageName}</IonTitle>
          </IonToolbar>
         </IonHeader> */}

        <OfferDetailFeature ref={offerActions} offer_id={props.match.params.offer_id} order_id={props.match.params.order_id} {...props} />
      </IonContent>
    </IonPage>;
};
export default OfferDetailsPage;