import { IonModal } from "@ionic/react";
import React from "react";
import "./ExpandableContainer.css";
type Props = React.FC<{
  open: boolean;
}>;
const ExpandableContainer: Props = ({
  children,
  open
}) => {
  return <div>
      <IonModal className="modal-fullscreen" isOpen={open}>
        {children}
      </IonModal>
      {children}
    </div>;
};
export default ExpandableContainer;