import React, { createRef, useState, useMemo, useEffect } from "react";
import { IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonButton, IonIcon, IonCardSubtitle, IonRow, IonLoading } from "@ionic/react";
import { copyOutline, pencilOutline, saveOutline, trashBinOutline, chevronDownOutline, chevronUpOutline, warningOutline, checkmarkCircleOutline } from "ionicons/icons";
import { DNA_API, Configuration_API, Products_API, PriceList_API, Attributes_API } from "../../helpers/api";
import { useTranslation } from "react-i18next";
import "./ProductCard.css";
import { getFinalPrice, OfferAttributes } from "../OfferForm/offerCalculation";
import OfferForm from "../OfferForm";
import { useDispatch } from "react-redux";
import { showNotification } from "../../features/Notification/redux/actions";
import { parseToChoosenAttribute, renderMeasures } from "./helpers";
import { SaveOffer } from "../OfferForm/OfferForm";
import { PreFilledOffer } from "../../models/models";
interface Props {
  offer: PreFilledOffer;
  onSave: (saveProduct: PreFilledOffer) => void;
  onCopy: (copiedProduct: PreFilledOffer) => void;
  onDelete: (product: PreFilledOffer) => void;
  onError: (error: string) => void;
  offer_id: number;
  order_id: number;
  loading: boolean;
  ordinal_reference: number;
  configs?: {
    dna?: DNA_API;
    global_config?: Configuration_API;
    products?: Products_API;
    pricelist?: PriceList_API;
    attributes?: Attributes_API;
  };
  onExpand: (index: number) => void;
  onCollapse: () => void;
  expanded: boolean;
  commentMessages: string[];
  readyToBeSent: (bol: boolean) => void;
}
const offerAttributesToOffer = (offer_id: number, order_id: number, uuid: string, offer: OfferAttributes): PreFilledOffer => {
  const attrArray: {
    [index: number]: number | number[];
  } = {};
  const attrIdArray: {
    [index: number]: number;
  } = {};
  offer.choosenAttributes.forEach((attr: any) => {
    attrArray[attr.attr.attr_group] = attr.attr.id_product_attribute;
    attrIdArray[attr.attr.attr_group] = attr.attr.id;
  });
  offer.multipleAttributes && Object.keys(offer.multipleAttributes).forEach((key, index) => {
    const _key = Number(key);
    attrArray[_key] = offer.multipleAttributes![_key];
  });
  const newOffer: PreFilledOffer = {
    uuid: uuid,
    product_attributes_id: attrArray,
    choosen_attributes_id: attrArray,
    height_2: offer.height2 ? Number(offer.height2) : undefined,
    height: offer.height ? Number(offer.height) : undefined,
    offer_id: offer_id,
    order_id: order_id,
    product_id: offer.choosenProduct.id,
    quantity: offer.quantity,
    width_2: offer.width2 ? Number(offer.width2) : undefined,
    width_a: offer.width_a ? Number(offer.width_a) : undefined,
    width_b: offer.width_b ? Number(offer.width_b) : undefined,
    width_c: offer.width_c ? Number(offer.width_c) : undefined,
    width: Number(offer.width),
    prefilled: false,
    comment: offer.comment,
    readyToSend: offer.readyToSend,
    manual: offer.manual
  };
  return newOffer;
};
const findRepeatedPositions = (attributesArray: any) => {
  const positionsCount = {};
  const repeatedPositions = [];
  attributesArray.forEach((attributeObj: any) => {
    const position = attributeObj.position;
    if (positionsCount[position]) {
      positionsCount[position]++;
    } else {
      positionsCount[position] = 1;
    }
  });
  for (const [position, count] of Object.entries(positionsCount)) {
    if (count > 1) {
      const repeatedAttributes = attributesArray.filter(attributeObj => attributeObj.position === Number(position));
      repeatedPositions.push(...repeatedAttributes);
    }
  }
  return repeatedPositions;
};
const ProductCard: React.FC<Props> = props => {
  const {
    offer,
    onSave,
    onCopy,
    onDelete,
    offer_id,
    order_id,
    configs,
    onExpand,
    expanded,
    onCollapse,
    loading,
    commentMessages,
    readyToBeSent
  } = props;
  const [Editable, setEditable] = useState(offer.prefilled || offer.copied ? false : true);
  const newRef = createRef<SaveOffer>();
  const [Ready, setReady] = useState<boolean>();
  useEffect(() => {
    if (newRef.current && newRef.current.onCheckIfReadyToSend() && Ready !== null) {
      setReady(newRef.current.onCheckIfReadyToSend().readyToSend);
    }
    readyToBeSent(Ready ?? false);
    return () => {};
  }, [Ready, newRef, readyToBeSent]);
  const {
    t
  } = useTranslation();
  const dispatch = useDispatch();
  const {
    basicPrice,
    choosenAttributes: choosenAttrs,
    productName,
    productId
  } = parseToChoosenAttribute(offer, configs!.attributes!, configs!.products!, configs!.pricelist!);
  const result = findRepeatedPositions(choosenAttrs);
  const offerForm = useMemo(() => <OfferForm readyToSend={ready => {
    setReady(ready);
  }} multipleAttributes={result} commentMessages={commentMessages} ref={newRef} editing={Editable} onError={(error, obj) => dispatch(showNotification({
    color: "warning",
    message: error,
    type: "Toast",
    obj: obj
  }))} saved={false} configs={props.configs} offer={props.offer} offer_new={offer.new} />, [commentMessages, newRef, Editable, props.configs, props.offer, offer.new, dispatch]);
  const productPrice = getFinalPrice(basicPrice!, choosenAttrs!) * offer.quantity;
  const individualProductPrice = getFinalPrice(basicPrice!, choosenAttrs!);
  return <>
      <IonCard>
        <IonCardHeader className="product-card-header">
          <span>
            <IonCardTitle className="product-card-title">
              [{props.ordinal_reference + 1}]{" "}
              {productName ? " - " + productName : null}{" "}
              {!!!offer.new && <>
                  {Ready ? <IonIcon className="icon-header success" icon={checkmarkCircleOutline} /> : <IonIcon className="icon-header warning" icon={warningOutline} />}
                </>}
            </IonCardTitle>
            <IonCardSubtitle>{renderMeasures(offer)}</IonCardSubtitle>
          </span>
          <span>
            <h3>
              {!isNaN(productPrice) && !offer.manual?.price ? productPrice : offer.manual?.price ? Number(offer.manual.price) * offer.quantity : productPrice}{" "}
              kr.
            </h3>
            <h5>
              ({offer.quantity}x
              {!isNaN(individualProductPrice) && !offer.manual?.price ? individualProductPrice : offer.manual?.price ? Number(offer.manual.price) : individualProductPrice}
              kr.)
            </h5>
          </span>
        </IonCardHeader>

        <IonCardContent>
          <IonRow></IonRow>
          <IonRow>
            {choosenAttrs.map((attr, i) => {
            const c = (attr as any);
            const attr_id = c.attr.id_product_attribute!;
            const attr_name = configs!.attributes![productId][c.attr.attr_group!].attributes[attr_id][0].public_name;
            if (i + 1 === choosenAttrs.length) {
              return attr_name;
            } else {
              return attr_name + ", ";
            }
          })}
          </IonRow>
          <IonRow>
            <b>Comment: </b> {offer.comment ? offer.comment : "No comment"}
          </IonRow>
          <IonRow className="product-actions">
            <span>
              <IonButton fill="outline" onClick={() => {
              expanded ? onCollapse() : onExpand(props.ordinal_reference);
            }}>
                {expanded ? "Collapse" : "Expand"}
                <IonIcon icon={expanded ? chevronUpOutline : chevronDownOutline} />
              </IonButton>
            </span>
            <span>
              <IonButton fill="clear" disabled={offer.product_id ? false : true} style={{
              marginRight: ".5rem"
            }} onClick={() => {
              onCopy(offer);
            }}>
                <IonIcon icon={copyOutline} />
                {t("Copy")}
              </IonButton>

              {!Editable ? <IonButton fill="outline" onClick={() => {
              setEditable(true);
              !expanded && onExpand(props.ordinal_reference);
            }}>
                  <IonIcon icon={pencilOutline} />
                  {t("Edit")}
                </IonButton> : <>
                  <IonButton fill="clear" color={"danger"} onClick={() => {
                onDelete(offer);
                setEditable(false);
              }}>
                    <IonIcon icon={trashBinOutline} />
                    {t("Delete")}
                  </IonButton>

                  <IonButton fill="outline" onClick={() => {
                setEditable(false);
                if (newRef && newRef.current) {
                  // setPrice(newRef.current.onGetPrice().price);
                  onSave(offerAttributesToOffer(offer_id, order_id, offer.uuid, newRef.current?.onSave().offer));
                }
              }}>
                    <IonIcon icon={saveOutline} />
                    {t("Save")}
                  </IonButton>
                </>}
            </span>
          </IonRow>
          {loading ? <IonLoading isOpen={true} /> :
        // <Modal isOpen={expanded} onClose={()=> onExpand(props.ordinal_reference)} title="test" >
        <IonCard className={!expanded ? "offerFormCard" : "offerFormCardExpanded"}>
                <IonCardHeader style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}>
                  <span>
                    {!Editable ? null : <IonCardTitle>{t("SaveToUpdatePrice")}</IonCardTitle>}
                    {offer.product_id && <IonCardSubtitle color={"primary"}>
                        {t("ProductID") + offer.product_id}
                      </IonCardSubtitle>}
                  </span>
                </IonCardHeader>
                <IonCardContent style={{
            padding: 0
          }}>
                  {offerForm}
                </IonCardContent>
              </IonCard>
        // </Modal>
        }
        </IonCardContent>
      </IonCard>
    </>;
};
export default ProductCard;