import { createAction } from "typesafe-actions";
import { Color } from "../../../models";

export type NotificationType =
  | "Notification List"
  | "Top Bar"
  | "Google"
  | "Toast";
export const showNotification = createAction("notification/SHOW_NOTIFICATION")<{
  message: string;
  type: NotificationType;
  color: Color;
  obj?: Object;
}>();

export const clearNotification = createAction(
  "notification/CLEAR_NOTIFICATION"
)<void>();
