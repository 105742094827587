import React from "react";
import "./ListOrderedProducts.css";
import { OrderedProducts } from "../../models/models";
import { IonRow, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonText, IonCol, IonCardSubtitle, IonButton, IonIcon } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { closeOutline, expandOutline } from "ionicons/icons";
interface IProps {
  orderedProducts: OrderedProducts[];
  order_comment?: string;
  expand?: boolean;
  onExpand?: (expand: boolean) => void;
}
type Props = React.FC<IProps>;
const ListOrderedProducts: Props = ({
  orderedProducts,
  order_comment,
  expand,
  onExpand
}) => {
  const {
    t
  } = useTranslation();
  const renderProductMeasures = (product: OrderedProducts) => {
    const measurements = [product.product_width && `Width ${product.product_width}cm`, product.product_height && `Height ${product.product_height}cm`, product.product_height_2 && `Height 2 ${product.product_height_2}cm`, product.product_width_2 && `Width 2 ${product.product_width_2}cm`, product.product_width_a && `Width A ${product.product_width_a}cm`, product.product_width_b && `Width B ${product.product_width_b}cm`, product.product_width_c && `Width C ${product.product_width_c}cm`].filter(Boolean);
    return measurements.join(", ");
  };
  return <>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle className={"OrderedProducts-header"}>
            <IonText>{t("OrderedProducts")}</IonText>
            {expand !== undefined && onExpand && <IonButton className="ion-float-end" fill={"outline"} onClick={() => onExpand(!expand)}>
                <IonIcon icon={expand ? closeOutline : expandOutline} />
                {expand ? "Close" : "Expand"}
              </IonButton>}
          </IonCardTitle>
          {order_comment && <IonCardSubtitle>
              <IonText>{t("OrderComment")}</IonText> {order_comment}
            </IonCardSubtitle>}
        </IonCardHeader>
        <IonCardContent style={{
        maxHeight: expand ? "100vh" : "22rem",
        overflowY: "auto",
        padding: "0",
        paddingBottom: expand ? "10rem" : "0",
        width: "100vw"
      }}>
          <IonRow style={{
          padding: "1rem"
        }}>
            <IonCol size={"2"}>
              <IonText>{t("Product")}</IonText>
            </IonCol>
            <IonCol size={"2"}>
              <IonText>{t("Measures")}</IonText>
            </IonCol>
            <IonCol size={"2"}>
              <IonText>{t("UnitPrice")}</IonText>
            </IonCol>
            <IonCol size={"2"}>
              <IonText>{t("Qty")}</IonText>
            </IonCol>
            <IonCol size={"2"}>
              <IonText>{t("Comment")}</IonText>
            </IonCol>
            <IonCol size={"2"}>
              <IonText>{t("Total")}</IonText>
            </IonCol>
          </IonRow>
          {orderedProducts.map((product, index) => <IonRow key={product.id_product + index} style={{
          margin: 0,
          padding: "1rem"
        }} className="ordered-products-item">
              <IonCol size="2">
                <h2>
                  <b>{product.product_name}</b>
                </h2>
                {product.attributes?.map(attr => <IonRow key={attr.id_attribute}>
                    {attr.group_public_name + ": " + attr.attribute_public_name}
                  </IonRow>)}
              </IonCol>
              <IonCol size="2">{renderProductMeasures(product)}</IonCol>
              <IonCol size="2">
                <b>{product.unit_price_tax_incl + t("Currency")}</b>
              </IonCol>
              <IonCol size="2">
                <b>{product.product_quantity}</b>
              </IonCol>
              <IonCol size="2">
                <b>{product.product_comment}</b>
              </IonCol>
              <IonCol size="2">
                <b>{product.total_price_tax_incl + t("Currency")}</b>
              </IonCol>
            </IonRow>)}
        </IonCardContent>
      </IonCard>
    </>;
};
export default ListOrderedProducts;