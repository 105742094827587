import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { defineCustomElements } from "@ionic/pwa-elements/loader";
import { Provider } from "react-redux";
import store from "./store/";
import i18n from "./i18next";
import { I18nextProvider } from "react-i18next";
import { IonLoading } from "@ionic/react";
import { ErrorBoundary } from "react-error-boundary";
import Error from "./components/Error";
ReactDOM.render(<ErrorBoundary fallbackRender={fallback => <Error error={fallback.error.message} />}>
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <Suspense fallback={<IonLoading />}>
          <App />
        </Suspense>
      </I18nextProvider>
    </Provider>
  </ErrorBoundary>, document.getElementById("root"));
defineCustomElements(window);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();