import { IonButton, IonInput, IonItem, IonLabel, IonList, IonLoading } from "@ionic/react";
import React, { createRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { RootState } from "typesafe-actions";
import { Modal } from "../../components";
import UserSettingsForm from "../../components/UserSettingsForm";
import { SaveUserForm } from "../../components/UserSettingsForm/UserSettingsForm";
import { ModalAction } from "../../models";
import { showNotification } from "../Notification/redux/actions";
import { getUser, updateUser, updateUserPassword } from "./redux/actions";
export interface SaveAction {
  onSave: () => void;
}
const SettingsFeature = forwardRef((props, ref) => {
  useImperativeHandle(ref, (): SaveAction => ({
    onSave() {
      if (userRef.current) {
        dispatch(updateUser.request({
          token: auth.token!,
          newUserSettings: userRef.current.onSave().newUser
        }));
      }
    }
  }));
  const userSettings = useSelector((state: RootState) => state.settingsReducer.userSettings, shallowEqual);
  const loading = useSelector((state: RootState) => state.settingsReducer.loading);
  const auth = useSelector((state: RootState) => state.authReducer);
  const [ChangePassword, setChangePassword] = useState(false);
  const dispatch = useDispatch();
  const {
    t
  } = useTranslation();
  useEffect(() => {
    dispatch(getUser.request({
      token: auth.token!,
      id_user: auth.userID!
    }));
    return () => {};
  }, [auth.token, auth.userID, dispatch]);
  const userRef = createRef<SaveUserForm>();
  const passwordForm = useRef<HTMLFormElement>(null);
  const changePassword: ModalAction = {
    name: t("ChangePassword"),
    fn: () => {
      const pw_1 = (passwordForm.current!["password_1"].value as string);
      const pw_2 = (passwordForm.current!["password_2"].value as string);
      if (pw_1.trim().length > 0 && pw_2.trim().length > 0 && pw_1 === pw_2) {
        dispatch(updateUserPassword.request({
          email: auth.email!,
          token: auth.token!,
          newPassword: pw_1
        }));
        setChangePassword(false);
        dispatch(showNotification({
          color: "success",
          message: t("PasswordSavedSuccessfully"),
          type: "Toast"
        }));
      } else {
        dispatch(showNotification({
          color: "warning",
          message: t("PasswordError"),
          type: "Toast"
        }));
      }
    },
    type: "button"
  };
  const renderChangePassword = () => {
    return <Modal actions={[changePassword]} isOpen={ChangePassword} onClose={() => setChangePassword(false)} title={t("ChangePassword")}>
        <form ref={passwordForm}>
          <IonList>
            <IonItem>
              <IonLabel position={"floating"}>{t("NewPassword")}</IonLabel>
              <IonInput name={"password_1"} type="password" placeholder={t("NewPassword")} />
            </IonItem>
            <IonItem>
              <IonLabel position={"floating"}>
                {t("ConfirmNewPassword")}
              </IonLabel>
              <IonInput name={"password_2"} type="password" placeholder={t("ConfirmNewPassword")} />
            </IonItem>
          </IonList>
        </form>
      </Modal>;
  };
  return <>
      {loading ? <IonLoading isOpen={loading} /> : <IonList>
          <UserSettingsForm ref={userRef} name={userSettings.name} lastname={userSettings.lastname} company={userSettings.company ? userSettings.company : ""} address1={userSettings.address1 ? userSettings.address1 : ""} city={userSettings.city ? userSettings.city : ""} postcode={userSettings.postcode ? userSettings.postcode : ""} id_country={Number(userSettings.id_country)} phone={userSettings.phone ? Number(userSettings.phone) : 0} />
        </IonList>}
      <IonButton style={{
      margin: "1rem"
    }} expand={"block"} onClick={() => setChangePassword(true)}>
        {t("ChangePassword")}
      </IonButton>
      {renderChangePassword()}
    </>;
});
export default SettingsFeature;