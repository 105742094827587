import React, { useState } from "react";
import { Modal } from "..";
import { IonSearchbar, IonList, IonRadioGroup, IonItem, IonLabel, IonButton } from "@ionic/react";
import { ModalProps } from "../Modal/Modal";
import { Products_API } from "../../helpers/api";
import { ChoosenProduct } from "../OfferForm/offerCalculation";
export interface Props {
  modal: Omit<ModalProps, "children">;
  choosenProduct: ChoosenProduct | null;
  onSetChoosenProduct: (choosenProduct: ChoosenProduct) => void;
  allProducts: Products_API | null;
}
const ModalSelectProduct: React.FC<Props> = props => {
  const {
    isOpen,
    onClose,
    title
  } = props.modal;
  const {
    choosenProduct,
    onSetChoosenProduct,
    allProducts
  } = props;
  const [SearchText, setSearchText] = useState("");
  const [expandedCategories, setExpandedCategories] = useState<string[]>([]);
  const productLines = ["LUX+", "Premium", "Lux", "Basic+", "Basic"];
  const spareParts = ["vnr.", "Somfy"];
  const gardinskinne = "Gardinskinne";
  const faber = "Faber";
  const velux = "Velux";
  const categorizeProducts = products => {
    const categories = {
      productLines: [],
      gardinskinne: [],
      spareParts: [],
      others: [],
      velux: [],
      faber: []
    };
    products.forEach(product => {
      const name = product.name.toLowerCase();
      if (name.includes(velux.toLowerCase())) {
        categories.velux.push(product); // Strictly categorize as Velux
      } else if (name.includes(faber.toLowerCase())) {
        categories.faber.push(product); // Strictly categorize as Faber
      } else if (name.includes(gardinskinne.toLowerCase())) {
        categories.gardinskinne.push(product); // Strictly categorize as Gardinskinne
      } else if (spareParts.some(part => name.includes(part.toLowerCase()))) {
        categories.spareParts.push(product); // Spare parts
      } else if (productLines.some(line => name.includes(line.toLowerCase()))) {
        categories.productLines.push(product); // Product lines
      } else {
        categories.others.push(product); // Default to Others
      }
    });
    return categories;
  };
  const toggleCategory = (category: string) => {
    setExpandedCategories(prev => prev.includes(category) ? prev.filter(cat => cat !== category) : [...prev, category]);
  };
  const allCategories = ["productLines", "gardinskinne", "spareParts", "others", "velux", "faber"];
  const filteredProducts = allProducts ? categorizeProducts(allProducts.products.filter(p => {
    const searchText = SearchText.trim().toLowerCase();
    return searchText ? p.name.toLowerCase().includes(searchText) : true; // Show all if search is empty
  })) : null;

  // Expand all categories if search is cleared
  React.useEffect(() => {
    if (!SearchText.trim()) {
      setExpandedCategories(allCategories);
    }
  }, [SearchText]);
  return <>
      <Modal isOpen={isOpen} onClose={onClose} title={title} {...props.modal}>
        <IonSearchbar value={SearchText} onIonChange={e => setSearchText(e.detail.value!)} />
        <IonList>
          <IonRadioGroup value={choosenProduct} onIonChange={e => {
          onSetChoosenProduct(e.detail.value);
        }}>
            {filteredProducts && <>
                {allCategories.map(category => <React.Fragment key={category}>
                    {filteredProducts[category] && filteredProducts[category].length > 0 && <>
                          <IonItem style={{
                  cursor: "pointer"
                }} onClick={() => toggleCategory(category)}>
                            <IonLabel>
                              <strong>
                                {category.replace(/([A-Z])/g, " $1") // Format camelCase to words
                      .charAt(0).toUpperCase() + category.replace(/([A-Z])/g, " $1").slice(1)}{" "}
                                ({filteredProducts[category].length})
                              </strong>
                            </IonLabel>
                            <IonButton fill={expandedCategories.includes(category) ? "outline" : "solid"}>
                              {expandedCategories.includes(category) ? "Hide" : "Show"}
                            </IonButton>
                          </IonItem>
                          {expandedCategories.includes(category) && filteredProducts[category].map((product, i) => <IonItem key={i} button onClick={() => onSetChoosenProduct({
                  id: Number(product.id_product),
                  name: product.name,
                  inputs: product.inputs,
                  price: product.price,
                  tax: product.tax,
                  mounting_price: product.mounting_price,
                  limits: product.limits,
                  product_type: product.product_type
                })}>
                                <IonLabel>{product.name}</IonLabel>
                              </IonItem>)}
                        </>}
                  </React.Fragment>)}
              </>}
          </IonRadioGroup>
        </IonList>
      </Modal>
    </>;
};
export default ModalSelectProduct;