import React from "react";
import "./UserDetail.css";
import { Customer } from "../../models/models";
import { IonRow, IonCol, IonLabel, IonItem, IonInput, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonText } from "@ionic/react";
import { startCase } from "lodash";
import { useTranslation } from "react-i18next";
interface IProps {
  user: Customer | null;
  colSize?: number;
}
type Props = React.FC<IProps>;
const UserDetail: Props = ({
  user,
  colSize
}) => {
  const {
    t
  } = useTranslation();
  const renderInputs = (colSize: number = 12) => {
    return user ? Object.keys(user!).map((key, i) => {
      if (key === "customer_id") {
        return null;
      }
      return <IonCol key={i} size={colSize.toString()}>
            <IonItem>
              <IonLabel position="stacked">{startCase(key)}</IonLabel>
              <IonInput readonly={true} value={Object.values(user!)[i] ? Object.values(user!)[i] : undefined} />
            </IonItem>
          </IonCol>;
    }) : <span>{t("Nothing")}</span>;
  };
  return <>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle className={"userDetails-header"}>
            <IonText>
              {t("Customer")}
              <IonText>
                <small>
                  {" "}
                  {t("ID")} {user?.customer_id}
                </small>{" "}
              </IonText>
            </IonText>
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent style={{
        overflowY: "auto",
        padding: "0"
      }}>
          <IonRow style={{
          margin: 0
        }}>{renderInputs(colSize)}</IonRow>
        </IonCardContent>
      </IonCard>
    </>;
};
export default UserDetail;