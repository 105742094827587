import { IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonLabel, IonText } from "@ionic/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { ShippingDetails } from "../../models";
import "./Delivery.css";
interface IProps {
  delivery?: ShippingDetails[];
}
type Props = React.FC<IProps>;

// Section Delivery
// Title: Shipping Information
// Data for each: Manufacturer, Shipping Date, Expected Delivery date, carrier, tracking number
// Actions: Follow external link to carrier website.

//TODO add delivery address

const Delivery: Props = props => {
  const {
    t
  } = useTranslation();
  return <>
      {props.delivery && props.delivery.length > 0 ? <IonCard>
          <IonCardHeader>
            <IonCardTitle style={{
          paddingBottom: ".7rem",
          paddingTop: ".5rem"
        }}>
              {t("DeliveryStatus")}
            </IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            {props.delivery.map(delivery => {
          return <>
                  <hr />
                  {t("Shipping Date")} <b>{delivery.shipping_date}</b> <br />
                  {t("Expected Delivery")}{" "}
                  <b>{delivery.expected_delivery_date}</b>
                  <IonLabel className="ion-text-wrap">
                    <IonText>
                      {delivery._carriers_.length > 0 && delivery._carriers_.map(carrier => {
                  return <>
                              <p>
                                {t("Carrier")} <b>{carrier.name}</b>
                              </p>
                              <p>
                                {t("TrackingNumber")}
                                <b>
                                  {carrier.tracking_number.toString().trim().length > 0 ? carrier.tracking_number : t("NotAvailable")}
                                </b>
                              </p>
                            </>;
                })}
                      <p>
                        {t("Manufacturer")} <b>{delivery.manufacturer_name}</b>
                      </p>
                    </IonText>
                  </IonLabel>
                </>;
        })}
          </IonCardContent>
        </IonCard> : <IonCard>
          <IonCardHeader>
            <IonCardTitle style={{
          paddingBottom: ".7rem",
          paddingTop: ".5rem"
        }}>
              {t("DeliveryStatus")}
            </IonCardTitle>
            <IonCardSubtitle>{t("NotShipped")}</IonCardSubtitle>
          </IonCardHeader>
        </IonCard>}
    </>;
};
export default Delivery;