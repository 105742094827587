import React from "react";
import { IonInput, IonLabel, IonList, IonItem } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { PreFilledOffer } from "../../models";
import { calculateTotalProductsPrice, calculateShippingPrice, calculateMeassuringPrice, calculateMountingPrice } from "../../features/OfferDetail/totalsCalculation";
import { useSelector } from "react-redux";
import { RootState } from "typesafe-actions";
interface Props {
  products: PreFilledOffer[];
  extraMountingPrice: number;
}
const Totals: React.FC<Props> = props => {
  const {
    t
  } = useTranslation();
  const {
    products,
    extraMountingPrice
  } = props;
  const configs = useSelector((state: RootState) => state.authReducer.appConfigs);
  const totalProductsPrice = calculateTotalProductsPrice(configs, products);
  const measuringPrice = calculateMeassuringPrice(configs?.global_config, totalProductsPrice);
  const mountingPrice = calculateMountingPrice(configs?.attributes, configs?.global_config, configs?.products?.products!, products);
  const shippingPrice = calculateShippingPrice(configs?.global_config, totalProductsPrice, products);
  return <>
      <IonList>
        <IonItem>
          <IonLabel position={"floating"}>
            {t("Total products price")} ({t("Currency")})
          </IonLabel>
          <IonInput readonly value={isNaN(totalProductsPrice) ? (t("ProductError") as string) : totalProductsPrice} />
        </IonItem>
        <IonItem>
          <IonLabel position={"floating"}>
            {t("Shipping")} ({t("Currency")})
          </IonLabel>
          <IonInput readonly value={shippingPrice} />
        </IonItem>
        <IonItem>
          <IonLabel position={"floating"}>
            {t("Measuring")} ({t("Currency")})
          </IonLabel>
          <IonInput readonly value={measuringPrice} />
        </IonItem>
        <IonItem>
          <IonLabel position={"floating"}>
            {t("Mounting")} ({t("Currency")}){" "}
            {extraMountingPrice > 0 && `${t("Extra")} ${extraMountingPrice}`}
          </IonLabel>
          <IonInput readonly value={mountingPrice ? mountingPrice + extraMountingPrice < 0 ? 0 : mountingPrice + extraMountingPrice : 0} />
        </IonItem>
      </IonList>
    </>;
};
export default Totals;