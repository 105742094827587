import { createAction, createAsyncAction } from "typesafe-actions";
import { PreFilledOffer } from "../../../models";
import { OfferDetailState } from "./reducer";

export interface CreateOffer {
  cart_extra_mounting_price: number;
  cart_extra_discount: number;
  estimated_mounting_hours: number;
  estimated_mounting_minutes: number;
  general_comment: string;
  product: string[];
  id_product: number[];
  quantity: number[];
  width?: number[];
  height?: number[];
  width2?: number[];
  height2?: number[];
  width_a?: number[];
  width_b?: number[];
  width_c?: number[];
  attributes?: { [index: number]: number }[];
  comment?: string[];
  manual?: Object[]; // TBD
  submitOffer?: string;
  saveOffer?: string;
  delivery_to_fitter: boolean;
  readyToSend: { [index: number]: boolean }[];
}
export interface DraftOffer extends CreateOffer {
  id: number;
  readyToSend: { [index: number]: boolean }[];
}
export const getOffers = createAsyncAction(
  "offer_details/OFFERS_REQUEST",
  "offer_details/OFFERS_SUCCESS",
  "offer_details/OFFERS_FAILURE"
)<
  { id_order: number; id_offer: number; token: string },
  {
    offers: PreFilledOffer[];
    mountingTime: string;
    discount: number;
    extraMounting: number;
    generalComment?: string;
  },
  Error | string
>();

export const createOffer = createAsyncAction(
  "offer_details/CREATE_OFFER_REQUEST",
  "offer_details/CREATE_OFFER_SUCCESS",
  "offer_details/CREATE_OFFER_FAILURE"
)<
  { token: string; id_order: number; offer: CreateOffer },
  { id_order: number; id_offer: number; offer: CreateOffer },
  Error | string
>();

export const updateOffer = createAsyncAction(
  "offer_details/UPDATE_OFFER_REQUEST",
  "offer_details/UPDATE_OFFER_SUCCESS",
  "offer_details/UPDATE_OFFER_FAILURE"
)<
  { token: string; id_order: number; id_offer: number; offer: CreateOffer },
  { id_order: number; offer: CreateOffer },
  Error | string
>();

export const draftOffer = createAction("offer_details/SAVE_DRAFT")<{
  id_order: number;
  offer: CreateOffer;
}>();

export const updateProduct = createAction("offer_details/UPDATE_PRODUCT")<{
  index: number;
  product: PreFilledOffer;
}>();

export const addNewEmptyProduct = createAsyncAction(
  "offer_details/ADD_NEW_EMPTY_PRODUCT_REQUEST",
  "offer_details/ADD_NEW_EMPTY_PRODUCT_SUCCESS",
  "offer_details/ADD_NEW_EMPTY_PRODUCT_FAILURE"
)<
  {
    mountingTime: string;
    discount: number;
    extraMounting: number;
    generalComment?: string;
  },
  {
    mountingTime: string;
    discount: number;
    extraMounting: number;
    generalComment?: string;
  },
  Error | string
>();

// export const addNewEmptyProduct = createAction(
//   "offer_details/ADD_EMPTY_PRODUCT"
// )<{
//   mountingTime: string;
//   discount: number;
//   extraMounting: number;
//   generalComment?: string;
// }>();
export const addNewProduct = createAction("offer_details/ADD__PRODUCT")<{
  offer: PreFilledOffer;
}>();
export const copyProduct = createAction("offer_details/COPY_PRODUCT")<{
  offer: PreFilledOffer;
}>();

export const deleteProduct = createAction("offer_details/DELETE_PRODUCT")<{
  index: number;
}>();

export const cleanProducts = createAction(
  "offer_details/CLEAN_PRODUCTS"
)<void>();

export const expandProduct = createAction("offer_details/EXPAND_PRODUCT")<{
  index: number;
}>();

export const newExpandProduct = createAction(
  "offer_details/EXPAND_PRODUCT_NEW"
)<{
  index: number;
  // offer: PreFilledOffer;
}>();

export const collapseProduct = createAction(
  "offer_details/COLLAPSE_PRODUCT"
)<void>();

export const init = createAction("offer_details/INIT")<void>();

export const getDraft = createAsyncAction(
  "offer_details/DRAFT_REQUEST",
  "offer_details/DRAFT_SUCCESS",
  "offer_details/DRAFT_FAILURE"
)<number, OfferDetailState, Error | string>();

export const updateComment = createAction(
  "offer_details/UPDATE_COMMENT"
)<string>();
