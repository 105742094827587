import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { IonInput, IonItem, IonLabel, IonList, IonSelect, IonSelectOption, IonCol, IonRow } from "@ionic/react";
import { useTranslation } from "react-i18next";
export interface CustomerForm {
  name: string;
  surname: string;
  email: string;
  phone: number;
  company: string;
  cvr_ean: number | string;
  street: string;
  postcode: string;
  city: string;
  country: number;
}
export interface SaveCustomerForm {
  onSave: () => {
    newCustomer: CustomerForm;
  };
}
const AddCustomerForm = forwardRef((props: {}, ref) => {
  const {
    t
  } = useTranslation();
  const newCustomerForm = useRef<HTMLFormElement>(null);
  useImperativeHandle(ref, (): SaveCustomerForm => ({
    onSave() {
      // Form data
      const name = newCustomerForm.current!["_name"].value;
      const surname = newCustomerForm.current!["surname"].value;
      const email = newCustomerForm.current!["email"].value;
      const phone = newCustomerForm.current!["phone"].value;
      const company = newCustomerForm.current!["company"].value;
      const cvr_ean = newCustomerForm.current!["cvr_ean"].value;
      const street = newCustomerForm.current!["street"].value;
      const postcode = newCustomerForm.current!["postcode"].value;
      const city = newCustomerForm.current!["city"].value;
      const country = newCustomerForm.current!["country"].value;
      return {
        newCustomer: {
          name,
          surname,
          email,
          phone,
          company,
          cvr_ean,
          street,
          postcode,
          city,
          country
        }
      };
    }
  }));
  return <>
      <form ref={newCustomerForm}>
        <IonList>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel position={"floating"}>{t("Name")}</IonLabel>
                <IonInput name={"_name"} type="text" placeholder={t("PlaceholderForCustomerName")} />
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem>
                <IonLabel position={"floating"}>{t("Surname")}</IonLabel>
                <IonInput name={"surname"} type="text" placeholder={t("PlaceholderForCustomerSurname")} />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel position={"floating"}>{t("Email")}</IonLabel>
                <IonInput name={"email"} type="text" placeholder={t("PlaceholderForCustomerEmail")} />
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem>
                <IonLabel position={"floating"}>{t("Phone")}</IonLabel>
                <IonInput name={"phone"} type="number" placeholder={t("PlaceholderForCustomerPhone")} />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel position={"floating"}>{t("Company")}</IonLabel>
                <IonInput name={"company"} type="text" placeholder={t("PlaceholderForCustomerCompany")} />
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem>
                <IonLabel position={"floating"}>{t("Cvr/Ean")}</IonLabel>
                <IonInput name={"cvr_ean"} type="number" placeholder={t("PlaceholderForCustomerCVR/EAN")} />
              </IonItem>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol size="8">
              <IonItem>
                <IonLabel position={"floating"}>{t("Street")}</IonLabel>
                <IonInput name={"street"} type="text" placeholder={t("PlaceholderForCustomerStreet")} />
              </IonItem>
            </IonCol>
            <IonCol size="4">
              <IonItem>
                <IonLabel position={"floating"}>{t("Postcode")}</IonLabel>
                <IonInput name={"postcode"} type="text" placeholder={t("PlaceholderForCustomerPostcode")} />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel position={"floating"}>{t("City")}</IonLabel>
                <IonInput name={"city"} type="text" placeholder={t("PlaceholderForCustomerCity")} />
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem>
                <IonLabel position={"floating"}>{t("Country")}</IonLabel>
                <IonSelect name={"country"}
              // placeholder={t("PlaceholderForCustomerCountry")}
              >
                  <IonSelectOption value="20">{t("Denmark")}</IonSelectOption>
                  <IonSelectOption value="89">
                    {t("FaroeIslands")}
                  </IonSelectOption>
                  <IonSelectOption value="1">{t("Germany")}</IonSelectOption>
                  <IonSelectOption value="96">{t("Greenland")}</IonSelectOption>
                  <IonSelectOption value="23">{t("Norway")}</IonSelectOption>
                  <IonSelectOption value="18">{t("Sweden")}</IonSelectOption>
                </IonSelect>
              </IonItem>
            </IonCol>
          </IonRow>
        </IonList>
      </form>
    </>;
});
export default AddCustomerForm;