import React, { useState } from "react";
import "./Modal.css";
import { IonModal, IonRow, IonLabel, IonButton, IonIcon, IonSegment, IonSegmentButton, ModalOptions } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { ModalAction } from "../../models";
export interface ModalProps extends Omit<ModalOptions, "component"> {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  tabs?: string[];
  getSelectedTab?: (selectedTab: string) => void;
  actions?: ModalAction[];
  hidTitle?: boolean;
  children?: React.ReactNode;
}
const Modal = (props: ModalProps) => {
  const [selectedTab, setTab] = useState<string>();
  const {
    title,
    tabs,
    actions,
    children,
    getSelectedTab,
    onClose,
    hidTitle
  } = props;
  const handleSelectTab = (tab: string) => {
    setTab(tab);
    getSelectedTab && getSelectedTab(tab);
  };
  const renderActions = (actions: ModalAction[]) => {
    const iconActions = actions.filter(a => a.type === "icon");
    const buttonActions = actions.filter(a => a.type === "button");
    return <>
        <div className="icons">
          {iconActions.map(action => {
          return <IonIcon className={action.position} size="medium" icon={action.icon} onClick={action.fn}>
                {action.name}
              </IonIcon>;
        })}
        </div>
        <div className="buttons">
          {buttonActions.map(action => {
          return <IonButton disabled={action.loading || action.disabled} key={action.name} fill={action.buttonStyle ? action.buttonStyle : "solid"} className={`button ${action.position}`} onClick={action.fn}>
                {action.icon && <IonIcon icon={action.icon} />}
                {action.name}
              </IonButton>;
        })}
        </div>
      </>;
  };
  return <>
      <IonModal onDidDismiss={onClose} {...props}>
        {!hidTitle && <IonRow className={"modal-header"}>
            <h1>{title}</h1>
            <IonIcon onClick={onClose} size="large" icon={closeOutline} />
          </IonRow>}
        {tabs && <IonRow className={"modal-tabs"}>
            <IonSegment onIonChange={e => handleSelectTab((e.detail.value! as string))} value={selectedTab ? selectedTab : tabs[0]}>
              {tabs.map(tab => <IonSegmentButton key={tab} value={tab}>
                  <IonLabel>{tab}</IonLabel>
                </IonSegmentButton>)}
            </IonSegment>
          </IonRow>}
        {children && <div className={"modal-content"}>{children}</div>}
        {actions && <IonRow className={"modal-actions"}>{renderActions(actions)}</IonRow>}
      </IonModal>
    </>;
};
export default Modal;