import { ActionType, getType, Reducer } from "typesafe-actions";
import * as login from "./actions";
import { Roles } from "../../../models/";
import {
  Attributes_API,
  Configuration_API,
  DNA_API,
  PriceList_API,
  Products_API,
} from "../../../helpers/api";
import { Storage } from "@capacitor/storage";

export type LoginAction = ActionType<typeof login>;

export type AppConfigs = {
  dna?: DNA_API;
  global_config?: Configuration_API;
  products?: Products_API;
  pricelist?: PriceList_API;
  attributes?: Attributes_API;
};
interface AuthState {
  isAuth: boolean;
  userID: number | null;
  error?: string;
  email?: string;
  name?: string;
  role: Roles | null;
  loadingRequests: {
    dna: boolean;
    global_config: boolean;
    products: boolean;
    pricelist: boolean;
    attributes: boolean;
  };
  token: string | null;
  workingOnline: boolean;
  appConfigs: AppConfigs | undefined;
}

const initialState: AuthState = {
  isAuth: false,
  loadingRequests: {
    dna: true,
    global_config: true,
    products: true,
    pricelist: true,
    attributes: true,
  },
  userID: null,
  role: null,
  token: null,
  workingOnline: true,
  error: undefined,
  appConfigs: undefined,
};
// const pouchDb = initConfigsDB();

const removeStorageByKey = async (key: string) => {
  await Storage.remove({ key });
};

export const authReducer: Reducer<AuthState, LoginAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getType(login.localConfigs):
      return {
        ...state,
        appConfigs: action.payload,
      };
    case getType(login.getDNAAction.success):
      return {
        ...state,
        loadingRequests: {
          ...state.loadingRequests,
          dna: false,
        },
        appConfigs: {
          ...state.appConfigs,
          dna: action.payload,
        },
      };
    case getType(login.getAttributesAction.success):
      return {
        ...state,
        loadingRequests: {
          ...state.loadingRequests,
          attributes: false,
        },
        appConfigs: {
          ...state.appConfigs,
          attributes: action.payload,
        },
      };
    case getType(login.getConfigAction.success):
      return {
        ...state,
        loadingRequests: {
          ...state.loadingRequests,
          global_config: false,
        },
        appConfigs: {
          ...state.appConfigs,
          global_config: action.payload,
        },
      };
    case getType(login.getPriceListAction.success):
      return {
        ...state,
        loadingRequests: {
          ...state.loadingRequests,
          pricelist: false,
        },
        appConfigs: {
          ...state.appConfigs,
          pricelist: action.payload,
        },
      };
    case getType(login.getProductsAction.success):
      return {
        ...state,
        loadingRequests: {
          ...state.loadingRequests,
          products: false,
        },
        appConfigs: {
          ...state.appConfigs,
          products: action.payload,
        },
      };
    case getType(login.toggleWorkingOnline):
      return {
        ...state,
        workingOnline: action.payload,
      };
    case getType(login.loginAction.success):
      const { name, role, userID, token, email } = action.payload;
      // storageSet<AuthCookie>("auth", {
      //   auth: {
      //     userID: action.payload.userID,
      //     name: action.payload.name,
      //     role: action.payload.role,
      //     valid: true,
      //     email: action.payload.email,
      //     token: action.payload.token,
      //     last_login: new Date(Date.now()),
      //   },
      // });

      return {
        ...state,
        isAuth: true,
        userID,
        name,
        role,
        email,
        token,
      };
    case getType(login.logoutAction.request):
      // clearCookie();

      // deleteDBS();
      removeStorageByKey("timeStamp");
      return {
        ...state,
        isAuth: false,
        userID: -1,
        name: "",
        role: null,
        googleSync: undefined,
        token: null,
        email: "",
      };
    case getType(login.loginAction.failure):
      return {
        ...state,
        error: action.payload.toString(),
      };
    default:
      return state;
  }
};
