import { ActionType, getType, Reducer } from "typesafe-actions";
import * as leads from "./actions";
import * as details from "../../Details/redux/actions";
import {
  Order,
  GBArray,
  NewLeads,
  OfferPending,
  OfferSent,
  PaymentWaiting,
  MountingPending,
  GB,
} from "../../../models/";
import {
  storageGet,
  toDeliveryPending,
  toFinished,
  toMeassuring,
  toMountingPending,
  toNewLeads,
  toOfferPending,
  toOfferSent,
  toPaymentWaiting,
} from "../../../helpers";
import { cloneDeep } from "lodash";
import { API_LEADS_PREVIEW } from "../../../models/apiModels";
import { DetailsAction } from "../../Details/redux/reducer";
import moment from "moment";
import { Mounter_API } from "../../../helpers/api";

export type LeadsAction = ActionType<typeof leads>;

interface LeadsState {
  leads: API_LEADS_PREVIEW[];
  newLeads: NewLeads[];
  measuringLeads: any[];
  offerPendingLeads: OfferPending[];
  offerSentLeads: OfferSent[];
  paymentWaitingLeads: PaymentWaiting[];
  mountingPendingLeads: MountingPending[];
  deliveryPendingLeads: any[];
  finishedLeads: any[];
  error?: string;
  isLoading?: boolean;
  customerAdded?: boolean;
  searchedString?: string;
  gbs?: GBArray;
  mounters?: Mounter_API[]; // maybe change name?
  filteredNames: string[];
  openTabs: string[];
  columnFilters: {
    rowName: string;
    filters: string[];
  }[];
}

const initialState: LeadsState = {
  leads: [],
  newLeads: [],
  measuringLeads: [],
  offerPendingLeads: [],
  offerSentLeads: [],
  paymentWaitingLeads: [],
  mountingPendingLeads: [],
  deliveryPendingLeads: [],
  filteredNames: [],
  openTabs: [],
  columnFilters: [],
  finishedLeads: [],
};

export const leadsReducer: Reducer<LeadsState, LeadsAction | DetailsAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getType(leads.updateAssignedGB.failure):
      return {
        ...state,
        error: action.payload.message,
        isLoading: false,
      };
    case getType(leads.updateAssignedGB.success):
      const copy = state.leads;
      const index_gb = copy.findIndex(
        (o) => o.id_order === action.payload.id_order
      );
      if (index_gb !== -1) {
        copy[index_gb].fitter.id_fitter = action.payload.id_fitter;
        copy[index_gb].fitter.first_name = action.payload.first_name;
        copy[index_gb].fitter.last_name = action.payload.last_name;
      }
      if (
        copy.find((o) => o.id_order === action.payload.id_order)!
          .current_state_gardinbus === 2
      ) {
        return {
          ...state,
          leads: copy,
          measuringLeads: toMeassuring(copy),
          isLoading: false,
        };
      } else if (
        copy.find((o) => o.id_order === action.payload.id_order)!
          .current_state_gardinbus === 3
      ) {
        return {
          ...state,
          leads: copy,
          offerPendingLeads: toOfferPending(copy),
          isLoading: false,
        };
      }
      return {
        ...state,
        leads: copy,
        isLoading: false,
      };
    case getType(leads.updateAssignedGB.request):
      return {
        ...state,
        isLoading: true,
      };
    case getType(leads.openTabs):
      return {
        ...state,
        openTabs: action.payload,
      };
    case getType(leads.selectedFilters):
      return {
        ...state,
        columnFilters: action.payload,
      };
    case getType(leads.filterLead):
      const gbNames =
        action.payload &&
        Object.values(action.payload)
          .filter((gb: GB) => gb.isSelected)
          .map((gb: GB) => gb.name);
      return {
        ...state,
        filteredNames: gbNames,
      };
    case getType(leads.getMounters.success):
      return {
        ...state,
        mounters: action.payload,
      };
    case getType(leads.deleteLead):
      const newState = state.leads.filter((l) => l.id_order !== action.payload);
      return {
        ...state,
        leads: newState,
      };
    case getType(leads.addCustomer.success):
      const coypWithNewEmptyLead = cloneDeep(state.leads);
      const newEmptyLead: API_LEADS_PREVIEW = {
        background_color: "",
        address_delivery: {
          city: "",
          postcode: "",
          id_address: 0,
        },
        id_order: action.payload.order_id,
        id_order_w_country_code: 0,
        current_state: 0,
        current_state_name: "",
        current_state_gardinbus: 3,
        current_state_gardinbus_name: "",
        customer: action.payload.customer,
        fitter: action.payload.fitter,
        mounter: {
          id_mounter: 0,
          first_name: null,
          last_name: null,
        },
        date_offer_pending_since: null,
        date_offer_sent: null,
        date_payment_waiting_since: null,
        gardinbus_measuring_date: null,
        gardinbus_measuring_date_end: null,
        gardinbus_mounting_date: null,
        gardinbus_mounting_date_end: null,
        has_note: false,
        notes: [],
        date_add: new Date().toDateString(),
      };
      coypWithNewEmptyLead.push(newEmptyLead);
      return {
        ...state,
        offerPendingLeads: toOfferPending(coypWithNewEmptyLead),
      };
    case getType(details.addNewEvent.success):
      const copyRescheduled = state.leads;
      const rescheduledIndex = copyRescheduled.findIndex(
        (o) => o.id_order === Number(action.payload.event.orderID)
      );
      if (rescheduledIndex !== -1 && action.payload.event.start) {
        copyRescheduled[rescheduledIndex].gardinbus_measuring_date = moment(
          action.payload.event.start
        )
          .format("YYYY-MM-DD HH:mm:ss")
          .toString();
        copyRescheduled[rescheduledIndex].fitter = {
          id_fitter: action.payload.operatorId,
          first_name: action.payload.operatorName,
          last_name: "",
        };
      }
      return {
        ...state,
        leads: copyRescheduled,
        measuringLeads: toMeassuring(copyRescheduled),
        mountingPendingLeads: toMountingPending(copyRescheduled),
      };
    case getType(details.assingMounter.success):
      const copyWithAssignedMounter = state.leads;
      const index = copyWithAssignedMounter.findIndex(
        (o) => o.id_order === action.payload.id_order
      );
      if (index !== -1) {
        copyWithAssignedMounter[index].gardinbus_mounting_date =
          action.payload.mounting_date;
      }
      return {
        ...state,
        leads: copyWithAssignedMounter,
      };
    case getType(leads.getAPILeads.request):
    case getType(leads.getAPILeadsByTimestamp.request):
      return {
        ...state,
        isLoading: true,
      };
    case getType(leads.getAPILeads.success):
      const row2s = action.payload as any;
      const _leads = row2s.orders ? Object.values(row2s.orders) : [];
      return {
        ...state,
        leads: row2s.orders ? Object.values(row2s.orders) : [],
        mountingPendingLeads: toMountingPending(_leads),
        paymentWaitingLeads: toPaymentWaiting(_leads),
        offerPendingLeads: toOfferPending(_leads),
        offerSentLeads: toOfferSent(_leads),
        measuringLeads: toMeassuring(_leads),
        newLeads: toNewLeads(_leads),
        deliveryPendingLeads: toDeliveryPending(_leads),
        finishedLeads: toFinished(_leads),
        isLoading: false,
      };
    case getType(leads.getAPILeadsByTimestamp.success):
      const _rows = action.payload as any;
      const _newLeads =
        _rows.orders && Object.values(_rows.orders).length > 0
          ? Object.values(_rows.orders)
          : [];
      const stateLeads = state.leads;
      const copyOfStateLeads = cloneDeep(state.leads);
      stateLeads.forEach((lead, index) => {
        _newLeads.forEach((newLead: any) => {
          if (lead.id_order === newLead.id_order) {
            copyOfStateLeads[index] = newLead;
          }
        });
      });
      return {
        ...state,
        leads: copyOfStateLeads,
        mountingPendingLeads: toMountingPending(copyOfStateLeads),
        paymentWaitingLeads: toPaymentWaiting(copyOfStateLeads),
        offerPendingLeads: toOfferPending(copyOfStateLeads),
        offerSentLeads: toOfferSent(copyOfStateLeads),
        measuringLeads: toMeassuring(copyOfStateLeads),
        newLeads: toNewLeads(copyOfStateLeads),
        isLoading: false,
      };
    case getType(leads.addNewCustomer.request):
      return {
        ...state,
        isLoading: true,
      };
    case getType(leads.addNewCustomer.success):
      return {
        ...state,
        customerAdded: true,
        isLoading: false,
      };
    case getType(leads.filterLeads):
      return {
        ...state,
        searchedString: action.payload,
      };
    case getType(leads.getAPILeads.failure) ||
      getType(leads.getStorageLeads.failure) ||
      getType(leads.addNewCustomer.failure):
      return {
        ...state,
        error: action.payload.message,
        isLoading: false,
      };
    case getType(leads.assignLead.request):
      const newOrders = { ...state.leads };
      const storageOrders = storageGet<Order[]>("orders");
      storageOrders.then((o) => {
        const assignedGBID = o!.find(
          (order) => order.order_id === action.payload.orderID
        )!.assigned_gb_id!;
        newOrders.find(
          (o) => o.id_order === action.payload.orderID
        )!.fitter.id_fitter = assignedGBID;
      });
      return {
        ...state,
        leads: newOrders,
      };
    case getType(leads.sortLead):
      const sortedLeads = cloneDeep(state.leads);
      sortedLeads.sort((a, b) => a.fitter.id_fitter - b.fitter.id_fitter);
      return {
        ...state,
        measuringLeads: toMeassuring(sortedLeads),
        mountingPendingLeads: toMountingPending(sortedLeads),
        offerPendingLeads: toOfferPending(sortedLeads),
        offerSentLeads: toOfferSent(sortedLeads),
        newLeads: toNewLeads(sortedLeads),
        paymentWaitingLeads: toPaymentWaiting(sortedLeads),
      };
    default:
      return state;
  }
};
