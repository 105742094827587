import { BasicOrder } from "../../models";

export const filterArray = <T extends BasicOrder[]>(
  array: T,
  searchText: string
) => {
  const filteredArray = searchText
    ? (array.filter(
        (order) =>
          order.OrderID.toString().includes(searchText) ||
          order.CustomerName.toString()
            .toLowerCase()
            .includes(searchText.toLowerCase()) ||
          order.CustomerPhone.toString().includes(searchText)
      ) as T)
    : array;
  return filteredArray;
};
