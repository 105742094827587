import React, { useEffect, useState } from "react";
import { IonContent, IonGrid, IonRow, IonCol, IonImg, IonPage, IonModal, IonHeader, IonToolbar, IonButtons, IonButton, IonTitle } from "@ionic/react";
import { S3Client, ListObjectsV2Command, DeleteObjectCommand } from "@aws-sdk/client-s3";
import PhotoUpload from "./PhotoUpload";
import { Roles } from "../../models";
const accessKeyId = process.env.REACT_APP_ACCESS_KEY_ID!;
const secretAccessKey = process.env.REACT_APP_SECRET_ACCESS_KEY!;

// Configure S3 client
const s3Client = new S3Client({
  region: "eu-north-1",
  credentials: {
    accessKeyId,
    secretAccessKey
  }
});
interface PhotoGalleryProps {
  order_id: string;
  user_role: Roles;
}
const PhotoGallery: React.FC<PhotoGalleryProps> = ({
  order_id,
  user_role
}) => {
  const [photos, setPhotos] = useState<string[]>([]);
  const [selectedPhoto, setSelectedPhoto] = useState<string | null>(null);
  useEffect(() => {
    fetchPhotos();
  }, [order_id]);
  const fetchPhotos = async () => {
    try {
      const listParams = {
        Bucket: "gardinbus-app",
        Prefix: "mounting/" + order_id
      };
      const listCommand = new ListObjectsV2Command(listParams);
      const {
        Contents
      } = await s3Client.send(listCommand);
      if (Contents) {
        const photoUrls = Contents.map(item => `https://gardinbus-app.s3.eu-north-1.amazonaws.com/${item.Key}`);
        setPhotos(photoUrls);
      }
    } catch (error) {
      console.error("Error fetching photos:", error);
    }
  };
  const openPhoto = (photoUrl: string) => {
    setSelectedPhoto(photoUrl);
  };
  const closePhoto = () => {
    setSelectedPhoto(null);
  };
  const deletePhoto = async () => {
    if (!selectedPhoto) return;
    const key = selectedPhoto.split("https://gardinbus-app.s3.eu-north-1.amazonaws.com/")[1];
    try {
      const deleteParams = {
        Bucket: "gardinbus-app",
        Key: key
      };
      const deleteCommand = new DeleteObjectCommand(deleteParams);
      await s3Client.send(deleteCommand);
      setPhotos(photos.filter(photo => photo !== selectedPhoto));
      closePhoto();
    } catch (error) {
      console.error("Error deleting photo:", error);
    }
  };
  const handleUploadSuccess = () => {
    fetchPhotos();
  };
  if (photos.length === 0) return <IonGrid>
        <IonRow>No photos of mounting</IonRow>
      </IonGrid>;
  return <>
      <IonGrid>
        <IonRow>
          {photos.map((photo, index) => <IonCol style={{
          cursor: "pointer"
        }} size="3" key={index}>
              <IonImg src={photo} onClick={() => openPhoto(photo)} />
            </IonCol>)}
        </IonRow>
      </IonGrid>
      {(user_role === "Admin" || user_role === "Mounter") && <IonButton expand="block" onClick={() => setSelectedPhoto("upload")}>
          Upload Photos
        </IonButton>}
      <IonModal isOpen={!!selectedPhoto} onDidDismiss={closePhoto}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Photo</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={closePhoto}>Close</IonButton>
            </IonButtons>
            {(user_role === "Admin" || user_role === "Mounter") && selectedPhoto !== "upload" && <IonButtons slot="end">
                  <IonButton onClick={deletePhoto} color="danger">
                    Delete
                  </IonButton>
                </IonButtons>}
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          {selectedPhoto && selectedPhoto !== "upload" && <IonImg src={selectedPhoto} />}
          {selectedPhoto === "upload" && <PhotoUpload order_id={order_id} onUploadSuccess={handleUploadSuccess} />}
        </IonContent>
      </IonModal>
    </>;
};
export default PhotoGallery;