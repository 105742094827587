import { createAsyncAction, createAction } from "typesafe-actions";
import { Message } from "./types";

export const getMessages = createAsyncAction(
  "send_SMS/GET_MESSAGES_REQUEST",
  "send_SMS/GET_MESSAGES_SUCCESS",
  "send_SMS/GET_MESSAGES_FAILURE"
)<
  {
    token: string;
  },
  Message[],
  Error | string
>();

export const sendSMS = createAsyncAction(
  "send_SMS/SEND_SMS_REQUEST",
  "send_SMS/SEND_SMS_SUCCESS",
  "send_SMS/SEND_SMS_FAILURE"
)<
  {
    token: string;
    id_order: number;
    id_message: number;
  },
  boolean,
  Error | string
>();

export const clearSent = createAction("send_SMS/CLEAR_SENT")<void>();
