import { createAsyncAction, createAction } from "typesafe-actions";
import { Mounter_API } from "../../../helpers/api";
import { API_LEADS_PREVIEW } from "../../../models/apiModels";
import { Order, Customer, CustomerDTO, GBArray } from "../../../models/models";
import { User } from "../../Login/redux/actions";

export const getAPILeads = createAsyncAction(
  "orders/API_LEADS_REQUEST",
  "orders/API_LEADS_SUCCESS",
  "orders/API_LEADS_FAILURE",
  "orders/API_LEADS_CANCEL"
)<User, API_LEADS_PREVIEW[], Error, void>();

export const getAPILeadsByTimestamp = createAsyncAction(
  "orders/API_LEADS_TIMESTAMP_REQUEST",
  "orders/API_LEADS_TIMESTAMP_SUCCESS",
  "orders/API_LEADS_TIMESTAMP_FAILURE",
  "orders/API_LEADS_TIMESTAMP_CANCEL"
)<{ user: User; timeStamp: Date }, API_LEADS_PREVIEW[], Error, void>();

export const getStorageLeads = createAsyncAction(
  "orders/STORAGE_LEADS_REQUEST",
  "orders/STORAGE_LEADS_SUCCESS",
  "orders/STORAGE_LEADS_FAILURE",
  "orders/STORAGE_LEADS_CANCEL"
)<User, API_LEADS_PREVIEW[], Error, void>();

export const assignLead = createAsyncAction(
  "LEAD_ASSING_REQUEST",
  "LEAD_ASSING_SUCCESS",
  "LEAD_ASSING_FAILURE"
)<{ orderID: number }, Order[], Error>();

export const addNewCustomer = createAsyncAction(
  "ADD_NEW_CUSTOMER_REQUEST",
  "ADD_NEW_CUSTOMER_SUCCESS",
  "ADD_NEW_CUSTOMER_FAILURE"
)<Customer, boolean, Error>();

export const filterLeads = createAction(
  "FILTER_STRING",
  (text: string) => text
)<string>();

export const deleteLead = createAction(
  "leads/DELETE_LEAD",
  (id_order: number) => id_order
)<number>();

export const viewDetails = createAction(
  "VIEW_DETAILS",
  (id: number) => id
)<number>();

export const addNewOrderNote = createAsyncAction(
  "ADD_NEW_ORDER_NOTE_REQUEST",
  "ADD_NEW_ORDER_NOTE_SUCCESS",
  "ADD_NEW_ORDER_NOTE_FAILURE"
)<string, string[], Error>();

export const editNewOrderNote = createAsyncAction(
  "EDIT_ORDER_NOTE_REQUEST",
  "EDIT_ORDER_NOTE_SUCCESS",
  "EDIT_ORDER_NOTE_FAILURE"
)<string, string[], Error>();

export const deleteNewOrderNote = createAsyncAction(
  "DELETE_ORDER_NOTE_REQUEST",
  "DELETE_ORDER_NOTE_SUCCESS",
  "DELETE_ORDER_NOTE_FAILURE"
)<number, string[], Error>();

export const resendOffer = createAsyncAction(
  "leads/RESEND_OFFER_REQUEST",
  "leads/RESEND_OFFER_SUCCESS",
  "leads/RESEND_OFFER_FAILURE"
)<{ token: string; id_order: number }, boolean, Error>();

export const leadStatusUpdate =
  createAction("leads/LEAD_UPDATE")<{
    orderNumber: number;
  }>();

export const addCustomer = createAsyncAction(
  "leads/NEW_CUSTOMER_REQUEST",
  "leads/NEW_CUSTOMER_SUCCESS",
  "leads/NEW_CUSTOMER_FAILURE"
)<
  {
    token: string;
    customer: CustomerDTO;
    fitter: { id: number; name: string };
  },
  {
    order_id: number;
    customer: {
      id_customer: number;
      firstname: string;
      lastname: string;
      email: string;
      phone: string | null;
    };
    fitter: {
      id_fitter: number;
      first_name: string | null;
      last_name: string | null;
    };
  },
  Error | string
>();

export const getMounters = createAsyncAction(
  "leads/getMounters_REQUEST",
  "leads/getMounters_SUCCESS",
  "leads/getMounters_FAILURE"
)<{ token: string }, Mounter_API[], Error>();

export const sortLead =
  createAction("leads/SORT")<{
    key: string;
  }>();

export const filterLead = createAction("leads/FILTER")<GBArray>();
export const openTabs = createAction("leads/TABS")<string[]>();
export const selectedFilters = createAction("leads/COLUMN_FILTERS")<
  {
    rowName: string;
    filters: string[];
  }[]
>();

export const updateAssignedGB = createAsyncAction(
  "leads/UPDATE_ASSIGNED_GB_REQUEST",
  "leads/UPDATE_ASSIGNED_GB_SUCCESS",
  "leads/UPDATE_ASSIGNED_GB_FAILURE"
)<
  {
    token: string;
    id_order: number;
    id_fitter: number;
    first_name: string;
    last_name: string;
  },
  {
    token: string;
    id_order: number;
    id_fitter: number;
    first_name: string;
    last_name: string;
  },
  Error
>();
