import React from "react";
import { useSwipeable, SwipeableDirectionCallbacks } from "react-swipeable";
interface IProps {
  handlers: SwipeableDirectionCallbacks;
}
const SwipableContainer: React.FC<IProps> = props => {
  // Variables
  const {
    children,
    handlers
  } = props;
  // Ref
  const ref = React.useRef<HTMLDivElement | null>(null);
  const _handlers = useSwipeable(handlers);
  const refPassthrough = (el: HTMLDivElement) => {
    // call useSwipeable ref prop with el
    _handlers.ref(el);

    // set myRef el so you can access it yourself
    ref.current = el;
  };
  return <div {..._handlers} ref={refPassthrough}>
      {children}
    </div>;
};
export default SwipableContainer;