import { Epic } from "redux-observable";
import { from, of } from "rxjs";
import { filter, catchError, switchMap, map } from "rxjs/operators";
import { RootAction, RootState, isActionOf } from "typesafe-actions";
import { ApiGetLeads, apiVersionWrapper } from "../../../helpers/api";
import { API_LEADS_PREVIEW } from "../../../models/apiModels";
import { showNotification } from "../../Notification/redux/actions";
import { logTimeSpent, initMountings, finishMounting } from "./actions";

const finishMountingAPI = (
  token: string,
  order_id: number,
  mounting_time: string,
  order_note: string
) => {
  const API_URL = `${process.env.REACT_APP_API_URL}finishMounting/${order_id}`;
  return apiVersionWrapper(
    API_URL,
    "PUT",
    {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    JSON.stringify({
      order_note,
      mounting_time,
    })
  ).pipe(
    map((response) => {
      const allOk: boolean = response.response;
      return allOk;
    }),
    catchError((error: Error) => {
      return of(
        showNotification({
          color: "danger",
          message: error.message,
          type: "Toast",
        })
      );
    })
  );
};

export const finishMountingEpic: Epic<RootAction, RootAction, RootState> = (
  action$
) =>
  action$.pipe(
    filter(isActionOf(finishMounting.request)),
    switchMap((action) =>
      from(
        finishMountingAPI(
          action.payload.token,
          action.payload.order_id,
          action.payload.mounting_time,
          action.payload.order_note
        )
      ).pipe(
        switchMap((rsp) =>
          rsp
            ? of(
                showNotification({
                  color: "success",
                  message: "Finished successfully",
                  type: "Toast",
                })
              )
            : of(
                showNotification({
                  color: "danger",
                  message: "Error!",
                  type: "Toast",
                })
              )
        ),
        catchError((err) => of(finishMounting.failure(err)))
      )
    )
  );

export const timeSpentEpic: Epic<RootAction, RootAction, RootState> = (
  action$
) =>
  action$.pipe(
    filter(isActionOf(logTimeSpent.request)),
    switchMap((action) => {
      return of(logTimeSpent.success(action.payload));
    }),
    catchError((err) => of(logTimeSpent.failure(err)))
  );

export const initMountingsEpic: Epic<RootAction, RootAction, RootState> = (
  action$
) =>
  action$.pipe(
    filter(isActionOf(initMountings.request)),
    switchMap((action) => {
      return from(ApiGetLeads(action.payload)).pipe(
        switchMap((rsp) =>
          of(initMountings.success(rsp as API_LEADS_PREVIEW[]))
        ),
        catchError((err) => of(initMountings.failure(err)))
      );
    }),
    catchError((err) => of(initMountings.failure(err)))
  );
