import { Epic } from "redux-observable";
import { concat, EMPTY, forkJoin, from, Observable, of } from "rxjs";
import { filter, catchError, switchMap, map, mergeMap } from "rxjs/operators";
import { RootAction, RootState, isActionOf } from "typesafe-actions";
import {
  dismissNotification,
  DrivingEvent,
  getNotifications,
  Notification,
  getDrivingEvent,
  getMeetingsForTheDay,
} from "./actions";
import { showNotification } from "../../Notification/redux/actions";
import { isArray } from "lodash";
import { getAssignedGBMeetings, apiVersionWrapper } from "../../../helpers/api";
import moment from "moment";
import { Meeting } from "../../../models";
import { CalendarEvent } from "../../../components/Calendar/Calendar";
import { isToday } from "../../../helpers";

const getNotificationsAPI = (token: string) => {
  const API_URL = `${process.env.REACT_APP_API_URL}getNotifications`;
  return apiVersionWrapper(API_URL, "GET", {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  }).pipe(
    map((response) => {
      const notifications: Notification[] = response.response;
      return notifications;
    }),
    catchError((error: Error) => {
      return of(error);
    })
  );
};

const dismissNotificationAPI = (token: string, notification_id: number) => {
  const API_URL = `${process.env.REACT_APP_API_URL}dismissNotification/${notification_id}`;
  return apiVersionWrapper(API_URL, "PUT", {
    Authorization: `Bearer ${token}`,
  }).pipe(
    map((response) => {
      const allOk: boolean = response.response;
      return allOk;
    }),
    catchError((error: Error) => {
      return of(error);
    })
  );
};

const getDistances = (token: string, meetings: Meeting[]) => {
  const observables: Observable<Error | DrivingEvent>[] = [];
  meetings.sort(
    (a, b) =>
      new Date(a.start ? a.start : 0).getTime() -
      new Date(b.start ? b.start : 0).getTime()
  );
  meetings.forEach((meeting, i) => {
    if (i !== 0 && i !== meetings.length && meeting.orderID !== 0) {
      const previousMeetingIndex = i !== 0 && i !== meetings.length ? i - 1 : 0; // kinda useless no?
      const previousMeeting = meetings[previousMeetingIndex];
      if (isToday(previousMeeting.end)) {
        const API_URL = `${process.env.REACT_APP_API_URL}getDistance/${
          meetings[previousMeetingIndex].orderID
        }/${meeting.orderID}/${moment(meeting.start).format(
          "YYYY-MM-DD HH:mm:ss"
        )}`;
        observables.push(
          apiVersionWrapper(API_URL, "GET", {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          }).pipe(
            map((response) => {
              const drivingEvent: DrivingEvent = {
                ...response.response,
                to_order_id: meeting.orderID,
                meeting_start_time: meeting.start,
                from_order_id: meetings[previousMeetingIndex].orderID,
              };
              return drivingEvent;
            }),
            catchError((error: Error) => {
              return of(error);
            })
          )
        );
      }
    }
  });
  return observables;
};

export const getNotificationsEpic: Epic<RootAction, RootAction, RootState> = (
  action$
) =>
  action$.pipe(
    filter(isActionOf(getNotifications.request)),
    switchMap((action) =>
      from(getNotificationsAPI(action.payload.token)).pipe(
        switchMap((rsp) => {
          if (isArray(rsp)) {
            return of(getNotifications.success(rsp as Notification[]));
          }
          return EMPTY;
        }),
        catchError((err) => of(getNotifications.failure(err)))
      )
    )
  );

export const dismissNotificationEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$) =>
  action$.pipe(
    filter(isActionOf(dismissNotification.request)),
    switchMap((action) =>
      from(
        dismissNotificationAPI(
          action.payload.token,
          action.payload.id_notification
        )
      ).pipe(
        switchMap(() => {
          return concat(
            of(
              dismissNotification.success({
                id_notification: action.payload.id_notification,
              })
            ),
            of(
              showNotification({
                color: "success",
                message: "Dismissed notification",
                type: "Toast",
              })
            )
          );
        }),
        catchError((err) => of(dismissNotification.failure(err)))
      )
    )
  );

function isDrivingEvent(arg: any): arg is DrivingEvent {
  return arg && arg.driving_time && typeof arg.driving_time == "number";
}
export const GetDrivingEventEpic: Epic<RootAction, RootAction, RootState> = (
  action$
) =>
  action$.pipe(
    filter(isActionOf(getMeetingsForTheDay.success)),
    switchMap((action) => {
      return forkJoin(
        getDistances(
          action.payload.token,
          action.payload.meetings.filter(
            (m) => m.orderID !== 0 && isToday(m.start)
          )
        )
      ).pipe(
        mergeMap((rsp) => {
          const meetingsTimes: DrivingEvent[] = rsp.filter((result) =>
            isDrivingEvent(result)
          ) as DrivingEvent[];
          const events: CalendarEvent[] = meetingsTimes.map((r, i) => {
            return {
              id: i + Date.now(),
              start: moment(r.start_time).toDate(),
              end: moment(r.meeting_start_time).toDate(),
              title: "Estimated driving time from last meeting",
            };
          });
          return of(getDrivingEvent.success(events));
        }),
        catchError((err) => of(dismissNotification.failure(err)))
      );
    })
  );

export const GetMeetingsForTheDayEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$) =>
  action$.pipe(
    filter(isActionOf(getMeetingsForTheDay.request)),
    switchMap((action) =>
      from(
        getAssignedGBMeetings(
          action.payload.token,
          action.payload.gbID,
          moment().format("YYYY-MM-DD")
        )
      ).pipe(
        switchMap((rsp) => {
          return of(
            getMeetingsForTheDay.success({
              token: action.payload.token,
              meetings: isArray(rsp) ? (rsp as Meeting[]) : [],
            })
          );
        }),
        catchError((err) => of(getMeetingsForTheDay.failure(err)))
      )
    )
  );
