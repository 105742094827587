import { createAsyncAction } from "typesafe-actions";
import { CalendarEvent } from "../../../components/Calendar/Calendar";
import { Meeting } from "../../../models";

export interface Notification {
  id_gb_user_notification: number;
  text: string;
  icon: string;
  color: string;
  action: string;
}
export interface DrivingEvent {
  driving_time: number;
  start_time: string | Date;
  date: string; //2021-08-30 09:31:20
  from_order_id: number;
  to_order_id: number;
  meeting_start_time: string | Date;
}

export const getNotifications = createAsyncAction(
  "dashboard/GET_NOTIFICATIONS_REQUEST",
  "dashboard/GET_NOTIFICATIONS_SUCCESS",
  "dashboard/GET_NOTIFICATIONS_FAILURE"
)<{ token: string }, Notification[], Error | string>();

export const dismissNotification = createAsyncAction(
  "dashboard/DISMISS_NOTIFICATION_REQUEST",
  "dashboard/DISMISS_NOTIFICATION_SUCCESS",
  "dashboard/DISMISS_NOTIFICATION_FAILURE"
)<
  { token: string; id_notification: number },
  { id_notification: number },
  Error | string
>();

export const getMeetingsForTheDay = createAsyncAction(
  "dashboard/GET_MEETINGS_REQUEST",
  "dashboard/GET_MEETINGS_SUCCESS",
  "dashboard/GET_MEETINGS_FAILURE"
)<
  { token: string; gbID: number },
  { token: string; meetings: Meeting[] },
  Error
>();

export const getDrivingEvent = createAsyncAction(
  "dashboard/GET_DRIVING_EVENT_REQUEST",
  "dashboard/GET_DRIVING_EVENT_SUCCESS",
  "dashboard/GET_DRIVING_EVENT_FAILURE"
)<{ token: string; meetings: Meeting[] }, CalendarEvent[], Error | string>();
