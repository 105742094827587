import React, { useState } from "react";
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonBackButton, IonIcon, IonButton, isPlatform, IonList, IonItem, IonLabel, IonSelect, IonSelectOption, IonInput } from "@ionic/react";
import "./DetailsPage.css";
import { RouteComponentProps } from "react-router";
import { DetailsFeature } from "../../features/";
import { callOutline } from "ionicons/icons";
import { Modal } from "../../components";
import { ModalAction } from "../../models";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "typesafe-actions";
import { getCustomerResponseOption, setCustomerResponseOption } from "../../features/Details/redux/actions";
import { useTranslation } from "react-i18next";
type TParams = {
  id: string;
};
interface Props extends RouteComponentProps<TParams> {
  pageName: string;
}
const DetailsPage: React.FC<Props> = props => {
  const {
    pageName
  } = props;
  const {
    t
  } = useTranslation();
  const reasons = useSelector((state: RootState) => state.detailsReducer.customerOptions);
  const auth = useSelector((state: RootState) => state.authReducer);
  const dispatch = useDispatch();
  const [edit] = useState(false);
  const [notInterestedAlert, setNotInterestedAlert] = useState(false);
  const [notInterestedReason, setNotInterestedReason] = useState<number>();
  const [notInterestedNote, setNotInterestedNote] = useState<string>();
  //Method
  const notInterestedModalAction: ModalAction = {
    fn: () => {
      dispatch(setCustomerResponseOption.request({
        token: auth.token!,
        id_order: Number(props.match.params.id),
        order_note: notInterestedNote ? notInterestedNote : "",
        id_gardinbus_customer_response: notInterestedReason ? notInterestedReason : -1
      }));
      setNotInterestedAlert(false);
    },
    name: t("Save"),
    type: "button"
  };
  const notInterestedModal = () => {
    return <Modal isOpen={notInterestedAlert} onClose={() => setNotInterestedAlert(false)} title={t("LogCall")} actions={[notInterestedModalAction]}>
        <IonList>
          <IonItem>
            <IonLabel position={"floating"}>{t("Reason")}</IonLabel>
            <IonSelect value={notInterestedReason} placeholder={t("Reason")} onIonChange={e => setNotInterestedReason(e.detail.value!)} okText={t("Select")} cancelText={t("Dismiss")}>
              {reasons?.map(reason => <IonSelectOption key={reason.id_gardinbus_customer_response} value={reason.id_gardinbus_customer_response}>
                  {reason.name}
                </IonSelectOption>)}
            </IonSelect>
          </IonItem>
          <IonItem>
            <IonLabel position={"floating"}>{t("Note")}</IonLabel>
            <IonInput onIonChange={e => setNotInterestedNote(e.detail.value!)} placeholder={t("NewNote")} />
          </IonItem>
        </IonList>
      </Modal>;
  };
  return <IonPage>
      {notInterestedModal()}
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>
            {pageName} [OrderID: {props.match.params.id}]
          </IonTitle>
          {!isNaN(Number(props.match.params.id)) && <IonButtons slot={"end"}>
              <IonButton style={{
            width: '100%'
          }} color={"danger"} onClick={() => {
            dispatch(getCustomerResponseOption.request({
              token: auth.token!
            }));
            setNotInterestedAlert(true);
          }}>
                <IonIcon slot="icon-only" style={isPlatform("cordova") ? {
              marginRight: "0.4rem"
            } : undefined} icon={callOutline} />
                {isPlatform("cordova") && <>{t("NotInterested")}</>}
              </IonButton>
            </IonButtons>}
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {/* <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{pageName}</IonTitle>
          </IonToolbar>
         </IonHeader> */}
        <DetailsFeature editable={edit} {...props} />
      </IonContent>
    </IonPage>;
};
export default DetailsPage;