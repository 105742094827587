import { ActionType, getType, Reducer } from "typesafe-actions";

import * as sms from "./actions";
import { Message } from "./types";

export type SMSAction = ActionType<typeof sms>;

interface SMSState {
  messages: Message[];
  isLoading?: boolean;
  sent?: boolean;
}

const initialState: SMSState = {
  messages: [],
  isLoading: false,
  sent: false,
};

export const smsReducer: Reducer<SMSState, SMSAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getType(sms.getMessages.success):
      return {
        ...state,
        messages: action.payload,
      };
    case getType(sms.sendSMS.success):
      return {
        ...state,
        sent: action.payload,
      };
    case getType(sms.clearSent):
      return {
        ...state,
        sent: false,
      };
    default:
      return state;
  }
};
