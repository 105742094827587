import { IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonButton, IonCardSubtitle, IonItem, IonList, IonLabel, IonText, IonIcon } from "@ionic/react";
import { add } from "ionicons/icons";
import React from "react";
import { useTranslation } from "react-i18next";
import "./OfferCard.css";
export interface IOffer {
  id: number;
  dateCreated: Date | string;
  productCount: number;
  offerLink: string;
  sent: boolean;
  generalComment?: string;
  isDraft?: boolean;
  isLocalDraft?: boolean;
}
export interface IPropsOffer {
  offer: IOffer[];
  onClickOffer: (offer_id: number) => void;
  onClickNewOffer: (evt: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => void;
}
type Props = React.FC<IPropsOffer>;

// Section Offers
// Title: Offers
// Data for each: Offer Date, Sent to customer(bool), Offer comment, Total
// Section Action: Add offer
// Actions per offer: Details, Edit

const OfferCard: Props = ({
  offer,
  onClickOffer,
  onClickNewOffer
}) => {
  const {
    t
  } = useTranslation();
  return <>
      {offer.length > 0 ? <IonCard>
          <IonCardHeader>
            <IonCardTitle style={{
          display: "flex",
          alignItems: "center"
        }}>
              {t("Offers")}
              <IonButton fill={"clear"} onClick={onClickNewOffer}>
                <IonIcon icon={add} />
                {t("Add")}
              </IonButton>
            </IonCardTitle>
          </IonCardHeader>
          <IonCardContent style={{
        padding: 0
      }}>
            <IonList>
              {offer.map((o, i) => <React.Fragment key={i}>
                  <IonItem detail button onClick={() => onClickOffer(o.id)} key={o.id}>
                    <IonLabel className="ion-text-wrap">
                      <IonText>
                        <h2>
                          {t("Created")} <b>{o.dateCreated}</b>
                        </h2>
                      </IonText>
                      <IonText style={{
                  display: "inline-flex",
                  marginTop: "0.5rem"
                }}>
                        <IonText className={"card-buble"} style={{
                    marginRight: ".5rem"
                  }}>
                          {t("Products")} <b>{o.productCount}</b>
                        </IonText>
                        <IonText color={o.sent ? "success" : "warning"} className={"card-buble"}>
                          {t("SentToCustomer?")} <b>{o.sent ? t("Yes") : t("No")}</b>
                        </IonText>
                      </IonText>
                      <p style={{
                  marginTop: ".4rem"
                }}>
                        {o.generalComment && <>
                            <IonText style={{
                      marginTop: "0.4rem"
                    }}>
                              {o.generalComment}
                            </IonText>
                          </>}
                      </p>
                    </IonLabel>
                    {/* <IonButton
                      slot={"end"}
                      fill="outline"
                      onClick={() => onClickOffer(o.id)}
                     >
                      View Offer
                     </IonButton> */}
                    {o.isDraft && <IonText color="warning" style={{
                padding: "0.2rem .5rem",
                border: " 1px solid",
                borderRadius: "8px"
              }}>
                        {t("Draft")}
                      </IonText>}
                    {o.isLocalDraft && <IonText color="primary" style={{
                padding: "0.2rem .5rem",
                border: " 1px solid",
                borderRadius: "8px",
                marginLeft: "16px"
              }}>
                        {t("SavedLocally")}
                      </IonText>}
                  </IonItem>
                  {o.offerLink && o.offerLink.trim().length > 0 && <IonItem key={o.offerLink}>
                      <IonButton fill={"outline"} target={"_blank"} href={o.offerLink}>
                        {t("OfferLink")}
                      </IonButton>
                    </IonItem>}
                </React.Fragment>)}
            </IonList>
          </IonCardContent>
        </IonCard> : <IonCard>
          <IonCardHeader>
            <IonCardTitle style={{
          display: "flex",
          alignItems: "center"
        }}>
              {t("Offers")}
              <IonButton fill={"clear"} onClick={onClickNewOffer}>
                <IonIcon icon={add} />
                {t("Add")}
              </IonButton>
            </IonCardTitle>
            <IonCardSubtitle>{t("NoOffers")}</IonCardSubtitle>
          </IonCardHeader>
        </IonCard>}
    </>;
};
export default OfferCard;