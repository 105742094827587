import { Epic } from "redux-observable";
import { from, of } from "rxjs";
import { filter, catchError, switchMap } from "rxjs/operators";
import { RootAction, RootState, isActionOf } from "typesafe-actions";
import { getRole } from "../../../helpers";
import {
  getAttributesAction,
  getConfigAction,
  getDNAAction,
  getPriceListAction,
  getProductsAction,
  loginAction,
  User,
} from "./actions";
import {
  ApiGetToken,
  Attributes_API,
  Configuration_API,
  DNA_API,
  getAttributes,
  getConfiguration,
  getDNAAPI,
  getPriceList,
  getProducts,
  PriceList_API,
  Products_API,
} from "../../../helpers/api";
import { API_USER } from "../../../models/apiModels";
import { showNotification } from "../../Notification/redux/actions";
import { Keychain } from "@awesome-cordova-plugins/keychain";

export const loginSuccessEpic: Epic<RootAction, RootAction, RootState> = (
  action$
) =>
  action$.pipe(
    filter(isActionOf(loginAction.request)),
    switchMap((action) => {
      return from(
        ApiGetToken(action.payload.email, action.payload.password)
      ).pipe(
        switchMap((response) => {
          const rsp = response as API_USER;
          const user: User = {
            name: rsp.payload.user.name + " " + rsp.payload.user.lastname,
            role: getRole(Number(rsp.payload.user.role)),
            userID: Number(rsp.payload.user.id),
            token: rsp.token,
            email: rsp.payload.user.email,
          };
          //Save into keychain
          Keychain.setJson(
            "user-login",
            { email: action.payload.email, password: action.payload.password },
            false
          );
          return of(
            loginAction.success(user),
            getConfigAction.request(rsp.token),
            getDNAAction.request(rsp.token),
            getProductsAction.request(rsp.token),
            getPriceListAction.request(rsp.token),
            getAttributesAction.request(rsp.token)
          );
        }),
        catchError((err: Error) => {
          if (err.message === "ajax error 401") {
            return of(
              showNotification({
                message: "Check your username and password!",
                color: "danger",
                type: "Toast",
              })
            );
          } else {
            return of(
              showNotification({
                message: err.stack + "-" + err.name + "-" + err.message,
                color: "danger",
                type: "Toast",
              })
            );
          }
        })
      );
    }),
    catchError((err: Error) => of(loginAction.failure(err)))
  );

// const getFromPouchDb = async () => {
//   return await pouchDb
//     .allDocs({ include_docs: true })
//     .then((rsp) => rsp)
//     .catch((err) => err);
// };
// export const OnLoginSuccessEpic: Epic<RootAction, RootAction, RootState> = (
//   action$
// ) =>
//   action$.pipe(
//     filter(isActionOf(loginAction.success)),
//     switchMap((action) => {
//       return from(getFromPouchDb()).pipe(
//         switchMap((response) => {
//           const allDocs: {
//             total_rows: number;
//             offset: number;
//             rows: {
//               id: string;
//               key: string;
//               value: { rev: string };
//               doc: any;
//             }[];
//           } = response;
//           const global_config = allDocs.rows.filter(
//             (r) => r.key === "global_config"
//           )[0].doc;
//           const dna = allDocs.rows.filter((r) => r.key === "dna")[0].doc;
//           const attributes = allDocs.rows.filter(
//             (r) => r.key === "attributes"
//           )[0].doc;
//           const pricelist = allDocs.rows.filter((r) => r.key === "pricelist")[0]
//             .doc;
//           const products = allDocs.rows.filter((r) => r.key === "products")[0]
//             .doc;
//           return of(
//             localConfigs({
//               global_config,
//               dna,
//               attributes,
//               pricelist,
//               products,
//             })
//           );
//         }),
//         catchError((err: Error) => {
//           return of(loginAction.failure(err));
//         })
//       );
//     }),
//     catchError((err: Error) => of(loginAction.failure(err)))
//   );

export const loginGetConfigEpic: Epic<RootAction, RootAction, RootState> = (
  action$
) =>
  action$.pipe(
    filter(isActionOf(getConfigAction.request)),
    switchMap((action) => {
      return from(getConfiguration(action.payload)).pipe(
        switchMap((response) => {
          // createFile(response, "config");
          // pouchDb
          //   .get("global_config")
          //   .then(function (doc) {
          //     return pouchDb.put({
          //       _id: "global_config",
          //       _rev: doc._rev,
          //       ...response,
          //     });
          //   })
          //   .then(function (response) {
          //     // handle response
          //   })
          //   .catch(function (err) {
          //     // if it doesnt have create
          //     return pouchDb.put({
          //       _id: "global_config",
          //       ...response,
          //     });
          //   });

          return of(getConfigAction.success(response as Configuration_API));
        }),
        catchError((err: Error) => {
          return of(getConfigAction.failure(err));
        })
      );
    }),
    catchError((err: Error) => of(getConfigAction.failure(err)))
  );

export const loginGetDNAEpic: Epic<RootAction, RootAction, RootState> = (
  action$
) =>
  action$.pipe(
    filter(isActionOf(getDNAAction.request)),
    switchMap((action) => {
      return from(getDNAAPI(action.payload)).pipe(
        switchMap((response) => {
          // pouchDb
          //   .get(id)
          //   .then(function (doc) {
          //     return pouchDb.put({
          //       _id: id,
          //       _rev: doc._rev,
          //       ...response,
          //     });
          //   })
          //   .then(function (response) {
          //     // handle response
          //   })
          //   .catch(function (err) {
          //     // if it doesnt have create
          //     return pouchDb.put({
          //       _id: id,
          //       ...response,
          //     });
          //   });
          return of(getDNAAction.success(response as DNA_API));
        }),
        catchError((err: Error) => {
          return of(getDNAAction.failure(err));
        })
      );
    }),
    catchError((err: Error) => of(getDNAAction.failure(err)))
  );

export const loginGetProductEpic: Epic<RootAction, RootAction, RootState> = (
  action$
) =>
  action$.pipe(
    filter(isActionOf(getProductsAction.request)),
    switchMap((action) => {
      return from(getProducts(action.payload)).pipe(
        switchMap((response) => {
          // pouchDb
          //   .get(id)
          //   .then(function (doc) {
          //     return pouchDb.put({
          //       _id: id,
          //       _rev: doc._rev,
          //       ...response,
          //     });
          //   })
          //   .then(function (response) {
          //     // handle response
          //   })
          //   .catch(function (err) {
          //     // if it doesnt have create
          //     return pouchDb.put({
          //       _id: id,
          //       ...response,
          //     });
          //   });
          return of(getProductsAction.success(response as Products_API));
        }),
        catchError((err: Error) => {
          return of(getProductsAction.failure(err));
        })
      );
    }),
    catchError((err: Error) => of(getProductsAction.failure(err)))
  );

export const loginGetPricelistEpic: Epic<RootAction, RootAction, RootState> = (
  action$
) =>
  action$.pipe(
    filter(isActionOf(getPriceListAction.request)),
    switchMap((action) => {
      return from(getPriceList(action.payload)).pipe(
        switchMap((response) => {
          // pouchDb
          //   .get(id)
          //   .then(function (doc) {
          //     return pouchDb.put({
          //       _id: id,
          //       _rev: doc._rev,
          //       ...response,
          //     });
          //   })
          //   .then(function (response) {
          //     // handle response
          //   })
          //   .catch(function (err) {
          //     // if it doesnt have create
          //     return pouchDb.put({
          //       _id: id,
          //       ...response,
          //     });
          //   });
          return of(getPriceListAction.success(response as PriceList_API));
        }),
        catchError((err: Error) => {
          return of(getPriceListAction.failure(err));
        })
      );
    }),
    catchError((err: Error) => of(getPriceListAction.failure(err)))
  );

export const loginGetAttributesEpic: Epic<RootAction, RootAction, RootState> = (
  action$
) =>
  action$.pipe(
    filter(isActionOf(getAttributesAction.request)),
    switchMap((action) => {
      return from(getAttributes(action.payload)).pipe(
        switchMap((response) => {
          // pouchDb
          //   .get(id)
          //   .then(function (doc) {
          //     return pouchDb.put({
          //       _id: id,
          //       _rev: doc._rev,
          //       ...response,
          //     });
          //   })
          //   .then(function (response) {
          //     // handle response
          //   })
          //   .catch(function (err) {
          //     // if it doesnt have create
          //     return pouchDb.put({
          //       _id: id,
          //       ...response,
          //     });
          //   });
          return of(getAttributesAction.success(response as Attributes_API));
        }),
        catchError((err: Error) => {
          return of(getAttributesAction.failure(err));
        })
      );
    }),
    catchError((err: Error) => of(getAttributesAction.failure(err)))
  );
