export const parsedOfferToBodyRequest = (
  products: any,
  offers: any[],
  newProduct?: any,
  productIndex?: number
) => {
  let _offer: any = {
    attributes: {},
    comment: {},
    height2: {},
    height: {},
    id_product: {},
    product: {},
    quantity: {},
    width2: {},
    width: {},
    width_a: {},
    width_b: {},
    width_c: {},
    manual: {},
    prefilled: {},
    readyToSend: {},
    new: {},
  };

  // Primeiro, preenche _offer com as ofertas existentes
  offers.forEach((product, index) => {
    _offer.attributes[index] = product.choosen_attributes_id;
    _offer.comment[index] = product.comment;
    _offer.height2[index] = product.height_2 || {}; 
    _offer.height[index] = product.height;
    _offer.id_product[index] = product.product_id;
    _offer.product[index] =
      products.find((_p: any) => _p.id_product === product.product_id)?.name ||
      "Produto Desconhecido";
    _offer.quantity[index] = product.quantity;
    _offer.width2[index] = product.width_2 || {}; 
    _offer.width[index] = product.width;
    _offer.width_a[index] = product.width_a || {}; 
    _offer.width_b[index] = product.width_b || {}; 
    _offer.width_c[index] = product.width_c || {}; 
    _offer.manual[index] = product.manual || {}; 
    _offer.prefilled[index] = product.prefilled;
    _offer.readyToSend[index] = product.readyToSend;
    _offer.new[index] = product.new;
  });

  if (newProduct) {
    const idx =
      typeof productIndex !== "undefined"
        ? productIndex
        : Object.keys(_offer.id_product).length;

    _offer.attributes[idx] = newProduct.choosen_attributes_id;
    _offer.comment[idx] = newProduct.comment;
    _offer.height2[idx] = newProduct.height_2 || {};
    _offer.height[idx] = newProduct.height;
    _offer.id_product[idx] = newProduct.product_id;
    _offer.product[idx] =
      products.find((_p: any) => _p.id_product === newProduct.product_id)
        ?.name || "Unknown Product";
    _offer.quantity[idx] = newProduct.quantity;
    _offer.width2[idx] = newProduct.width_2 || {};
    _offer.width[idx] = newProduct.width;
    _offer.width_a[idx] = newProduct.width_a || {};
    _offer.width_b[idx] = newProduct.width_b || {};
    _offer.width_c[idx] = newProduct.width_c || {};
    _offer.manual[idx] = newProduct.manual || {};
    _offer.prefilled[idx] = newProduct.prefilled;
    _offer.readyToSend[idx] = newProduct.readyToSend;
    _offer.new[idx] = newProduct.new;
  }

  return _offer;
};
