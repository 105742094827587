import { createAction, createAsyncAction } from "typesafe-actions";
import {
  Attributes_API,
  Configuration_API,
  DNA_API,
  PriceList_API,
  Products_API,
} from "../../../helpers/api";
import { Roles } from "../../../models";

export interface Login {
  email: string;
  password: string;
}
export interface User {
  userID: number;
  name: string;
  role: Roles;
  token: string;
  email?: string;
}
export const loginAction = createAsyncAction(
  "login/LOGIN_REQUEST",
  "login/LOGIN_SUCCESS",
  "login/LOGIN_FAILURE"
)<Login, User, Error | string>();

export const logoutAction = createAsyncAction(
  "login/LOGOUT_REQUEST",
  "login/LOGOUT_SUCCESS",
  "login/LOGOUT_FAILURE"
)<void, void, Error | string>();

export const toggleWorkingOnline = createAction(
  "login/TOGGLE_ONLINE"
)<boolean>();

export const getConfigAction = createAsyncAction(
  "login/CONFIG_REQUEST",
  "login/CONFIG_SUCCESS",
  "login/CONFIG_FAILURE"
)<string, Configuration_API, Error | string>();

export const getDNAAction = createAsyncAction(
  "login/DNA_REQUEST",
  "login/DNA_SUCCESS",
  "login/DNA_FAILURE"
)<string, DNA_API, Error | string>();

export const getProductsAction = createAsyncAction(
  "login/PRODUCT_REQUEST",
  "login/PRODUCT_SUCCESS",
  "login/PRODUCT_FAILURE"
)<string, Products_API, Error | string>();

export const getPriceListAction = createAsyncAction(
  "login/PRICE_REQUEST",
  "login/PRICE_SUCCESS",
  "login/PRICE_FAILURE"
)<string, PriceList_API, Error | string>();

export const getAttributesAction = createAsyncAction(
  "login/ATTRIBUTES_REQUEST",
  "login/ATTRIBUTES_SUCCESS",
  "login/ATTRIBUTES_FAILURE"
)<string, Attributes_API, Error | string>();

export const localConfigs = createAction("login/LOCAL_CONFIG")<{
  dna?: DNA_API;
  global_config?: Configuration_API;
  products?: Products_API;
  pricelist?: PriceList_API;
  attributes?: Attributes_API;
}>();
