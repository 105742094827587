import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { IonInput, IonItem, IonLabel, IonList, IonSelect, IonSelectOption } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { UserSettingsAPIDTO } from "../../models";
export interface UserForm {
  name: string;
  lastname: string;
  company: string;
  address1: string;
  city: string;
  postcode: string;
  id_country: number;
  phone: number;
}
export interface SaveUserForm {
  onSave: () => {
    newUser: UserSettingsAPIDTO;
  };
}
const UserSettingsForm = forwardRef((props: UserForm, ref) => {
  const {
    t
  } = useTranslation();
  const newUserForm = useRef<HTMLFormElement>(null);
  useImperativeHandle(ref, (): SaveUserForm => ({
    onSave() {
      // Form data
      const name = newUserForm.current!["_name"].value;
      const lastname = newUserForm.current!["lastname"].value;
      const phone = newUserForm.current!["phone"].value;
      const company = newUserForm.current!["company"].value;
      const address1 = newUserForm.current!["address1"].value;
      const postcode = newUserForm.current!["postcode"].value;
      const city = newUserForm.current!["city"].value;
      const id_country = newUserForm.current!["country"].value;
      return {
        newUser: {
          name,
          lastname,
          phone,
          company,
          address1,
          postcode,
          city,
          id_country
        }
      };
    }
  }));
  return <>
      <form ref={newUserForm}>
        <IonList>
          <IonItem>
            <IonLabel position={"floating"}>{t("Name*")}</IonLabel>
            <IonInput value={props.name} name={"_name"} type="text" placeholder={t("UserName")} />
          </IonItem>
          <IonItem>
            <IonLabel position={"floating"}>{t("LastName*")}</IonLabel>
            <IonInput value={props.lastname} name={"lastname"} type="text" placeholder={t("LastName")} />
          </IonItem>{" "}
          <IonItem>
            <IonLabel position={"floating"}>{t("Phone")}</IonLabel>
            <IonInput value={props.phone} name={"phone"} type="number" placeholder={t("Phone")} />
          </IonItem>{" "}
          <IonItem>
            <IonLabel position={"floating"}>{t("Company")}</IonLabel>
            <IonInput value={props.company} name={"company"} type="text" placeholder={t("Company")} />
          </IonItem>{" "}
          <IonItem>
            <IonLabel position={"floating"}>{t("Address*")}</IonLabel>
            <IonInput value={props.address1} name={"address1"} type="text" placeholder={t("Address")} />
          </IonItem>{" "}
          <IonItem>
            <IonLabel position={"floating"}>{t("Postcode*")}</IonLabel>
            <IonInput value={props.postcode} name={"postcode"} type="text" placeholder={t("Postcode")} />
          </IonItem>{" "}
          <IonItem>
            <IonLabel position={"floating"}>{t("City*")}</IonLabel>
            <IonInput value={props.city} name={"city"} type="text" placeholder={t("City")} />
          </IonItem>{" "}
          <IonItem>
            <IonLabel position={"floating"}>{t("Country*")}</IonLabel>
            <IonSelect value={props.id_country} name={"country"} placeholder={t("Country")}>
              <IonSelectOption value={20}>{t("Denmark")}</IonSelectOption>
              <IonSelectOption value={89}>{t("FaroeIslands")}</IonSelectOption>
              <IonSelectOption value={1}>{t("Germany")}</IonSelectOption>
              <IonSelectOption value={96}>{t("Greenland")}</IonSelectOption>
              <IonSelectOption value={23}>{t("Norway")}</IonSelectOption>
              <IonSelectOption value={18}>{t("Sweden")}</IonSelectOption>
            </IonSelect>
          </IonItem>
        </IonList>
      </form>
    </>;
});
export default UserSettingsForm;