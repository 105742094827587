import { ActionType, getType, Reducer } from "typesafe-actions";

import * as settings from "./actions";
import { UserSettingsAPI } from "../../../models/";
import { cloneDeep } from "lodash";

export type SettingsAction = ActionType<typeof settings>;

interface SettingsState {
  error?: string | Error;
  userSettings: UserSettingsAPI;
  loading: boolean;
  calendarChoosen: string;
}

const initialState: SettingsState = {
  loading: true,
  calendarChoosen: "",
  userSettings: {
    address1: "",
    city: "",
    color: "",
    company: "",
    email: "",
    id_country: 0,
    id_gb_user: 0,
    id_gb_user_role: 0,
    id_gb_user_team: 0,
    id_shop: 0,
    lastname: "",
    name: "",
    phone: "",
    postcode: "",
  },
};

export const settingsReducer: Reducer<SettingsState, SettingsAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getType(settings.getUser.success):
      return {
        ...state,
        userSettings: action.payload,
        loading: false,
      };
    case getType(settings.updateUser.success):
      const copy = cloneDeep(state.userSettings);
      copy.address1 = action.payload.address1;
      copy.city = action.payload.city;
      copy.company = action.payload.company;
      copy.id_country = action.payload.id_country;
      copy.lastname = action.payload.lastname;
      copy.name = action.payload.name;
      copy.phone = action.payload.phone;
      copy.postcode = action.payload.postcode;
      return {
        ...state,
        userSettings: copy,
        loading: false,
      };

    default:
      return state;
  }
};
