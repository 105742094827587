import React, { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { IonCheckbox, IonDatetime, IonDatetimeButton, IonInput, IonItem, IonLabel, IonList, IonModal, IonSelect, IonSelectOption, isPlatform } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { GBArray, NewEventForm, OrderDetail } from "../../models";
import moment from "moment";
import { generateRandomID, isWeekday, validDate } from "../../helpers";
import "./MeetingForm.css";
import { API_LEADS_PREVIEW } from "../../models/apiModels";
import { isNull } from "lodash";
import { useStateCallback } from "../../helpers/hooks/useStateCallback";
interface Props {
  canCheckDrivingTime?: (bol: boolean, gbID: number, order_id: number, startTime: string, endTime: string) => void;
  onSelectGB?: (id: number) => void;
  gbList: GBArray | undefined;
  assignedGb?: number | null;
  orderList: API_LEADS_PREVIEW[];
  orderInfo?: OrderDetail;
  startDate?: Date | string;
  endDate?: Date | string;
  hideTitle?: boolean;
  hideOrder?: boolean;
  hideGB?: boolean;
}
export interface SaveForm {
  onSave: () => {
    newEvent: NewEventForm;
  };
}
interface Error {
  startDate: boolean | undefined;
  endDate?: boolean | undefined;
  gb: boolean | undefined;
}
const MeetingForm = forwardRef((props: Props, ref) => {
  const {
    t
  } = useTranslation();
  const {
    orderInfo,
    gbList,
    assignedGb,
    orderList,
    hideTitle,
    hideOrder,
    hideGB,
    canCheckDrivingTime,
    startDate,
    endDate
  } = props;
  const newEventForm = useRef<HTMLFormElement>(null);
  const [checked, setChecked] = useState(true);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [eventTitle, setEventTitle] = useState(orderInfo ? orderInfo.customer.post_code + "/" + orderInfo.order_id + "  " + orderInfo.customer.customer_name + " , " + orderInfo.customer.phone : "");
  useImperativeHandle(ref, (): SaveForm => ({
    onSave() {
      // Form data
      const _startDateString = newEventForm.current!["startDate"].value;
      const _endDateString = newEventForm.current!["endDate"].value;
      const _startDate = moment(newEventForm.current!["startDate"].value).toDate();
      const _endDate = _endDateString ? moment(_endDateString).toDate() : undefined;
      const _title = eventTitle;
      const _gbID = assignedGb && !Number(newEventForm.current!["gb_id"].value) ? assignedGb : Number(newEventForm.current!["gb_id"].value);
      const _orderID = newEventForm.current!["order_id"].value;
      const _notify = Boolean(newEventForm.current!["notify_customer"].value);
      // Local Variables
      let _error: Error = {
        startDate: false,
        endDate: false,
        gb: false
      };

      // Validations
      if (!newEventForm.current!["gb_id"].value) {
        _error = {
          ..._error,
          gb: true
        };
      }
      if (!_startDateString) {
        _error = {
          ..._error,
          startDate: true
        };
      }
      if (!_endDateString) {
        _error = {
          ..._error,
          endDate: true
        };
      }
      if (_startDate && _endDate && !validDate(_startDate, _endDate)) {
        if (_startDate > _endDate || _startDate < new Date(Date.now())) {
          _error = {
            ..._error,
            startDate: true
          };
        }
        if (_endDate < _startDate || _endDate < new Date(Date.now())) {
          _error = {
            ..._error,
            endDate: true
          };
        }
      }
      setError(_error);
      return {
        newEvent: {
          operatorID: _gbID,
          // Problem when GB ID is 0
          startDate: _startDate,
          endDate: _endDate,
          title: _title,
          meetingID: generateRandomID(),
          orderID: _orderID,
          notifyCustomer: _notify,
          note: note
        }
      };
    }
  }));
  const gbValue = gbList && Object.values(gbList).length === 1 ? Object.values(gbList)[0] : assignedGb && gbList ? Object.values(gbList)[assignedGb] : null;
  const [Fields, setFields] = useStateCallback<{
    start: string | null;
    end: string | null;
    gb: number | null;
    order_id: number | null;
  }>({
    start: startDate ? isPlatform("desktop") ? moment(startDate).format("YYYY-MM-DDTHH:mm") : moment(startDate).toISOString() : orderInfo?.meeting?.start?.toISOString() ?? null,
    end: endDate ? isPlatform("desktop") ? moment(endDate).format("YYYY-MM-DDTHH:mm") : moment(endDate).toISOString() : orderInfo?.meeting?.end?.toISOString() ?? null,
    gb: orderInfo?.assignedGBID ? orderInfo?.assignedGBID : gbValue?.id ? gbValue?.id : null,
    order_id: orderInfo?.order_id ?? null
  });
  const allRequiredFieldFilled = (fields: {
    start: string | null;
    end: string | null;
    gb: number | null;
    order_id: number | null;
  }) => {
    if (fields.start && fields.end && fields.gb && (fields.order_id || orderInfo?.order_id) && canCheckDrivingTime) {
      canCheckDrivingTime(true, isNull(fields.gb) && !isNull(assignedGb) ? assignedGb! : fields.gb, orderInfo ? Number(orderInfo.order_id) : fields.order_id!, fields.start, fields.end);
    }
  };
  const renderStartInput = () => {
    return <IonItem color={error?.startDate ? "danger" : undefined}>
        <IonLabel position={"stacked"}>{t("StartRequired")}</IonLabel>
        <IonDatetimeButton style={{
        marginTop: "1rem"
      }} datetime="datetimestart"></IonDatetimeButton>

        <IonModal keepContentsMounted={true}>
          <IonDatetime minuteValues="0,15,30,45" isDateEnabled={isWeekday} hourCycle="h24" hourValues={[8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22]} name={"startDate"} value={Fields.start} showDefaultButtons={true} id="datetimestart" onIonChange={e => {
          let endDate = new Date(e.detail.value.toString());
          // Add 2 hours
          endDate.setHours(endDate.getHours() + 3);
          setFields({
            ...Fields,
            start: e.detail.value ? e.detail.value.toString() : null,
            end: endDate.toISOString().slice(0, 19)
          }, newState => {
            allRequiredFieldFilled(newState);
          });
        }} />
        </IonModal>
      </IonItem>;
  };
  const renderEndInput = () => {
    return <IonItem color={error?.endDate ? "danger" : undefined}>
        <IonLabel position={"stacked"}>{t("EndRequired")}</IonLabel>
        <IonDatetimeButton style={{
        marginTop: "1rem"
      }} datetime="datetimeend"></IonDatetimeButton>

        <IonModal keepContentsMounted={true}>
          <IonDatetime showDefaultButtons={true} id="datetimeend" minuteValues={[0, 15, 30, 45]} hourValues={[8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22]} name={"endDate"} isDateEnabled={isWeekday} hourCycle="h24" value={Fields.end} onIonChange={e => {
          setFields({
            ...Fields,
            end: e.detail.value ? e.detail.value.toString() : null
          }, newState => {
            allRequiredFieldFilled(newState);
          });
        }} />
        </IonModal>
      </IonItem>;
  };
  const [note, setNote] = React.useState<string>("");
  return <>
      <form ref={newEventForm}>
        <IonList>
          <IonItem className={hideTitle ? "hidden" : undefined}>
            <IonLabel position={"floating"}>{t("Title")}</IonLabel>
            <IonInput name={"eventTitle"} type="text" placeholder={"PlaceholderEventTitle"} value={eventTitle} onIonChange={e => {
            setEventTitle(String(e.target.value));
          }}
          //   value={
          //     orderInfo
          // ? `${
          //     orderInfo.address_delivery
          //       ? action.payload.postcode + "/"
          //       : "NoPostcode/"
          //   }${action.payload.event.orderID} - ${action.payload.fullName}`
          // :
          // }
          />
          </IonItem>
          {renderStartInput()}
          {renderEndInput()}

          <IonItem color={error?.gb ? "danger" : undefined}>
            <IonLabel position={"floating"}>{t("GBRequired")}</IonLabel>
            <IonSelect value={assignedGb && !Fields.gb ? assignedGb : Fields.gb} placeholder={gbValue ? undefined : t("SelectGBPlaceholder")} name={"gb_id"} okText={t("SelectGBPlaceholder")} cancelText={t("Dismiss")} onIonChange={e => {
            setFields({
              ...Fields,
              gb: e.detail.value
            }, newState => {
              allRequiredFieldFilled(newState);
            });
          }}>
              {gbList && Object.values(gbList).map(o => {
              return <IonSelectOption key={o.id} value={o.id}>
                      {o.name}
                    </IonSelectOption>;
            })}
            </IonSelect>
          </IonItem>
          <IonItem className={hideOrder ? "hidden" : undefined}>
            <IonLabel position={"floating"}>{t("RelatedOrder")}</IonLabel>
            <IonSelect value={orderInfo ? orderInfo?.order_id : Fields.order_id} placeholder={orderInfo ? undefined : t("SelectOrder")} name={"order_id"} okText={t("SelectOrder")} cancelText={t("Dismiss")} onIonChange={e => {
            setFields({
              ...Fields,
              order_id: e.detail.value
            }, newState => {
              allRequiredFieldFilled(newState);
            });
          }}>
              <IonSelectOption key={"Not related to order"} value={null}>
                {t("Not related to order")}
              </IonSelectOption>
              {orderList && Object.values(orderList).map(order => {
              return <IonSelectOption key={order.id_order} value={order.id_order}>
                      {order.id_order} - {order.customer.firstname}{" "}
                      {order.customer.lastname}
                    </IonSelectOption>;
            })}
            </IonSelect>
          </IonItem>
          <IonItem>
            <IonLabel>{t("NotifyCustomer")}</IonLabel>
            <IonCheckbox checked={checked} name={"notify_customer"} onIonChange={e => setChecked(e.detail.checked)} />
          </IonItem>
          <IonItem>
            <IonLabel>Note</IonLabel>
            <IonInput value={note} onIonChange={e => setNote(e.target.value.toString())} />
          </IonItem>
        </IonList>
      </form>
    </>;
});
export default MeetingForm;