import { createAction, createAsyncAction } from "typesafe-actions";
import { API_LEADS_PREVIEW } from "../../../models/apiModels";

export const logTimeSpent = createAsyncAction(
  "mounting/MOUNTER_TIMESPENT_REQUEST",
  "mounting/MOUNTER_TIMESPENT_SUCCESS",
  "mounting/MOUNTER_TIMESPENT_FAILURE"
)<
  { id: number; timeSpent: number },
  { id: number; timeSpent: number },
  Error
>();

export const finishMounting = createAsyncAction(
  "mounting/FINISH_REQUEST",
  "mounting/FINISH_SUCCESS",
  "mounting/FINISH_FAILURE"
)<
  {
    token: string;
    order_id: number;
    mounting_time: string;
    order_note: string;
  },
  boolean,
  Error
>();

export const initMountings = createAsyncAction(
  "mounting/INIT_REQUEST",
  "mounting/INIT_SUCCESS",
  "mounting/INIT_FAILURE"
)<string, API_LEADS_PREVIEW[], Error>();

export const resetToast = createAction("mounting/RESET_TOAST")<void>();
