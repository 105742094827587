import React from "react";
import { useTranslation } from "react-i18next";
import { IonCard, IonCardHeader, IonCardTitle, IonText, IonCardContent, IonRow, IonCol } from "@ionic/react";
interface RelatedOrder {
  id_order: string;
  current_state_gardinbus: string;
  date_add: Date;
  id_order_w_country_code: string;
  current_state_gardinbus_name: string;
}
interface IProps {
  related_orders: RelatedOrder[];
  onClick: (idOrder: number) => void;
}
type Props = React.FC<IProps>;
const RelatedOrders: Props = ({
  related_orders,
  onClick
}) => {
  const {
    t
  } = useTranslation();
  return <>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle className={"RelatedOrders-header"}>
            <IonText>{t("RelatedOrders")}</IonText>
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent style={{
        overflowY: "auto",
        padding: "0",
        width: "100vw"
      }}>
          <IonRow style={{
          padding: "1rem"
        }}>
            <IonCol size={"4"}>
              <IonText>{t("OrderId")}</IonText>
            </IonCol>

            <IonCol size={"4"}>
              <IonText>{t("DateAdded")}</IonText>
            </IonCol>

            <IonCol size={"4"}>
              <IonText>{t("CurrentStateGardinBusName")}</IonText>
            </IonCol>
          </IonRow>
          {related_orders.map(order => <IonRow key={order.id_order} onClick={() => onClick(Number(order.id_order))} style={{
          margin: 0,
          padding: "1rem",
          cursor: "pointer"
        }} className="ordered-products-item">
              <IonCol size="4">
                <h2>
                  <b>{order.id_order}</b>
                </h2>
              </IonCol>
              <IonCol size="4">{order.date_add}</IonCol>
              <IonCol size="4">{order.current_state_gardinbus_name}</IonCol>
            </IonRow>)}
        </IonCardContent>
      </IonCard>
    </>;
};
export default RelatedOrders;