import { Epic } from "redux-observable";
import { EMPTY, forkJoin, from, of } from "rxjs";
import {
  filter,
  catchError,
  switchMap,
  map,
  mergeMap,
  defaultIfEmpty,
} from "rxjs/operators";
import { RootAction, RootState, isActionOf } from "typesafe-actions";
import { GB, GBArray, Meeting, Roles } from "../../../models/";
import {
  dateRangeOverlaps,
  datesAreOnSameDay,
  fullName,
} from "../../../helpers/";
import {
  addNewEvent,
  checkDrivingTime,
  deleteEvent,
  editEvent,
  getEvents,
  getSuggestions,
  initCalendars,
} from "./actions";
import {
  addDeviceCalendarEvent,
  editDeviceCalendarEvent,
  removeDeviceCalendarEvent,
} from "../../../helpers/deviceCalendar";
import { isPlatform } from "@ionic/react";
import moment from "moment";
import { API_GB } from "../../Details/redux/epics";
import {
  getAllMeetings,
  getAssignedGBMeetings,
  apiVersionWrapper,
} from "../../../helpers/api";
import { DrivingEvent } from "../../Dashboard/redux/actions";
import { Observable } from "rxjs";
import { CalendarEvent } from "../../../components/Calendar/Calendar";
import { showNotification } from "../../Notification/redux/actions";
import { Suggestion } from "../../../models/apiModels";

const getSuggestionsAPI = (token: string, id_order: number) => {
  const API_URL = `${process.env.REACT_APP_API_URL}getDateRecommendations/${id_order}`;
  return apiVersionWrapper(API_URL, "GET", {
    Authorization: `Bearer ${token}`,
  }).pipe(
    map((response) => {
      return response.response as Suggestion[];
    }),
    catchError((error: Error) => {
      return of(error);
    })
  );
};

const getDistances = (
  token: string,
  meetings: Meeting[],
  new_order: number,
  startTime: Date
) => {
  const observables: Observable<Error | DrivingEvent>[] = [];
  if (meetings.length === 0) {
    return [];
  }
  meetings.filter((m) => m.end! < startTime);
  //sort meetings by date
  meetings.sort(
    (a, b) =>
      new Date(a.start ? a.start : 0).getTime() -
      new Date(b.start ? b.start : 0).getTime()
  );
  const previousMeeting = meetings[meetings.length - 1];
  if (previousMeeting && previousMeeting.start! > startTime) {
    return [];
  }
  if (Number(previousMeeting.orderID) === new_order) {
    const error: Error = new Error(
      "New meeting is for the same order as previous, don't you mean to edit?"
    );
    return [of(error)];
  }
  if (datesAreOnSameDay(previousMeeting.end!, startTime)) {
    const API_URL = `${process.env.REACT_APP_API_URL}getDistance/${
      previousMeeting.orderID
    }/${new_order}/${moment(startTime).format("YYYY-MM-DD HH:mm:ss")}`;
    observables.push(
      apiVersionWrapper(API_URL, "GET", {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      }).pipe(
        map((response) => {
          const drivingEvent: DrivingEvent = {
            ...response.response,
            to_order_id: new_order,
            meeting_start_time: startTime,
            from_order_id: previousMeeting.orderID,
          };

          return drivingEvent;
        }),
        catchError((error: Error) => {
          return of(error);
        })
      )
    );
  }
  return observables.length > 0 ? observables : [];
};

const getRoleID = (role: Roles) => {
  switch (role) {
    case "Admin":
      return 1; //change to some number that means gb+mounter
    case "Booker":
      return 1; //change to some number that means gb+mounter
    case "GBManager":
      return 1;
    case "Mounter":
      return 2;

    default:
      break;
  }
};
const getGBs = (token: string, role: Roles, userID: number) => {
  const API_URL = `${process.env.REACT_APP_API_URL}getUsers/${getRoleID(role)}`; // 1- fitter 2- mounter
  return apiVersionWrapper(API_URL, "GET", {
    Authorization: `Bearer ${token}`,
  }).pipe(
    map((response) => {
      const gbs: API_GB[] = response.response;
      if (role === "GBManager" || role === "Mounter") {
        return gbs.filter((gb) => gb.id_gb_user === userID);
      }
      return gbs;
    }),
    catchError((error: Error) => {
      return of(error);
    })
  );
  //
  // return EMPTY;
};
const addEventAPI = (
  token: string,
  dateStart: string,
  dateEnd: string,
  description: string,
  id_user?: number,
  id_order?: number,
  id_address?: number
) => {
  const API_URL = `${process.env.REACT_APP_API_URL}addEvent`;
  return apiVersionWrapper(
    API_URL,
    "POST",
    {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    JSON.stringify({
      id_user,
      date_start: dateStart,
      date_end: dateEnd,
      id_order: id_order ? id_order : false,
      id_address: id_address ? id_address : false,
      description: description,
    })
  ).pipe(
    map((response) => {
      const event_id: number = response.response;
      return event_id;
    }),
    catchError((error: Error) => {
      return of(error);
    })
  );
};
const updateEventAPI = (
  token: string,
  dateStart: string,
  dateEnd: string,
  description: string,
  id_event: number,
  id_user: number,
  id_address?: number
) => {
  const API_URL = `${process.env.REACT_APP_API_URL}updateEvent/${id_event}`;
  return apiVersionWrapper(
    API_URL,
    "PUT",
    {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    JSON.stringify({
      id_user,
      date_start: dateStart,
      date_end: dateEnd,
      id_address: id_address ? id_address : false,
      description: description,
      measuring: false,
      mounting: false,
    })
  ).pipe(
    map((response) => {
      const ok: boolean = response.response;
      return ok;
    }),
    catchError((error: Error) => {
      return of(error);
    })
  );
};
const deleteEventAPI = (token: string, id_event: number) => {
  const API_URL = `${process.env.REACT_APP_API_URL}deleteEvent/${id_event}`;
  return apiVersionWrapper(API_URL, "DELETE", {
    Authorization: `Bearer ${token}`,
  }).pipe(
    map((response) => {
      const ok: boolean = response.response;
      return ok;
    }),
    catchError((error: Error) => {
      return of(error);
    })
  );
};
// async function loadFromStorage(
//   userRole: Roles,
//   userID: number,
//   calendarName: string
// ): Promise<GBArray | undefined> {
//   const storageGbs = await storageGet<GBArray>("gbs").then((gbs) => gbs);
//   let deviceEvents: IOSEvent[] = [];
//   isPlatform("cordova") &&
//     (await getDeviceCalendarEvent(calendarName).then((events) => {
//       if (events as IOSEvent[]) {
//         deviceEvents = events as IOSEvent[]; // have to putt here the add meeting logic
//       }
//       if (events as string) {
//         const error = events as string;
//         return of(initCalendars.failure(error));
//       }
//     }));
//   if (storageGbs && Object.values(storageGbs).length > 0) {
//     if (userRole === "GBManager" || userRole === "Mounter") {
//       //put the events he has on the device
//       const meetings = Object.values(storageGbs).find((gb) => gb.id === userID)
//         ?.meetings;
//       meetings &&
//         meetings.push(...castDeviceEventsToMeetings(userID, deviceEvents));
//     }
//     return userRole === "Admin" || userRole === "Booker"
//       ? storageGbs
//       : filterGB(storageGbs, userID);
//   }
//   // else{} do api call
// }

function isDrivingEvent(arg: any): arg is DrivingEvent {
  return arg && arg.driving_time && typeof arg.driving_time == "number";
}
export const CheckDrivingEventEpic: Epic<RootAction, RootAction, RootState> = (
  action$
) =>
  action$.pipe(
    filter(isActionOf(checkDrivingTime.request)),
    switchMap((action) => {
      return forkJoin(
        getDistances(
          action.payload.token,
          action.payload.meetings.filter((m) => m.orderID !== 0),
          action.payload.new_order_id,
          action.payload.startTime
        )
      ).pipe(
        defaultIfEmpty(),
        mergeMap((rsp) => {
          if (rsp.length > 0)
            of(
              showNotification({
                color: "warning",
                message: "Couldnt calculate distances",
                type: "Toast",
              })
            );
          const meetingsTimes: DrivingEvent[] = rsp.filter((result) =>
            isDrivingEvent(result)
          ) as DrivingEvent[];
          let flag = false;
          if (!meetingsTimes[0].driving_time) {
            return of(
              showNotification({
                color: "warning",
                message: rsp as unknown as string,
                type: "Toast",
              }),
              checkDrivingTime.failure("Overlapping")
            );
          }
          const newRealStartOfNextMeeting = moment(
            action.payload.startTime
          ).subtract(meetingsTimes[0].driving_time, "minutes");
          if (meetingsTimes.length > 0) {
            //check if doesnt overlap
            action.payload.meetings.forEach((m) => {
              if (
                dateRangeOverlaps(
                  newRealStartOfNextMeeting.toDate(),
                  action.payload.endTime,
                  m.start!,
                  m.end!
                )
              ) {
                flag = true;
              }
            });
          }
          const events: CalendarEvent[] = meetingsTimes.map((r, i) => {
            return {
              id: i + Date.now(),
              start: moment(r.start_time).toDate(),
              end: moment(r.meeting_start_time).toDate(),
              title: "Estimated driving time from last meeting",
            };
          });
          return flag
            ? of(
                showNotification({
                  color: "danger",
                  message:
                    "With driving time this meetings overlaps another, GB would have to leave at " +
                    newRealStartOfNextMeeting.format("HH:mm").toString() +
                    "h",
                  type: "Toast",
                }),
                checkDrivingTime.failure("Overlapping")
              )
            : of(
                checkDrivingTime.success(events),
                showNotification({
                  color: "success",
                  message:
                    "The GB will have to leave at " +
                    newRealStartOfNextMeeting.format("HH:mm").toString() +
                    "h from the previous meeting",
                  type: "Toast",
                })
              );
        }),
        catchError((err: Error) => {
          const errorMsg =
            err.name.toString() === "TypeError"
              ? "New meeting is for the same order as previous, don't you mean to edit?"
              : err.message;
          return err.name.toString() === "TypeError"
            ? of(checkDrivingTime.failure("Overlapping"))
            : of(
                showNotification({
                  color: "danger",
                  message: "Error: " + errorMsg,
                  type: "Toast",
                }),
                checkDrivingTime.failure("Overlapping")
              );
        })
      );
    })
  );

export const initCalendarsEpic: Epic<RootAction, RootAction, RootState> = (
  action$
) =>
  action$.pipe(
    filter(isActionOf(initCalendars.request)),
    // get all users, then in another action assign the meetings to them
    switchMap((action) => {
      const { userRole, token, userID } = action.payload;
      return from(getGBs(token, userRole, userID)).pipe(
        switchMap((response) => {
          if (response as API_GB[]) {
            const rsp: API_GB[] = response as API_GB[];
            const gbArray: GBArray = {};
            rsp.forEach((gb) => {
              const _gb: GB = {
                id: gb.id_gb_user,
                name: fullName(gb.name, gb.lastname),
                color: gb.color,
                meetings: [],
              };
              gbArray[gb.id_gb_user] = _gb;
            });
            return of(
              initCalendars.success({
                response: gbArray,
                dateStart: action.payload.dateStart,
                dateEnd: action.payload.dateEnd,
                user: {
                  id_user: action.payload.userID,
                  token: action.payload.token,
                  userRole: action.payload.userRole,
                },
              })
            );
          }
          return EMPTY;
        }),
        catchError((message: string) => {
          return of(
            initCalendars.failure(message),
            showNotification({
              color: "warning",
              message: "Failed to fetch calendar events",
              type: "Toast",
            })
          );
        })
      );
    })
  );
export const getCalendarMeetingsEpic: Epic<RootAction, RootAction, RootState> =
  (action$) =>
    action$.pipe(
      filter(isActionOf(initCalendars.success)),
      switchMap((act) => {
        return from(
          act.payload.user.userRole === "GBManager" ||
            act.payload.user.userRole === "Mounter"
            ? getAssignedGBMeetings(
                act.payload.user.token,
                act.payload.user.id_user,
                act.payload.dateStart
              )
            : getAllMeetings(act.payload.user.token, act.payload.dateStart)
        ).pipe(
          switchMap((response) => {
            if (response as Meeting[]) {
              return of(getEvents.success(response as Meeting[]));
            }
            return EMPTY;
          }),
          catchError((err) => of(getEvents.failure(err)))
        );
      })
    );

export const newCalendarEventEpic: Epic<RootAction, RootAction, RootState> = (
  action$
) =>
  action$.pipe(
    filter(isActionOf(addNewEvent.request)),
    switchMap((action) => {
      //if user wants to sync with device
      isPlatform("cordova") &&
        addDeviceCalendarEvent(
          action.payload.event,
          action.payload.event.calendar
        );
      const startDate = moment(action.payload.event.start)
        .format("YYYY-MM-DD HH:mm:ss")
        .toString();
      const endDate = moment(action.payload.event.end)
        .format("YYYY-MM-DD HH:mm:ss")
        .toString();
      return from(
        addEventAPI(
          action.payload.token,
          startDate,
          endDate,
          action.payload.event.title!,
          Number(action.payload.operatorId),
          action.payload.event.orderID
            ? Number(action.payload.event.orderID)
            : undefined,
          action.payload.id_address
        )
      ).pipe(
        switchMap((rsp) => {
          if (rsp as Number) {
            const event_id = rsp as number;
            action.payload.event.meetingID = event_id;
          }
          return of(
            addNewEvent.success(action.payload),
            showNotification({
              color: "success",
              message: "New meeting added",
              type: "Toast",
            })
          );
        }),
        catchError((err) => of(addNewEvent.failure(err)))
      );
    }),
    catchError((err) => of(addNewEvent.failure(err)))
  );

export const editCalendarEventEpic: Epic<RootAction, RootAction, RootState> = (
  action$
) =>
  action$.pipe(
    filter(isActionOf(editEvent.request)),
    switchMap((action) => {
      isPlatform("cordova") &&
        editDeviceCalendarEvent(
          action.payload.event,
          {
            ...action.payload.event,
            start: action.payload.newStartDate,
            end: action.payload.newEndDate,
          },
          action.payload.event.calendar
        );

      return from(
        updateEventAPI(
          action.payload.token,
          moment(action.payload.newStartDate).format("YYYY-MM-DD HH:mm:ss"),
          moment(action.payload.newEndDate).format("YYYY-MM-DD HH:mm:ss"),
          action.payload.event.title!,
          Number(action.payload.event.meetingID), //not correct it should be event id
          action.payload.id_user,
          action.payload.id_address
        )
      ).pipe(
        switchMap(() =>
          of(
            editEvent.success(action.payload),
            showNotification({
              color: "success",
              message: "Meeting edited",
              type: "Toast",
            })
          )
        ),
        catchError((err) => of(editEvent.failure(err)))
      );
    }),
    catchError((err) => of(editEvent.failure(err)))
  );

export const deleteCalendarEventEpic: Epic<RootAction, RootAction, RootState> =
  (action$) =>
    action$.pipe(
      filter(isActionOf(deleteEvent.request)),
      switchMap((action) => {
        isPlatform("cordova") &&
          removeDeviceCalendarEvent(action.payload.event);
        return from(
          deleteEventAPI(
            action.payload.token,
            Number(action.payload.event.meetingID)
          )
        ).pipe(
          switchMap(() =>
            of(
              deleteEvent.success(Number(action.payload.event.meetingID)),
              showNotification({
                color: "success",
                message: "Meeting deleted",
                type: "Toast",
              })
            )
          ),
          catchError((err) => of(deleteEvent.failure(err as Error)))
        );
      }),
      catchError((err) => of(deleteEvent.failure(err)))
    );

export const getSuggestionsEpic: Epic<RootAction, RootAction, RootState> = (
  action$
) =>
  action$.pipe(
    filter(isActionOf(getSuggestions.request)),
    switchMap((act) => {
      return from(
        getSuggestionsAPI(act.payload.token, act.payload.order_id)
      ).pipe(
        switchMap((response) => {
          if (response as Suggestion[]) {
            return of(getSuggestions.success(response as Suggestion[]));
          }
          return EMPTY;
        }),
        catchError((err) => of(getSuggestions.failure(err)))
      );
    })
  );
