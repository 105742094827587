import React, { useEffect } from "react";
import Tracker from "@openreplay/tracker";
import { Options } from "@openreplay/tracker/lib/modules/network";
import API from "@openreplay/tracker";
import trackerAssist from "@openreplay/tracker-assist";
interface Props {
  projectKey: string;
  userId: string;
  //   metadata: Object;
  network: (Options & Partial<Options>) | undefined;
}
const OpenReplayTracker = ({
  projectKey,
  network,
  userId
}: Props) => {
  const [tracker, setTracker] = React.useState<API>();
  useEffect(() => {
    const startOpenReplay = async () => {
      if (!tracker && projectKey) {
        try {
          const tracker = new Tracker({
            projectKey: projectKey,
            network: network
          });
          tracker.use(trackerAssist());
          if (!tracker.isActive()) {
            setTracker(tracker);
            await tracker.start({
              userID: userId
              //   metadata: metadata,
            });
          }
        } catch (error) {
          console.error("OpenReplay error:", error);
        }
      }
    };
    startOpenReplay();
  }, []);
};
export default OpenReplayTracker;