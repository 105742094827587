import { ActionType, getType, Reducer } from "typesafe-actions";
import { CalendarEvent } from "../../../components/Calendar/Calendar";
import { Meeting } from "../../../models";

import * as dashboard from "./actions";
import { Notification } from "./actions";
import moment from "moment";

export type DashboardAction = ActionType<typeof dashboard>;

interface DashboardState {
  notifications?: Notification[];
  error?: string;
  isLoading?: boolean;
  meetings: Meeting[];
  calendarDrivingEvents: CalendarEvent[];
}

const initialState: DashboardState = {
  notifications: [],
  error: "",
  isLoading: true,
  meetings: [],
  calendarDrivingEvents: [],
};

export const dashboardReducer: Reducer<DashboardState, DashboardAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getType(dashboard.getMeetingsForTheDay.success):
      return { ...state, meetings: action.payload.meetings };
    case getType(dashboard.getMeetingsForTheDay.request):
      return { ...state, isLoading: true };
    case getType(dashboard.getDrivingEvent.success):
      const drivingEvents = action.payload.map((p) => ({
        ...p,
        end: moment(p.end).subtract(5, "minutes").toDate(),
      }));
      return {
        ...state,
        calendarDrivingEvents: drivingEvents,
        isLoading: false,
      };
    case getType(dashboard.getNotifications.request):
      return { ...state, isLoading: true };
    case getType(dashboard.dismissNotification.request):
      return { ...state, isLoading: true };
    case getType(dashboard.getNotifications.success):
      return {
        ...state,
        notifications: action.payload,
        isLoading: false,
      };
    case getType(dashboard.dismissNotification.success):
      return {
        ...state,
        notifications:
          state.notifications &&
          state.notifications.filter(
            (n) => n.id_gb_user_notification !== action.payload.id_notification
          ),
        isLoading: false,
      };
    default:
      return state;
  }
};
