import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { IonItem, IonLabel, IonInput, IonTextarea, IonSelect, IonSelectOption } from "@ionic/react";
import { isEqual } from "lodash";
export interface ManualProductInterface {
  productName: string;
  customer: string;
  price?: number;
  manufacturer_ID?: number;
}
export interface ManualProductProps {
  disabled: boolean;
  manualProduct?: ManualProductInterface;
  onChange: (manualProduct: ManualProductInterface) => void;
}
const ManualProduct: React.FC<ManualProductProps> = props => {
  const {
    t
  } = useTranslation();
  const {
    disabled,
    onChange,
    manualProduct
  } = props;
  const [ProductName, setProductName] = useState("");
  const [Customer, setCustomer] = useState("");
  const [Price, setPrice] = useState<number | undefined>();
  const [IDManufacturer, setIDManufacturer] = useState<number>();
  useEffect(() => {
    if (manualProduct) {
      setProductName(manualProduct.productName);
      setCustomer(manualProduct.customer);
      setPrice(manualProduct.price);
      setIDManufacturer(manualProduct.manufacturer_ID);
    }
    // I want this to run once on component mount only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const allFilled = useCallback(() => ProductName !== "" && Customer !== "" && !isNaN(Number(Price)) && !isNaN(Number(IDManufacturer)), [ProductName, Customer, Price, IDManufacturer]);
  useEffect(() => {
    if (allFilled()) {
      onChange({
        productName: ProductName,
        customer: Customer,
        price: Number(Price),
        manufacturer_ID: Number(IDManufacturer)
      });
    }
    //I want this effects just to run when allFilled changes, not any of the other states
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allFilled]);
  return <>
      <IonItem>
        <IonLabel position="floating"> {t("Product")}</IonLabel>
        <IonTextarea value={ProductName} disabled={disabled} onIonChange={e => {
        setProductName(e.detail.value!);
      }} />
      </IonItem>
      <IonItem>
        <IonLabel position="floating"> {t("Customer")}</IonLabel>
        <IonTextarea value={Customer} disabled={disabled} onIonChange={e => {
        setCustomer(e.detail.value!);
      }} />
      </IonItem>
      <IonItem>
        <IonLabel position="floating"> {t("Price")}</IonLabel>
        <IonInput value={Price} disabled={disabled} type="number" onIonChange={e => {
        setPrice(Number(e.detail.value!));
      }} />
      </IonItem>
      <IonItem>
        <IonLabel> {t("Manufacturer")}</IonLabel>
        <IonSelect disabled={disabled} compareWith={isEqual} value={IDManufacturer} placeholder={t("SelectManufacturer")} onIonChange={e => {
        setIDManufacturer(e.detail.value!);
      }}>
          {/* TODO this should come from API */}
          <IonSelectOption value={15}>Aluproff</IonSelectOption>
          <IonSelectOption value={18}>Domus</IonSelectOption>
          <IonSelectOption value={17}>EXTRA Manufacturer</IonSelectOption>
          <IonSelectOption value={5}>Faber</IonSelectOption>
          <IonSelectOption value={19}>Germania</IonSelectOption>
          <IonSelectOption value={21}>Kvadrat</IonSelectOption>
          <IonSelectOption value={7}>SE-DA</IonSelectOption>
          <IonSelectOption value={6}>Shadow</IonSelectOption>
          <IonSelectOption value={3}>UNIG BASIC</IonSelectOption>
          <IonSelectOption value={9}>UNIG Standard</IonSelectOption>
          <IonSelectOption value={8}>VELUX</IonSelectOption>
          <IonSelectOption value={4}>VS</IonSelectOption>
          <IonSelectOption value={24}>Acrimo</IonSelectOption>
        </IonSelect>
      </IonItem>
    </>;
};
export default ManualProduct;