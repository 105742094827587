import React, { useEffect, useState } from "react";
import { IonList, IonItem, IonLabel, IonSearchbar } from "@ionic/react";
import { Attr, ChoosenAttributes, ChoosenAttributesWithoutArray, getDNA, getProductAttributePrice } from "../OfferForm/offerCalculation";
import { flattenDeep, uniqBy } from "lodash";
import { allInputs } from "../OfferForm/helpers";
import { DNA_API } from "../../helpers/api";
import { InputObject } from "../InputsMeasures/InputsMeasures";
import { useTranslation } from "react-i18next";
import Modal from "../Modal";
import MultiSelectAttributes from "./MultiSelectAttributes";
export interface Attribute {
  conversion_rate_attribute: number;
  id_attribute: number;
  id_product_attribute: number;
  is_default: number;
  mounting_price: number;
  position: number;
  price_impact: string;
  price_impact_value: number;
  price_impact_value_old: number;
  public_name: string;
}
interface Attributes {
  [key: number]: Attribute[];
}
interface Group {
  attributes: Attributes;
  group_position: number;
  group_public_name: string;
  hidden: number;
  id_attribute_group: number;
  id_bracket: null | number;
  id_shape: number;
  is_color_group: number;
  is_dynamic: number;
  multiselectable_group: number;
  optional: number;
  quantity_enabled: number;
}
export interface Props {
  onError: (error: string, obj?: Object) => void;
  allAttributesGroups: Group[];
  choosenAttributes: ChoosenAttributes[];
  chooseMultipleAttribute: any[];
  hiddenAttributes: {
    id: number;
    group_id: number;
    attr_id: number;
  }[];
  onSetChoosenAttributes: (choosenAttributes: ChoosenAttributes[]) => void;
  onSaveMultiSelectAttributes: ({
    selectedAttributes
  }: {
    [key: string]: number[];
  }) => void;
  onSetHiddenAttributes: (hiddenAttributes: {
    id: number;
    group_id: number;
    attr_id: number;
  }[]) => void;
  disabled?: boolean;
  dna: DNA_API;
  choosenProductID: number;
  inputs: InputObject[];
  basicProductPrice: number;
  productTax: number;
}
const sortByPosition = (a: any, b: any) => {
  const attribute_a: Attribute = a[0];
  const attribute_b: Attribute = b[0];
  if (attribute_a.position < attribute_b.position) {
    return -1;
  }
  if (attribute_a.position > attribute_b.position) {
    return 1;
  }
  return 0;
};
const AttributeList: React.FC<Props> = props => {
  const {
    t
  } = useTranslation();
  const {
    allAttributesGroups,
    choosenAttributes,
    hiddenAttributes,
    onSetChoosenAttributes,
    onSetHiddenAttributes,
    disabled = false,
    choosenProductID,
    dna,
    inputs,
    basicProductPrice,
    productTax,
    onError,
    onSaveMultiSelectAttributes,
    chooseMultipleAttribute
  } = props;
  type SelectedAttribute = {
    [index: number]: {
      value: Attribute;
      isColorGroup: number;
    };
  };
  const [MultipleAttributesRaw, setMultipleAttributesRaw] = useState(chooseMultipleAttribute.map(a => a.attr.id_product_attribute));
  const [SelectedAttributes, setSelectedAttributes] = useState<SelectedAttribute>();
  const [OpenModal, setOpenModal] = useState<{
    open: boolean;
    group?: Group;
    attributes: Attributes;
    isColorGroup: number;
    groupPosition: number;
    is_multiselectable: boolean;
  }>();
  const [SearchedText, setSearchedText] = useState<string>();

  //TODO have to set attributes here
  const [MultipleAttributes, setMultipleAttributes] = useState<Attribute[]>([]);
  useEffect(() => {
    const attributes = allAttributesGroups.map(g => g.attributes);
    const obj: {
      [index: number]: any;
    } = {};
    chooseMultipleAttribute.forEach(c => {
      const index = attributes.findIndex(a => a[c.attr.id_product_attribute]);
      if (index > -1) {
        const _attr = attributes[index][c.attr.id_product_attribute] ?? undefined;
        const foundAttr = _attr && _attr[0];
        if (foundAttr) {
          obj[c.position] = {
            value: {
              conversion_rate_attribute: foundAttr?.conversion_rate_attribute,
              id_attribute: c.attr.id,
              id_product_attribute: c.attr.id_product_attribute,
              is_default: foundAttr?.is_default,
              mounting_price: c.attr.mounting_price,
              position: c.position,
              price_impact: foundAttr?.price_impact,
              price_impact_value: foundAttr?.price_impact_value,
              price_impact_value_old: foundAttr?.price_impact_value_old,
              public_name: foundAttr?.public_name
            },
            isColorGroup: 0
          };
        }
      }
    });
    choosenAttributes.forEach(c => {
      if (!Array.isArray(c.attr)) {
        const attr: Attr = c.attr;
        const index = attributes.findIndex(a => a[attr.id_product_attribute]);
        if (index > -1) {
          const _attr = attributes[index][c.attr.id_product_attribute] ?? undefined;
          const foundAttr = _attr && _attr[0];
          if (foundAttr) {
            obj[c.position] = {
              value: {
                conversion_rate_attribute: foundAttr?.conversion_rate_attribute,
                id_attribute: c.attr.id,
                id_product_attribute: c.attr.id_product_attribute,
                is_default: foundAttr?.is_default,
                mounting_price: c.attr.mounting_price,
                position: c.position,
                price_impact: foundAttr?.price_impact,
                price_impact_value: foundAttr?.price_impact_value,
                price_impact_value_old: foundAttr?.price_impact_value_old,
                public_name: foundAttr?.public_name
              },
              isColorGroup: 0
            };
          }
        }
      }
    });
    setSelectedAttributes(obj);
    // setAttributeGroup(attributeGroup);

    return () => {
      setSelectedAttributes(undefined);
    };
    //run this on changing the choosenAttributes, so that the state has only the attributes that already went throught the DNA
  }, [MultipleAttributesRaw, allAttributesGroups, chooseMultipleAttribute, choosenAttributes]);
  const parseToChoosenAttributes = (array: {
    value: Attribute;
    isColorGroup: number;
  }[]): ChoosenAttributesWithoutArray[] => {
    const result: ChoosenAttributesWithoutArray[] = [];
    array.forEach(a => {
      const attribute = a.value;
      const _group = allAttributesGroups.find(attr_group => {
        return attr_group.attributes[attribute.id_product_attribute];
      });
      const price = _group && attribute ? getProductAttributePrice(basicProductPrice!, attribute.price_impact, Number(attribute.price_impact_value), Number(attribute.conversion_rate_attribute), productTax, Number(inputs.find(i => i.inputType === "height")?.value), _group?.is_color_group ? Math.round(basicProductPrice! * (Number(attribute.price_impact_value) / Number(attribute.conversion_rate_attribute)) * productTax) : 0) : 0;
      _group && result.push({
        position: _group.group_position,
        attr: {
          attr_group: _group.id_attribute_group,
          id: a.value.id_attribute,
          id_product_attribute: a.value.id_product_attribute,
          mounting_price: a.value.mounting_price,
          optional: Boolean(_group.optional),
          price: price && isNaN(price) ? 0 : price!
        }
      });
    });
    return result;
  };
  const modal = <Modal actions={OpenModal?.is_multiselectable ? [{
    name: "Save",
    fn: () => {
      const obj: {
        [key: number]: number[];
      } = {};
      MultipleAttributes.forEach(m => {
        const groupWhereItBelongs = allAttributesGroups.find(group => {
          return Object.keys(group.attributes).find(key => Number(key) === Number(m.id_product_attribute));
        });
        if (obj[groupWhereItBelongs!.id_attribute_group]) {
          obj[groupWhereItBelongs!.id_attribute_group].push(m.id_product_attribute);
        } else {
          obj[groupWhereItBelongs!.id_attribute_group] = [m.id_product_attribute];
        }
      });
      onSaveMultiSelectAttributes(obj);
      setOpenModal({
        open: false,
        attributes: [],
        groupPosition: 0,
        isColorGroup: 0,
        is_multiselectable: true
      });
    },
    type: "button"
  }] : []} isOpen={OpenModal?.open ?? false} onClose={() => {
    setOpenModal({
      open: false,
      attributes: {},
      isColorGroup: 0,
      groupPosition: 0,
      group: undefined,
      is_multiselectable: false
    });
    setSearchedText("");
  }} title="Select Attributes" children={<>
          <IonSearchbar onIonChange={evt => setSearchedText(evt.detail.value)} />
          <IonList>
            {OpenModal && Object.values(OpenModal.attributes).filter(a => {
        const attribute: Attribute = a[0];
        return SearchedText ? attribute.public_name.toLowerCase().includes(SearchedText.toLowerCase()) && !hiddenAttributes.find(h => h.attr_id === a[0].id_attribute) : a && !hiddenAttributes.find(h => h.attr_id === a[0].id_attribute);
      }).sort(sortByPosition).map((a, i) => {
        const attribute: Attribute = a[0];
        const _price = getProductAttributePrice(basicProductPrice!, attribute.price_impact, Number(attribute.price_impact_value), Number(attribute.conversion_rate_attribute), productTax, Number(inputs.find(i => i.inputType === "height")?.value), OpenModal.isColorGroup ? Math.round(basicProductPrice! * (Number(attribute.price_impact_value) / Number(attribute.conversion_rate_attribute)) * productTax) : 0);
        return OpenModal.is_multiselectable ? <MultiSelectAttributes checkedAttributes={MultipleAttributesRaw} price={_price} attribute={attribute} group={OpenModal.groupPosition} onChange={({
          attribute,
          checked,
          group
        }) => {
          if (checked) {
            setMultipleAttributesRaw([...MultipleAttributesRaw, attribute.id_product_attribute]);
            return setMultipleAttributes([...MultipleAttributes, attribute]);
          }
          const newarray = MultipleAttributesRaw.filter(item => item !== attribute.id_product_attribute);
          setMultipleAttributesRaw(newarray);
          return setMultipleAttributes(MultipleAttributes.filter(_attribute => _attribute !== attribute));
        }} /> : <IonItem button key={"modal" + attribute.id_product_attribute + i} onClick={() => {
          //get attributs from state
          const _newChoosenAttributes = {
            ...SelectedAttributes,
            [OpenModal.group!.group_position]: {
              value: attribute,
              isColorGroup: OpenModal.isColorGroup
            }
          };

          //parse them to choosen attribute obj
          const choosenAttributesObj = parseToChoosenAttributes(Object.values(_newChoosenAttributes));

          //run the dna
          const _hiddenAttributes = choosenAttributesObj ? flattenDeep(getDNA(dna, choosenProductID, choosenAttributesObj, ...allInputs(inputs))) : [];
          //filter the attributes against the dna rules
          const newChoosenAttributes = _hiddenAttributes.length > 0 ? choosenAttributesObj.filter(c => _hiddenAttributes.find(n => n !== c.attr.id)) : choosenAttributesObj;

          //Error, if theres more than one attribute with the same position attribute
          const unique = uniqBy(newChoosenAttributes, "position");
          if (unique.length < newChoosenAttributes.length) {
            onError(`Something went wrong with the DNA. `, {
              ...unique,
              ...inputs
            });
          }

          //call the functions to pass to the parent the obj's
          onSetHiddenAttributes(_hiddenAttributes);
          onSetChoosenAttributes(newChoosenAttributes);

          //reset modal
          setOpenModal({
            attributes: {},
            open: false,
            isColorGroup: 0,
            groupPosition: 0,
            group: undefined,
            is_multiselectable: false
          });
        }}>
                      {attribute.public_name}{" "}
                      {_price ? `(${attribute.price_impact === "-" ? "-" + _price : "+" + _price} kr.)` : null}
                    </IonItem>;
      })}
          </IonList>
        </>} />;

  //in case we have repeated group positions
  allAttributesGroups.forEach((a, i) => {
    const previousGroup = i > 0 ? allAttributesGroups[i - 1] : undefined;
    const nextGroup = allAttributesGroups.length > i + 1 ? allAttributesGroups[i + 1] : undefined;
    const lastElementOfTheArray = allAttributesGroups[allAttributesGroups.length - 1];
    //if the position is the same as the previous and the next, put it at the end
    if (a.group_position === previousGroup?.group_position && a.group_position === nextGroup?.group_position) {
      a.group_position = lastElementOfTheArray.group_position + 1;
    }
    //if its the same as the previous but adding one more wont make it the same as the next, add
    if (previousGroup?.group_position === a.group_position && nextGroup?.group_position !== a.group_position + 1) {
      a.group_position = previousGroup.group_position + 1;
    }
  });
  return <>
      {modal}
      <IonList>
        {allAttributesGroups.map((g, i) => {
        const attribute = SelectedAttributes && SelectedAttributes[g.group_position]?.value;
        const price = attribute ? getProductAttributePrice(basicProductPrice!, attribute.price_impact, Number(attribute.price_impact_value), Number(attribute.conversion_rate_attribute), productTax, Number(inputs.find(i => i.inputType === "height")?.value //array operation
        ), SelectedAttributes && SelectedAttributes[attribute.position]?.isColorGroup ? Math.round(basicProductPrice! * (Number(attribute.price_impact_value) / Number(attribute.conversion_rate_attribute)) * productTax) : 0) : 0;
        return <IonItem button onClick={() => setOpenModal({
          open: true,
          group: g,
          attributes: g.attributes,
          isColorGroup: g.is_color_group,
          groupPosition: g.group_position,
          is_multiselectable: Boolean(g.multiselectable_group)
        })} key={"attr" + g.group_public_name + i} disabled={disabled}>
              <IonLabel key={"labelKey" + i}>
                {!g.optional && "*"}
                {g.group_public_name}
              </IonLabel>
              {}
              {SelectedAttributes && SelectedAttributes[g.group_position] && !MultipleAttributesRaw.includes(SelectedAttributes[g.group_position].value.id_product_attribute) ? <IonLabel key={"selectedLabel" + SelectedAttributes[g.group_position].value.id_attribute}>
                  {SelectedAttributes[g.group_position].value.public_name}
                  {Number(SelectedAttributes[g.group_position].value.price_impact_value) !== 0 ? ` (${attribute.price_impact === "-" ? "-" + price : "+" + price} ${t("Currency")})` //TODO this should come from the API
            : null}
                </IonLabel> : MultipleAttributesRaw.length > 0 && Object.keys(g.attributes).find(g => {
            return MultipleAttributesRaw.includes(Number(g));
          }) && <IonLabel>Multiple values </IonLabel>}
            </IonItem>;
      })}
      </IonList>
    </>;
};
export default React.memo(AttributeList);