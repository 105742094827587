import { createAsyncAction, createAction } from "typesafe-actions";
import { CalendarEvent } from "../../../components/Calendar/Calendar";
import { GBArray, Meeting, Roles } from "../../../models";
import { Suggestion } from "../../../models/apiModels";

export const initCalendars = createAsyncAction(
  "calendars/INIT_CALENDARS_REQUEST",
  "calendars/INIT_CALENDARS_SUCCESS",
  "calendars/INIT_CALENDARS_FAILURE"
)<
  {
    userRole: Roles;
    userID: number;
    calendarName: string;
    token: string;
    dateStart: Date | string;
    dateEnd: Date | string;
  },
  {
    response: GBArray;
    dateStart: Date | string;
    dateEnd: Date | string;
    user: { id_user: number; token: string; userRole: Roles };
  },
  Error | string
>();

export const getEvents = createAsyncAction(
  "calendars/GET_EVENTS_REQUEST",
  "calendars/GET_EVENTS_SUCCESS",
  "calendars/GET_EVENTS_FAILURE"
)<{ token: string; id_user: number }, Meeting[], Error | string>();
// Add event to calendar

export const addNewEvent = createAsyncAction(
  "calendars/NEW_EVENT_REQUEST",
  "calendars/NEW_EVENT_SUCCESS",
  "calendars/NEW_EVENT_FAILURE",
  "calendars/NEW_EVENT_CANCEL"
)<
  {
    operatorId: number;
    event: Meeting;
    token: string;
    id_address: number;
    postcode: number;
    fullName: string;
  },
  { operatorId: number; event: Meeting; postcode: number; fullName: string },
  Error | string
>();

// Edit event to calendar (onDrag also)
export const editEvent = createAsyncAction(
  "calendars/EDIT_EVENT_REQUEST",
  "calendars/EDIT_EVENT_SUCCESS",
  "calendars/EDIT_EVENT_FAILURE"
)<
  {
    newStartDate: Date;
    newEndDate: Date;
    event: Meeting;
    token: string;
    id_user: number;
    id_address?: number;
  },
  { newStartDate: Date; newEndDate: Date; event: Meeting }, //supposedly the api return the new event so newstartdate and end will be just on request
  Error | string
>();

// Delete event to calendar
export const deleteEvent = createAsyncAction(
  "calendars/DELETE_EVENT_REQUEST",
  "calendars/DELETE_EVENT_SUCCESS",
  "calendars/DELETE_EVENT_FAILURE"
)<{ token: string; event: Meeting }, number, Error | string>();

// Filter events
export const filterCalendars = createAction(
  "calendars/FILTER_CALENDAR"
)<number>();
export const clearFilterCalendars = createAction(
  "calendars/CLEAR_FILTER_CALENDAR"
)<void>();
// export const rangeChange = createAsyncAction(
//   "calendars/RANGE_CHANGE_REQUEST",
//   "calendars/RANGE_CHANGE_SUCCESS",
//   "calendars/RANGE_CHANGE_FAILURE"
// )<{ startDate: Date, endDate: Date }, void, void>();

// Check if event with driving time doenst overlap
export const checkDrivingTime = createAsyncAction(
  "calendars/DRIVING_TIME_REQUEST",
  "calendars/DRIVING_TIME_SUCCESS",
  "calendars/DRIVING_TIME_FAILURE"
)<
  {
    token: string;
    meetings: Meeting[];
    new_order_id: number;
    startTime: Date;
    endTime: Date;
  },
  CalendarEvent[],
  Error | string
>();

export const getSuggestions = createAsyncAction(
  "calendars/GET_SUGGESTIONS_REQUEST",
  "calendars/GET_SUGGESTIONS_SUCCESS",
  "calendars/GET_SUGGESTIONS_FAILURE"
)<{ token: string; order_id: number }, Suggestion[], Error | string>();

export const clearSuggestions = createAction(
  "calendars/CLEAR_SUGGESTIONS"
)<void>();
