import { random, isNumber } from "lodash";
import { Epic } from "redux-observable";
import { EMPTY, from, of, concat } from "rxjs";
import { filter, catchError, switchMap, map } from "rxjs/operators";
import { RootAction, RootState, isActionOf } from "typesafe-actions";
import {
  ApiGetLeads,
  getMountersAPI,
  Mounter_API,
  ApiGetLeadsFromTimestamp,
  apiVersionWrapper,
} from "../../../helpers/api";
import { API_LEADS_PREVIEW } from "../../../models/apiModels";

import { showNotification } from "../../Notification/redux/actions";
import {
  addCustomer,
  addNewCustomer,
  getAPILeads,
  getMounters,
  resendOffer,
  getAPILeadsByTimestamp,
  filterLead,
  updateAssignedGB,
} from "./actions";
import { storageSet } from "../../../helpers";
import { filterGB } from "../../Details/redux/actions";

const addCustomerAPI = (
  token: string,
  name: string,
  surname: string,
  email: string,
  phone: number,
  company: string,
  cvr_ean: number | string,
  street: string,
  postcode: string,
  city: string,
  country: number
) => {
  const API_URL = `${process.env.REACT_APP_API_URL}addCustomer`;
  return apiVersionWrapper(
    API_URL,
    "POST",
    {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    JSON.stringify({
      name,
      surname,
      email,
      phone,
      company,
      cvr_ean,
      street,
      postcode,
      city,
      country,
    })
  ).pipe(
    map((response) => {
      const newOrderId: number = response.response;
      return newOrderId;
    }),
    catchError((error: Error) => {
      return of(error);
    })
  );
};

const resendOfferAPI = (token: string, id_order: number) => {
  const API_URL = `${process.env.REACT_APP_API_URL}resendOffer/${id_order}`;
  return apiVersionWrapper(API_URL, "GET", {
    Authorization: `Bearer ${token}`,
  }).pipe(
    map((response) => {
      const allOk: boolean = response.response;

      return allOk;
    }),
    catchError((error: Error) => {
      return of(error);
    })
  );
};

const updateAssignedGBAPI = (
  token: string,
  id_order: number,
  id_fitter: number
) => {
  const API_URL = `${process.env.REACT_APP_API_URL}updateAssignedGB/${id_order}/${id_fitter}`;
  return apiVersionWrapper(API_URL, "POST", {
    Authorization: `Bearer ${token}`,
  }).pipe(
    map((response) => {
      const allOk: boolean = response.response;

      return allOk;
    }),
    catchError((error: Error) => {
      return of(error);
    })
  );
};
// const pouchDb = initLeadsDB();
// const loadLeadsFromStorage = async (): Promise<API_LEADS_PREVIEW[]> => {
//   //if not check if it has something in the storag

//   const leads = await pouchDb
//     .allDocs({
//       include_docs: true,
//       attachments: true,
//     })
//     .then((rsp: any) => {
//       const _rsp = rsp as any;
//       return _rsp.total_rows > 0
//         ? _rsp.rows[_rsp.total_rows - 1].doc.orders
//         : null;
//     })
//     .catch((err: any) => {
//       console.log(err);
//     });

//   // const leads = (await storageGet<API_LEADS[]>("orders")
//   //   .then((rsp) => rsp)
//   //   .catch((err) => err)) as API_LEADS[];

//   if (!leads || Object.entries(leads).length === 0) {
//     return [];
//   }
//   return leads;
//   // return [];
// };

export const updateAssignedGBEpic: Epic<RootAction, RootAction, RootState> = (
  action$
) =>
  action$.pipe(
    filter(isActionOf(updateAssignedGB.request)),
    switchMap((action) => {
      return from(
        updateAssignedGBAPI(
          action.payload.token,
          action.payload.id_order,
          action.payload.id_fitter
        )
      ).pipe(
        switchMap((response) => {
          if (response) {
            return of(updateAssignedGB.success({ ...action.payload }));
          }
          return EMPTY;
        }),
        catchError((err) => of(updateAssignedGB.failure(err)))
      );
    })
  );

export const ResendOfferEpic: Epic<RootAction, RootAction, RootState> = (
  action$
) =>
  action$.pipe(
    filter(isActionOf(resendOffer.request)),
    switchMap((action) => {
      return from(
        resendOfferAPI(action.payload.token, action.payload.id_order)
      ).pipe(
        switchMap(() => of(resendOffer.success(true))),
        catchError((err) => of(resendOffer.failure(err))),
        catchError((err) => of(resendOffer.failure(err)))
      );
    })
  );

export const AddCustomerEpic: Epic<RootAction, RootAction, RootState> = (
  action$
) =>
  action$.pipe(
    filter(isActionOf(addCustomer.request)),
    switchMap((action) => {
      const { customer, token } = action.payload;
      return from(
        addCustomerAPI(
          token,
          customer.name,
          customer.surname,
          customer.email,
          customer.phone,
          customer.company,
          customer.cvr_ean,
          customer.street,
          customer.postcode,
          customer.city,
          customer.country
        )
      ).pipe(
        switchMap((rsp) => {
          if (isNumber(rsp)) {
            return concat(
              of(
                addCustomer.success({
                  order_id: rsp as number,
                  customer: {
                    email: customer.email,
                    firstname: customer.name,
                    lastname: customer.surname,
                    id_customer: random(0, 9999),
                    phone: customer.phone.toString(),
                  },
                  fitter: {
                    first_name: action.payload.fitter.name,
                    id_fitter: action.payload.fitter.id,
                    last_name: "",
                  },
                })
              ),

              of(
                showNotification({
                  color: "success",
                  message: "Customer added successfully",
                  type: "Toast",
                })
              )
            );
          }
          return EMPTY;
        }),
        catchError((err) =>
          of(showNotification({ color: "danger", message: err, type: "Toast" }))
        )
      );
    })
  );

export const getAPILeadsEpic: Epic<RootAction, RootAction, RootState> = (
  action$
) =>
  action$.pipe(
    filter(isActionOf(getAPILeads.request)),
    switchMap((action) => {
      return from(ApiGetLeads(action.payload.token)).pipe(
        switchMap((response) => {
          if (response as API_LEADS_PREVIEW[]) {
            const leads = response as API_LEADS_PREVIEW[];
            storageSet<Date>("timeStamp", new Date());
            return of(getAPILeads.success(leads));
          }
          return EMPTY;
        }),
        catchError((err) => of(getAPILeads.failure(err)))
      );
    })
  );

export const getAPILeadsByTimeStampEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$) =>
  action$.pipe(
    filter(isActionOf(getAPILeadsByTimestamp.request)),
    switchMap((action) => {
      return from(
        ApiGetLeadsFromTimestamp(
          action.payload.user.token,
          action.payload.timeStamp
          // moment("2022-01-16 09:00:00").toDate()
        )
      ).pipe(
        switchMap((response) => {
          if (response as API_LEADS_PREVIEW[]) {
            const leads = response as API_LEADS_PREVIEW[];
            if (leads && Object.values(leads).length > 0) {
              storageSet<Date>("timeStamp", new Date());
            }
            const observables: any[] = [getAPILeadsByTimestamp.success(leads)];
            if (Object.values(leads).length > 0) {
              observables.push(
                showNotification({
                  color: "light",
                  message: "New leads loaded: " + Object.values(leads).length,
                  type: "Toast",
                })
              );
            }
            return of(...observables);
          }
          return EMPTY;
        }),
        catchError((err) => of(getAPILeadsByTimestamp.failure(err)))
      );
    })
  );

// export const getStorageLeadsEpic: Epic<RootAction, RootAction, RootState> = (
//   action$
// ) =>
//   action$.pipe(
//     filter(isActionOf(getStorageLeads.request)),
//     switchMap((action) => {
//       return from(loadLeadsFromStorage()).pipe(
//         switchMap((leads) => {
//           if (Object.values(leads).length === 0) {
//             return of(getAPILeads.request(action.payload));
//           } else {
//             return of(getStorageLeads.success(leads));
//           }
//         }),
//         catchError((err) =>
//           concat(
//             of(getStorageLeads.failure(err)),
//             of(
//               getAPILeads.request({
//                 token: action.payload.token,
//                 role: action.payload.role,
//                 name: action.payload.name,
//                 userID: action.payload.userID,
//               })
//             )
//           )
//         ),
//         // takeUntil(action$.ofType(getStorageLeads.cancel)),
//         catchError((err) => of(getStorageLeads.failure(err)))
//       );
//     })
//   );

// export const assignLeadEpic: Epic<RootAction, RootAction, RootState> = (
//   action$
// ) =>
//   action$.pipe(
//     filter(isActionOf(assignLead.request)),
//     switchMap(() => {
//       return from(loadFromStorage(act.payload)).pipe(
//         map((response) => initDetails.success(response!)),
//         catchError((message: string) => of(initDetails.failure(message)))
//       );
//       return of(assignLead.success(dummyLeads[0]));
//     }),
//     catchError((err) => of(assignLead.failure(err)))
//   );

export const newCustomerEpic: Epic<RootAction, RootAction, RootState> = (
  action$
) =>
  action$.pipe(
    filter(isActionOf(addNewCustomer.request)),
    switchMap(() => {
      return of(addNewCustomer.success(true));
    }),
    catchError((err) => of(addNewCustomer.failure(err)))
  );

export const getMountersEpic: Epic<RootAction, RootAction, RootState> = (
  action$
) =>
  action$.pipe(
    filter(isActionOf(getMounters.request)),
    switchMap((action) => {
      return from(
        getMountersAPI(action.payload.token).pipe(
          switchMap((mounters) => {
            if (mounters as Mounter_API[]) {
              return of(getMounters.success(mounters as Mounter_API[]));
            }
            return EMPTY;
          }),
          catchError((err) => of(getMounters.failure(err)))
        )
      );
    }),
    catchError((err) => of(getMounters.failure(err)))
  );

export const updateFilterLeads: Epic<RootAction, RootAction, RootState> = (
  action$
) =>
  action$.pipe(
    filter(isActionOf(filterGB)),
    switchMap((action) => of(filterLead(action.payload.newGB)))
  );
