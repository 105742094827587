import React, { useRef } from "react";
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import "./SettingsPage.css";
import { RouteComponentProps } from "react-router";
import { SettingsFeature } from "../../features";
import { logOutOutline, saveOutline } from "ionicons/icons";
import { SaveAction } from "../../features/Settings/SettingsFeature";
import { useDispatch } from "react-redux";
import { logoutAction } from "../../features/Login/redux/actions";
interface Props extends RouteComponentProps {
  pageName: string;
}
const SettingsPage: React.FC<Props> = props => {
  const {
    pageName
  } = props;
  const childRef = useRef<SaveAction>();
  const dispatch = useDispatch();
  return <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{pageName}</IonTitle>
          <IonButtons slot={"end"}>
            <IonButton onClick={() => childRef.current && childRef.current.onSave()}>
              <IonIcon slot="icon-only" icon={saveOutline} />
            </IonButton>
            <IonButton onClick={() => {
            dispatch(logoutAction.request());
            props.history.push("/login");
          }}>
              <IonIcon slot="icon-only" icon={logOutOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{pageName}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <SettingsFeature ref={childRef} />
      </IonContent>
    </IonPage>;
};
export default SettingsPage;