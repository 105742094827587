import { ActionType, getType, Reducer } from "typesafe-actions";
import * as details from "./actions";
import * as leads from "../../Leads/redux/actions";
import {
  CustomerResponseOptions,
  GB,
  GBArray,
  Meeting,
  OrderDetail,
} from "../../../models/";
import { cloneDeep, orderBy } from "lodash";
import {
  fullName,
  generateRandomID,
  storageGet,
  storageSet,
} from "../../../helpers";
import { PLAIN_OFFER } from "../../../models/apiModels";
import moment from "moment";
// import { initLeadsDB } from "../../../helpers/db";

export type DetailsAction = ActionType<typeof details | typeof leads>;
interface DetailsState {
  order_info: OrderDetail | undefined;
  gbs: GBArray | undefined;
  assignedGb: GB | null;
  error?: string;
  isLoading?: boolean;
  customerOptions?: CustomerResponseOptions[];
  related_orders: {
    id_order: string;
    current_state_gardinbus: string;
    date_add: Date;
    id_order_w_country_code: string;
    current_state_gardinbus_name: string;
  }[];
  all_events: any[];
}

const initialState: DetailsState = {
  order_info: undefined,
  error: undefined,
  assignedGb: null,
  gbs: undefined,
  all_events: [],
  related_orders: [],
};

// const pouchDb = initLeadsDB();

// const updatePouch = (
//   orderid: number,
//   newMeasuringDate: string,
//   newMesauringEndDate?: string
// ) => {
//   pouchDb
//     .get("leads")
//     .then(function (doc: any) {
//       const orders: any = doc.orders;
//       orders[orderid].gardinbus_measuring_date = newMeasuringDate;
//       orders[orderid].gardinbus_measuring_date_end =
//         newMesauringEndDate ?? null;
//       return pouchDb.put({
//         _id: "leads",
//         _rev: doc._rev,
//         ...orders,
//       });
//     })
//     .then(function () {
//       // handle response
//     })
//     .catch(function () {
//       // if it doesnt have create
//       // return pouchDb.put({
//       //   _id: "leads",
//       //   ...newRows,
//       // });
//     });
// };

// const objectToSave = async () => {
//   const order = storageGet("ordersDetails")
//     .then((response) => {
//       return response as OrderDetail[];
//     })
//     .catch((err) => err as Error);
//   return order;
// };
// export const storageOrders = async () => {
//   const order = storageGet("orders")
//     .then((response) => {
//       return response as Order[];
//     })
//     .catch((err) => err as Error);
//   return order;
// };

export const detailsReducer: Reducer<DetailsState, DetailsAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getType(details.getAllEvents.success):
      return {
        ...state,
        all_events: action.payload,
      };
    case getType(details.filterGB):
      return {
        ...state,
        gbs: action.payload.newGB,
      };
    case getType(details.getCustomerResponseOption.success):
      return {
        ...state,
        customerOptions: action.payload,
      };
    case getType(leads.updateAssignedGB.success):
      const newAssignedGBAfterUpdating = state.gbs[action.payload.id_fitter];
      newAssignedGBAfterUpdating.assignedToThisOrder = true;
      return {
        ...state,
        assignedGb: newAssignedGBAfterUpdating,
      };
    case getType(details.getOrderDetailAction.request):
      return {
        ...state,
        isLoading: true,
      };
    case getType(details.getOfflineDetail.success):
      return {
        ...state,
        order_info: action.payload.orderDetail,
        gbs: action.payload.gbs,
        assignedGb: action.payload.assignedGb,
      };
    case getType(details.getOfflineDetail.failure):
      return {
        ...state,
        isLoading: false,
      };
    case getType(details.getOrderDetailAction.failure):
      return {
        ...state,
        isLoading: false,
      };
    case getType(details.getOrderDetailAction.success):
      storageSet("offers", {
        [action.payload.response.orderDetail.order_id]:
          action.payload.response.orderDetail.offer,
      });
      storageGet("draftOffers")
        .then((rsp: any) => {
          if (rsp) {
            const offer = rsp[
              action.payload.response.orderDetail.order_id
            ] as PLAIN_OFFER;
            offer.isDraft = true;
            const draftOffer = {
              id_gardinbus_offer: 0,
              fields_serialized: "",
              date_offer_created: moment()
                .format("YYYY-MM-DD HH:mm:ss")
                .toString(),
              sent: 0,
              id_order_measurement: 0,
              plain_offer: { ...offer },
              cart_link: "",
            };
            action.payload.response.orderDetail.offer?.push(draftOffer);
            action.payload.response.orderDetail.offer?.sort(
              (a, b) =>
                new Date(b.date_offer_created).getTime() -
                new Date(a.date_offer_created).getTime()
            );
          }
        })
        .catch((err) => console.log(err));
      return {
        ...state,
        order_info: action.payload.response.orderDetail,
        assignedGb: action.payload.response.assignedGb,
        related_orders: action.payload.response.related_orders,
        isLoading: false,
      };
    case getType(details.getGBSAction.success):
      const gbsAPI = action.payload;
      const gbArray: GBArray = {};
      gbsAPI.forEach((gb) => {
        const _gb: GB = {
          id: gb.id_gb_user,
          name: fullName(gb.name, gb.lastname),
          color: gb.color,
          assignedToThisOrder:
            state.order_info?.assignedGBID === gb.id_gb_user ? true : false,
          isSelected:
            state.order_info?.assignedGBID === gb.id_gb_user ? true : false,
          meetings: [],
        };
        gbArray[gb.id_gb_user] = _gb;
      });

      return {
        ...state,
        gbs: gbArray,
      };
    case getType(details.getEvents.request):
      return {
        ...state,
        isLoading: true,
      };
    case getType(details.getEvents.success):
      const eventsPayload = action.payload;
      const updatedGB = state.gbs;
      const assignedGB = state.assignedGb;
      eventsPayload.length > 0 &&
        eventsPayload.forEach((event) => {
          if (updatedGB && updatedGB[event.operatorID]) {
            if (
              !updatedGB[event.operatorID].meetings.find(
                (m) => m.meetingID === event.meetingID
              )
            ) {
              updatedGB[event.operatorID].meetings.push(event);
            }
            updatedGB[event.operatorID].isSelected = true;
            if (
              updatedGB[event.operatorID].id === state.order_info?.assignedGBID
            ) {
              updatedGB[event.operatorID].assignedToThisOrder = true;
            }
          }
        });

      let orderWithLastMeeting = cloneDeep(state.order_info);
      if (orderWithLastMeeting) {
        const sortedByDate = orderBy(
          eventsPayload,
          (event: Meeting) => {
            return event.start;
          },
          ["desc"]
        );

        orderWithLastMeeting.meeting = sortedByDate.find(
          (e) => e.orderID === state.order_info?.order_id
        );
      }
      if (assignedGB) {
        assignedGB.meetings = eventsPayload;
      }
      return {
        ...state,
        gbs: updatedGB,
        isLoading: false,
        order_info: orderWithLastMeeting,
        assignedGb: assignedGB,
      };
    case getType(details.initDetails.success):
      const { gbs } = action.payload;
      // if (action.payload.assignedGb) {
      //   const { assignedGb } = action.payload;
      //   gbs[assignedGb.id].isSelected = true;
      //   gbs[assignedGb.id].assignedToThisOrder = true;
      // }
      return {
        ...state,
        order_info: action.payload.orderDetail
          ? action.payload.orderDetail
          : undefined,
        gbs,
        assignedGb: action.payload.assignedGb
          ? action.payload.assignedGb
          : null,
      };
    case getType(details.showError):
      return {
        ...state,
        error: action.payload,
      };
    case getType(details.hideCalendar):
      const filteredGBs: GBArray = { ...state.gbs! };
      const index = action.payload.toString();
      filteredGBs[index].isSelected = false;
      return {
        ...state,
        isLoading: false,
        gbs: filteredGBs,
      };
    case getType(details.filterCalendars.success):
      // const filteredGBs: GBArray = { ...state.gbs! };
      // const index = action.payload.toString();
      // filteredGBs[index].isSelected = !filteredGBs[index].isSelected;
      return {
        ...state,
        isLoading: false,
        gbs: action.payload.gbs,
      };
    case getType(details.addNewEvent.success):
      const withNewEventGBs: GBArray = { ...state.gbs };
      const assignedGBCopy = state.assignedGb;
      const gbWithNewEvent = withNewEventGBs[action.payload.operatorId];
      const newEvent: Meeting = {
        ...action.payload.event,
        operatorID: action.payload.operatorId,
      };
      const orderInfoCopy = cloneDeep(state.order_info);
      let existingOrder = gbWithNewEvent.meetings.findIndex(
        (m) => m.orderID === Number(newEvent.orderID)
      );
      if (existingOrder !== -1) {
        gbWithNewEvent.meetings[existingOrder] = newEvent;
      } else {
        gbWithNewEvent.meetings.push(newEvent);
      }
      gbWithNewEvent.isSelected = true;
      gbWithNewEvent.assignedToThisOrder = true;
      if (action.payload.event.orderID) {
        orderInfoCopy!.meeting = newEvent;
      }
      // // save to pouch db
      // newEvent.orderID &&
      //   newEvent.start &&
      //   updatePouch(
      //     Number(newEvent.orderID),
      //     moment(newEvent.start).format("YYYY-MM-DD HH:mm:ss"),
      //     moment(newEvent.end).format("YYYY-MM-DD HH:mm:ss")
      //   );
      return {
        ...state,
        isLoading: false,
        gbs: withNewEventGBs,
        order_info: orderInfoCopy,
        assignedGb: action.payload.event.orderID
          ? gbWithNewEvent
          : assignedGBCopy,
      };
    // case getType(details.editEvent.success):
    //   const editedEventGBs: GBArray = { ...state.gbs };
    //   const gbWithEditedEvent = editedEventGBs[action.payload.event.operatorID];
    //   const oldMeetingIndex = gbWithEditedEvent.meetings.findIndex(
    //     (m) => m.meetingID === action.payload.event.meetingID
    //   );
    //   const editedEvent: Meeting = {
    //     ...action.payload.event,
    //     start: action.payload.newStartDate,
    //     end: action.payload.newEndDate,
    //   };
    //   gbWithEditedEvent.meetings = gbWithEditedEvent.meetings.filter(
    //     (m) => m !== gbWithEditedEvent.meetings[oldMeetingIndex]
    //   );
    //   gbWithEditedEvent.meetings.push(editedEvent);
    //   // if (action.payload.event.orderID) {
    //   //   objectToSave().then((r) => {
    //   //     if (r as OrderDetail[]) {
    //   //       const orders = r as OrderDetail[];
    //   //       orders.find(
    //   //         (order) => order.order_id === Number(action.payload.event.orderID)
    //   //       )!.meeting = editedEvent;
    //   //       storageSet<OrderDetail[]>("ordersDetails", orders!);
    //   //     }
    //   //   });
    //   // }
    //   // storageSet<GBArray>("gbs", editedEventGBs);
    //   return {
    //     ...state,
    //     isLoading: false,
    //     gbs: editedEventGBs,
    //   };
    case getType(details.deleteEvent.success):
      const GBsCopy: GBArray = { ...state.gbs };
      const GBIndex = action.payload.id_user;
      GBsCopy[GBIndex].meetings = GBsCopy[GBIndex].meetings.filter(
        (m) => m.meetingID !== action.payload.meetingID
      );
      // objectToSave().then((r) => {
      //   if (r as OrderDetail[]) {
      //     const orders = r as OrderDetail[];
      //     orders.filter(
      //       (order) => order.meeting?.meetingID !== action.payload.meetingID
      //     );
      //     const orderIndex = orders.findIndex(
      //       (o) => o.meeting?.meetingID === action.payload.meetingID
      //     );
      //     if (orderIndex !== -1) {
      //       orders[orderIndex].meeting = undefined;
      //     }
      //     storageSet<OrderDetail[]>("ordersDetails", orders!);
      //   }
      // });
      // storageSet<GBArray>("gbs", GBsCopy);
      const newOrderInfo3 = state.order_info;
      newOrderInfo3!.meeting = undefined;
      return {
        ...state,
        isLoading: false,
        gbs: GBsCopy,
        assignedGb: GBsCopy[GBIndex],
        order_info: newOrderInfo3,
      };
    case getType(details.reAssignToGB.success): //change to sucess when we got api
      const GBsWithNewAssigned: GBArray = { ...state.gbs };
      const oldAssignedGbIndex = Object.values(GBsWithNewAssigned).findIndex(
        (gb) => gb.assignedToThisOrder
      );
      const oldAssignedGb = GBsWithNewAssigned[oldAssignedGbIndex];
      const newAssignedGB = GBsWithNewAssigned[action.payload];
      newAssignedGB.isSelected = true;
      newAssignedGB.assignedToThisOrder = true;

      oldAssignedGb.isSelected = false;
      oldAssignedGb.assignedToThisOrder = false;
      const meeting = oldAssignedGb.meetings.find(
        (m) => m.meetingID === state.order_info?.meeting?.meetingID
      );
      meeting!.operatorID = newAssignedGB.id;
      oldAssignedGb.meetings = oldAssignedGb.meetings.filter(
        (m) => m.meetingID !== meeting?.meetingID
      ); // remove from old assigned
      newAssignedGB.meetings.push(meeting!); // add meeting to new assigned
      // objectToSave().then((r) => {
      //   if (r as OrderDetail[]) {
      //     const orders = r as OrderDetail[];
      //     orders.find(
      //       (order) => order.meeting?.meetingID === meeting?.meetingID
      //     )!.meeting = meeting;
      //     storageSet<OrderDetail[]>("ordersDetails", orders!);
      //   }
      // });
      // storageSet<GBArray>("gbs", GBsWithNewAssigned);

      return {
        ...state,
        gbs: GBsWithNewAssigned,
        assignedGb: newAssignedGB,
      };
    case getType(details.assignToGB.success): //change to sucess when we got api
      const GBsWithNewAssigned2: GBArray = { ...state.gbs };
      const newAssignedGB2 = GBsWithNewAssigned2[action.payload.gbID];
      newAssignedGB2.isSelected = true;
      newAssignedGB2.assignedToThisOrder = true;
      const newMeeting = {
        operatorID: action.payload.gbID,
        start: action.payload.startDate,
        end: action.payload.endDate,
        title: action.payload.title,
        meetingID: generateRandomID(),
        notify: false,
      };
      newAssignedGB2.meetings.push(newMeeting); // add meeting to new assigned
      const newOrderInfo2 = state.order_info;
      newOrderInfo2!.meeting = newMeeting;
      storageSet<GBArray>("gbs", GBsWithNewAssigned2);
      // objectToSave().then((r) => {
      //   if (r as OrderDetail[]) {
      //     const orders = r as OrderDetail[];
      //     orders.find(
      //       (order) => order.order_id === newOrderInfo2?.order_id
      //     )!.meeting = newMeeting;
      //     storageSet<OrderDetail[]>("ordersDetails", orders!);
      //   }
      // });
      // storageSet<OrderDetail>("ordersDetails", newOrderInfo2!);
      return {
        ...state,
        gbs: GBsWithNewAssigned2,
        assignedGb: newAssignedGB2,
        order_info: newOrderInfo2,
      };
    case getType(details.addNewOrderNote.success): //change to sucess when we got api
      const newOrderInfo = state.order_info;
      const newNotes = newOrderInfo!.notes!;
      newNotes.unshift(action.payload);
      // objectToSave().then((r) => {
      //   if (r as OrderDetail[]) {
      //     const orders = r as OrderDetail[];
      //     orders.find(
      //       (order) => order.order_id === state.order_info?.order_id
      //     )!.notes = newNotes;
      //     storageSet<OrderDetail[]>("ordersDetails", orders!);
      //   }
      // });
      // storageSet<OrderDetail>("ordersDetails", newOrderInfo!);
      return {
        ...state,
        order_info: newOrderInfo,
      };
    case getType(details.editNewOrderNote.success): //change to sucess when we got api
      const OrderInfoWithEditedNote = cloneDeep(state.order_info);
      const editedNote = OrderInfoWithEditedNote!.notes!.findIndex(
        (n) => n.id === action.payload.id
      );
      OrderInfoWithEditedNote!.notes![editedNote] = action.payload;
      // storageSet<OrderDetail>("ordersDetails", OrderInfoWithEditedNote!);
      // objectToSave().then((r) => {
      //   if (r as OrderDetail[]) {
      //     const orders = r as OrderDetail[];
      //     orders.find(
      //       (order) => order.order_id === state.order_info?.order_id
      //     )!.notes = action.payload;
      //     storageSet<OrderDetail[]>("ordersDetails", orders!);
      //   }
      // });
      return {
        ...state,
        order_info: OrderInfoWithEditedNote,
      };
    case getType(details.deleteNewOrderNote.success): //change to sucess when we got api
      const OrderInfoWithoutNote = cloneDeep(state.order_info);
      OrderInfoWithoutNote!.notes = OrderInfoWithoutNote!.notes?.filter(
        (n) => n.id !== action.payload
      );
      // objectToSave().then((r) => {
      //   if (r as OrderDetail[]) {
      //     const orders = r as OrderDetail[];
      //     orders.find(
      //       (order) => order.order_id === state.order_info?.order_id
      //     )!.notes = newNoteState;
      //     storageSet<OrderDetail[]>("ordersDetails", orders!);
      //   }
      // });
      // storageSet<OrderDetail>("ordersDetails", OrderInfoWithoutNote!);
      return {
        ...state,
        order_info: OrderInfoWithoutNote,
      };
    case getType(details.editCustomer.request): // change to sucess
      const orderInfoToChange = cloneDeep(state.order_info);
      orderInfoToChange!.customer = action.payload.customer;
      // objectToSave().then((r) => {
      //   if (r as OrderDetail[]) {
      //     const orders = r as OrderDetail[];
      //     orders.find(
      //       (order) => order.order_id === state.order_info?.order_id
      //     )!.customer = action.payload.customer;
      //     storageSet<OrderDetail[]>("ordersDetails", orders!);
      //   }
      // });
      // storageOrders().then((r) => {
      //   if (r as Order[]) {
      //     const orders = r as Order[];
      //     orders.find(
      //       (order) => order.order_id === state.order_info?.order_id
      //     )!.customer = action.payload.customer;
      //     storageSet<Order[]>("orders", orders!);
      //   }
      // });
      return {
        ...state,
        order_info: orderInfoToChange,
      };
    case getType(details.cancelOrder.success):
      // storageOrders().then((r) => {
      //   if (r as Order[]) {
      //     const orders = r as Order[];
      //     orders.find((order) => order.order_id === orderID)!.status =
      //       "Canceled";
      //     storageSet<Order[]>("orders", orders!);
      //   }
      // });
      return {
        ...state,
        // maybe add order status to order detail?
      };
    default:
      return state;
  }
};
