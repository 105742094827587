import React, { useEffect } from "react";
import { GB, OrderDetail, Roles } from "../../models";
import moment from "moment";
import "./OrderStatus.css";
import { IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonCol, IonRow, IonLabel, IonText, IonInput, IonItem } from "@ionic/react";
import { useTranslation } from "react-i18next";
interface Props {
  assignedGb: GB | null;
  orderInfo: OrderDetail;
  role: Roles;
}
const OrderStatus: React.FC<Props> = props => {
  const {
    assignedGb,
    orderInfo,
    role
  } = props;
  const {
    t
  } = useTranslation();
  return <>
      {assignedGb ? <IonCard id={"orderStatus-card"}>
          <IonCardHeader>
            <IonCardTitle style={{
          display: "flex",
          justifyContent: "space-between"
        }}>
              <IonText style={{
            display: "flex",
            alignItems: "center"
          }}>
                {t("AssignedTo")}
                {role === "Admin" || role === "Booker" ? " " + assignedGb.name : +" " + t("Me")}
                {/* <IonButton fill={"clear"}>
                  <IonIcon icon={addOutline} />
                  {orderInfo.meeting ? "Re-Schedule" : "Schedule"}
                 </IonButton> */}
              </IonText>
              {/* <IonButton fill={"clear"}>
                <IonIcon icon={calendarOutline} />
               </IonButton> */}
            </IonCardTitle>
            <IonCardSubtitle>
              {orderInfo?.meeting?.title ? <span>
                  {t("MeetingTitle")} {orderInfo?.meeting?.title}
                </span> : <span>{t("NoMeeting")}</span>}
            </IonCardSubtitle>
          </IonCardHeader>
          {orderInfo?.meeting && <IonCardContent>
              <IonRow>
                <IonCol>
                  <IonLabel position={"stacked"}>{t("StartTime")}</IonLabel>
                  {orderInfo?.meeting?.start ? <IonItem>
                      {moment(orderInfo?.meeting?.start).format("DD-MM-yyyy HH:mm").toString()}
                    </IonItem> : <IonInput style={{
              paddingLeft: 0
            }} value={(t("NotDefined") as string)} disabled></IonInput>}
                </IonCol>
                <IonCol>
                  <IonLabel position={"stacked"}>{t("EndTime")}</IonLabel>
                  {orderInfo?.meeting?.end ? <IonItem>
                      {moment(orderInfo?.meeting?.end).format("DD-MM-yyyy HH:mm").toString()}
                    </IonItem> : <IonInput style={{
              paddingLeft: 0
            }} value={(t("NotDefined") as string)} disabled></IonInput>}
                </IonCol>
              </IonRow>
            </IonCardContent>}
        </IonCard> : <IonCard id={"orderStatus-card-empty"}>
          <IonCardHeader>
            <IonCardTitle>{t("NotAssigned")}</IonCardTitle>
          </IonCardHeader>
        </IonCard>}
    </>;
};
export default OrderStatus;