import React from "react";
import { IonFab, IonFabButton, IonIcon, IonFabList } from "@ionic/react";
import { ellipsisVertical, refreshOutline, refreshCircleOutline } from "ionicons/icons";
import { storageGet } from "../../../helpers";
interface Props {
  onFullRefresh: () => void;
  onPartialRefresh: (timestamp: Date) => void;
}
export default function RefreshButtons(props: Props) {
  return <IonFab vertical="bottom" horizontal="end" slot="fixed">
      <IonFabButton>
        <IonIcon icon={ellipsisVertical}></IonIcon>
      </IonFabButton>
      <IonFabList side="top">
        <IonFabButton data-desc="Full Refresh" onClick={() => props.onFullRefresh()}>
          <IonIcon icon={refreshOutline} />
        </IonFabButton>
        <IonFabButton data-desc="Update leads" onClick={() => storageGet<Date>("timeStamp").then(timeStamp => props.onPartialRefresh(timeStamp ?? new Date()))}>
          <IonIcon icon={refreshCircleOutline} />
        </IonFabButton>
      </IonFabList>
    </IonFab>;
}