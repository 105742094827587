import React from "react";
import { IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonIcon, IonButton } from "@ionic/react";
import "./NotificationCard.css";
import { Color } from "../../models/models";
import { useTranslation } from "react-i18next";
interface IProps {
  title: string;
  link?: string;
  color?: Color;
  icon?: string;
  onDismiss: (id: number) => void;
  id: number;
}
type Props = React.FC<IProps>;
const NotificationCard: Props = props => {
  const {
    id,
    title,
    link,
    color,
    icon,
    onDismiss
  } = props;
  const {
    t
  } = useTranslation();
  return <IonCard routerLink={link} className={"widget"}>
      <IonCardHeader>
        <IonCardTitle style={{
        padding: 0
      }}>
          <IonIcon color={color} className={"icon-widget"} src={icon} />
        </IonCardTitle>
        <IonCardSubtitle> {title}</IonCardSubtitle>
      </IonCardHeader>
      <IonCardContent className={"card-content"}>
        <IonButton fill={"clear"} onClick={() => onDismiss(id)}>
          {t("Dismiss")}
        </IonButton>
        <IonButton fill={"outline"} routerLink={link}>
          {t("Go")}
        </IonButton>
      </IonCardContent>
    </IonCard>;
};
export default NotificationCard;