import React from "react";
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonRow } from "@ionic/react";
import { RouteComponentProps } from "react-router";
import { DashboardFeature } from "../../features/";
interface Props extends RouteComponentProps {
  pageName: string;
}
const DashboardPage: React.FC<Props> = props => {
  const {
    pageName
  } = props;
  return <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{pageName}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{pageName}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonRow>
          <DashboardFeature {...props} />
        </IonRow>
      </IonContent>
    </IonPage>;
};
export default DashboardPage;