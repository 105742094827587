import React from "react";
import { IonItem, IonLabel, IonList, IonSkeletonText, IonThumbnail } from "@ionic/react";
const Skeletons: React.FC<{}> = () => {
  return <>
      <IonList style={{
      marginTop: "2rem"
    }}>
        <IonItem style={{
        marginBottom: "2rem"
      }}>
          <IonThumbnail style={{
          width: "10rem",
          height: "6rem"
        }} slot="start">
            <IonSkeletonText animated />
          </IonThumbnail>
          <IonLabel>
            <h3>
              <IonSkeletonText animated style={{
              width: "50%",
              height: "1rem"
            }} />
            </h3>
            <p>
              <IonSkeletonText animated style={{
              width: "80%",
              height: "1rem"
            }} />
            </p>
            <p>
              <IonSkeletonText animated style={{
              width: "60%",
              height: "1rem"
            }} />
            </p>
            <p>
              <IonSkeletonText animated style={{
              width: "90%",
              height: "1rem"
            }} />
            </p>
          </IonLabel>
        </IonItem>
        <IonItem style={{
        marginBottom: "2rem"
      }}>
          <IonThumbnail style={{
          width: "10rem",
          height: "6rem"
        }} slot="start">
            <IonSkeletonText animated />
          </IonThumbnail>
          <IonLabel>
            <h3>
              <IonSkeletonText animated style={{
              width: "50%",
              height: "1rem"
            }} />
            </h3>
            <p>
              <IonSkeletonText animated style={{
              width: "80%",
              height: "1rem"
            }} />
            </p>
            <p>
              <IonSkeletonText animated style={{
              width: "60%",
              height: "1rem"
            }} />
            </p>
            <p>
              <IonSkeletonText animated style={{
              width: "90%",
              height: "1rem"
            }} />
            </p>
          </IonLabel>
        </IonItem>
        <IonItem style={{
        marginBottom: "2rem"
      }}>
          <IonThumbnail style={{
          width: "10rem",
          height: "6rem"
        }} slot="start">
            <IonSkeletonText animated />
          </IonThumbnail>
          <IonLabel>
            <h3>
              <IonSkeletonText animated style={{
              width: "50%",
              height: "1rem"
            }} />
            </h3>
            <p>
              <IonSkeletonText animated style={{
              width: "80%",
              height: "1rem"
            }} />
            </p>
            <p>
              <IonSkeletonText animated style={{
              width: "60%",
              height: "1rem"
            }} />
            </p>
            <p>
              <IonSkeletonText animated style={{
              width: "90%",
              height: "1rem"
            }} />
            </p>
          </IonLabel>
        </IonItem>
        <IonItem style={{
        marginBottom: "2rem"
      }}>
          <IonThumbnail style={{
          width: "10rem",
          height: "6rem"
        }} slot="start">
            <IonSkeletonText animated />
          </IonThumbnail>
          <IonLabel>
            <h3>
              <IonSkeletonText animated style={{
              width: "50%",
              height: "1rem"
            }} />
            </h3>
            <p>
              <IonSkeletonText animated style={{
              width: "80%",
              height: "1rem"
            }} />
            </p>
            <p>
              <IonSkeletonText animated style={{
              width: "60%",
              height: "1rem"
            }} />
            </p>
            <p>
              <IonSkeletonText animated style={{
              width: "90%",
              height: "1rem"
            }} />
            </p>
          </IonLabel>
        </IonItem>
        <IonItem style={{
        marginBottom: "2rem"
      }}>
          <IonThumbnail style={{
          width: "10rem",
          height: "6rem"
        }} slot="start">
            <IonSkeletonText animated />
          </IonThumbnail>
          <IonLabel>
            <h3>
              <IonSkeletonText animated style={{
              width: "50%",
              height: "1rem"
            }} />
            </h3>
            <p>
              <IonSkeletonText animated style={{
              width: "80%",
              height: "1rem"
            }} />
            </p>
            <p>
              <IonSkeletonText animated style={{
              width: "60%",
              height: "1rem"
            }} />
            </p>
            <p>
              <IonSkeletonText animated style={{
              width: "90%",
              height: "1rem"
            }} />
            </p>
          </IonLabel>
        </IonItem>
        <IonItem style={{
        marginBottom: "2rem"
      }}>
          <IonThumbnail style={{
          width: "10rem",
          height: "6rem"
        }} slot="start">
            <IonSkeletonText animated />
          </IonThumbnail>
          <IonLabel>
            <h3>
              <IonSkeletonText animated style={{
              width: "50%",
              height: "1rem"
            }} />
            </h3>
            <p>
              <IonSkeletonText animated style={{
              width: "80%",
              height: "1rem"
            }} />
            </p>
            <p>
              <IonSkeletonText animated style={{
              width: "60%",
              height: "1rem"
            }} />
            </p>
            <p>
              <IonSkeletonText animated style={{
              width: "90%",
              height: "1rem"
            }} />
            </p>
          </IonLabel>
        </IonItem>
      </IonList>
    </>;
};
export default Skeletons;