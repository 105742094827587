import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { IonInput, IonItem, IonLabel, IonList, IonSelect, IonSelectOption } from "@ionic/react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Mounter_API } from "../../helpers/api";
export interface MounterForm {
  id: number;
  startDate?: Date;
}
export interface SaveMounterForm {
  onSave: () => {
    assignedMounter: MounterForm;
  };
}
const AssignMounterForm = forwardRef((props: {
  mounters: Mounter_API[];
  onError: (error: string) => void;
}, ref) => {
  const {
    t
  } = useTranslation();
  const {
    mounters,
    onError
  } = props;
  const newMounterForm = useRef<HTMLFormElement>(null);
  useImperativeHandle(ref, (): SaveMounterForm => ({
    onSave() {
      // Form data
      const id = newMounterForm.current!["mounter"].value;
      const startDate = newMounterForm.current!["startDate"].value;
      return {
        assignedMounter: {
          id,
          startDate
        }
      };
    }
  }));
  return <>
        <form ref={newMounterForm}>
          <IonList>
            <IonItem>
              <IonLabel position={"floating"}>{t("StartingDate")}</IonLabel>
              <IonInput name={"startDate"} type="datetime-local" placeholder={t("StartingDatePlaceholder")} onIonChange={event => moment(event.detail.value) < moment() && onError(t("MeetingInPastError"))} />
            </IonItem>
            <IonItem>
              <IonLabel position={"floating"}>{t("Mounter")}</IonLabel>
              <IonSelect name={"mounter"} placeholder={t("MounterPlaceholder")} value={mounters.length === 1 ? mounters[0].id_gb_user : undefined}>
                {mounters.map(mounter => <IonSelectOption value={mounter.id_gb_user}>
                    {mounter.name}
                  </IonSelectOption>)}
              </IonSelect>
            </IonItem>
          </IonList>
        </form>
      </>;
});
export default AssignMounterForm;