import React from "react";
import { IonRow, IonCol, IonLabel, IonItem, IonInput } from "@ionic/react";
import { useTranslation } from "react-i18next";
interface Props {
  ExtraMountingPrice: number;
  newExtraMountingPrice: (mountingPrice: number) => void;
  ExtraDiscount: number;
  newExtraDiscount: (mountingPrice: number) => void;
  EstimatedMounting: string;
  newEstimatedMounting: (mountingPrice: string) => void;
  IsFitterAdrress: boolean;
  newIsFitterAdrress: (mountingPrice: boolean) => void;
  GeneralComment?: string;
  newGeneralComment: (generalComment: string) => void;
}
const SpecialConditions: React.FC<Props> = props => {
  const {
    t
  } = useTranslation();
  const {
    ExtraMountingPrice,
    ExtraDiscount,
    newExtraMountingPrice,
    newExtraDiscount
  } = props;
  return <>
      <IonRow>
        <IonCol style={{
        padding: 0
      }} size={"6"}>
          <IonItem>
            <IonLabel className="ion-text-wrap" position={"floating"}>
              {t("ExtraMounting")} ({"Currency"}){" "}
              {/* TODO should be from API */}
            </IonLabel>
            <IonInput type={"number"} value={ExtraMountingPrice} onIonChange={e => newExtraMountingPrice(Number(e.detail.value!))} />
          </IonItem>
        </IonCol>
        <IonCol style={{
        padding: 0
      }} size={"6"}>
          <IonItem>
            <IonLabel className="ion-text-wrap" position={"floating"}>
              {t("ExtraDiscount")} ({"Currency"}){" "}
              {/* TODO should be from API */}
            </IonLabel>
            <IonInput type={"number"} value={ExtraDiscount} onIonChange={e => newExtraDiscount(Number(e.detail.value!))} />
          </IonItem>
        </IonCol>
      </IonRow>
    </>;
};
export default SpecialConditions;