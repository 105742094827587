import { IonButton, IonItem, IonLabel } from "@ionic/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "typesafe-actions";
import { Toast } from "../../components";
import { clearNotification } from "./redux/actions";
const NotificationFeatureComponent: React.FC<{}> = () => {
  const isOpen = useSelector((state: RootState) => state.notificationReducer.isOpen);
  const message = useSelector((state: RootState) => state.notificationReducer.message);
  const color = useSelector((state: RootState) => state.notificationReducer.color);
  const type = useSelector((state: RootState) => state.notificationReducer.type);
  const obj = useSelector((state: RootState) => state.notificationReducer.obj);
  const dispatch = useDispatch();
  const {
    t
  } = useTranslation();
  const renderTopNotification = () => {
    return <IonItem color={color ? String(color) : undefined}>
        <IonLabel>{message}</IonLabel>
        {obj && <IonButton onClick={() => navigator.clipboard.writeText(JSON.stringify(obj))} fill="outline" slot="end" color="light">
            {t("Copy to Clipboard")}
          </IonButton>}
        <IonButton onClick={() => dispatch(clearNotification())} fill="clear" slot="end" color="light">
          {t("Dismiss")}
        </IonButton>
      </IonItem>;
  };
  const renderNotificationType = () => {
    switch (type) {
      case "Google":
      case "Top Bar":
        return renderTopNotification();
      case "Toast":
        return <Toast open={isOpen} onClose={() => dispatch(clearNotification())} message={message ? message : ""} color={color ? color : undefined} obj={obj} />;
      case "Notification List":
        return null;
      //TBD
      default:
        break;
    }
  };
  return <>{isOpen && renderNotificationType()}</>;
};
const NotificationFeature = React.memo(NotificationFeatureComponent);
export default NotificationFeature;