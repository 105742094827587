import React from "react";
import { IonContent, IonPage } from "@ionic/react";
import "./LoginPage.css";
import { RouteComponentProps } from "react-router";
import { LoginFeature } from "../../features/";
const LoginPage: React.FC<RouteComponentProps> = () => {
  return <IonPage>
      <IonContent>
        <LoginFeature />
      </IonContent>
    </IonPage>;
};
export default LoginPage;