import React, { useEffect, useState } from "react";
import "./Login.css";
import { IonInput, IonItem, IonLabel, IonButton } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { Keychain } from "@awesome-cordova-plugins/keychain";
import { Capacitor } from "@capacitor/core";
interface LoginInputs {
  email: string;
  password: string;
  credentialsChange: boolean;
}
interface Props {
  loginHandler: (formInputs: LoginInputs) => void;
  forgotHandler: () => void;
}
const Login: React.FC<Props> = ({
  loginHandler,
  forgotHandler
}) => {
  const {
    t
  } = useTranslation();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [originalCredentials, setOriginalCredentails] = useState<{
    email: string;
    password: string;
  }>();
  async function fetchUser() {
    return await Keychain.getJson("user-login").then(val => {
      setOriginalCredentails({
        email: val.email,
        password: val.password
      });
      setEmail(val.email);
      setPassword(val.password);
    }).catch((err: Error) => console.log("error", err.message));
  }
  useEffect(() => {
    if (Capacitor.getPlatform() === "ios") {
      fetchUser();
    }
  }, []);
  return <>
      <div id="wrapper">
        <div id="login">
          <h1>{t("LoginTitle")}</h1>
          <IonItem onSubmit={() => {
          const credentialsChange = email !== originalCredentials?.email || password !== originalCredentials.password;
          loginHandler({
            email,
            password,
            credentialsChange
          });
        }}>
            <IonLabel position="floating">{t("EmailLabel")}</IonLabel>
            <IonInput autocomplete="email" value={email} onIonChange={e => setEmail(e.detail.value!)} type="email"></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel position="floating">{t("PasswordLabel")}</IonLabel>
            <IonInput autocomplete="current-password" value={password} onIonChange={e => setPassword(e.detail.value!)} type="password"></IonInput>
          </IonItem>
          <IonButton type="submit" onClick={() => {
          const credentialsChange = email !== originalCredentials?.email || password !== originalCredentials.password;
          loginHandler({
            email,
            password,
            credentialsChange
          });
        }}>
            {t("SubmitLogin")}
          </IonButton>
          {/* <IonButton fill="clear" onClick={forgotHandler}>
            {t("ForgotLogin")}
           </IonButton> */}
        </div>
      </div>
    </>;
};
export default Login;