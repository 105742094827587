import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { IonItem, IonRow, IonCol, IonLabel, IonInput, IonButton, IonIcon, IonSelect, IonSelectOption } from "@ionic/react";
import "./InputsMeasures.css";
import { calculatorOutline } from "ionicons/icons";
import { isEmpty } from "lodash";
export type Input = "width" | "height" | "height_2" | "width_2" | "width_a" | "width_b" | "width_c" | "partclassic_width" | "partclassic_height";
export type InputLimits = {
  min_width?: number;
  max_width?: number;
  min_height?: number;
  max_height?: number;
  max_width_2?: number;
  max_height_2?: number;
  min_height_2?: number;
  max_width_a?: number;
  min_width_a?: number;
  max_width_b?: number;
  min_width_b?: number;
  max_width_c?: number;
  min_width_c?: number;
};
export type InputObject = {
  inputType: Input;
  value?: number | string;
  limits?: InputLimits;
};
export interface InputMeasuresProps {
  inputs: InputObject[];
  disabled: boolean;
  quantity: number;
  editable: boolean;
  hideCalculateButton?: boolean;
  select?: {
    product_type: string;
    options: number[];
  };
  onChangeQuantity: (quantity: number) => void;
  onChangeInput: (obj: InputObject) => void;
  onClickCalculate: (hasError: boolean) => void;
  onClickEditMeasure: () => void;
  onError: (error: string) => void;
}
const max_measure = (inputType: string, limits?: InputLimits): number | undefined => {
  if (limits) {
    switch (inputType) {
      case "width":
        return limits.max_width!;
      case "height":
        return limits.max_height!;
      case "height_2":
        return limits.max_height_2!;
      case "width_2":
        return limits.max_width_2!;
      case "width_a":
        return limits.max_width_a!;
      case "width_b":
        return limits.max_width_b!;
      case "width_c":
        return limits.max_width_c!;
      default:
        return undefined;
    }
  }
  return undefined;
};
const min_measure = (inputType: string, limits?: InputLimits): number | undefined => {
  if (limits) {
    switch (inputType) {
      case "width":
        return limits.min_width!;
      case "height":
        return limits.min_height!;
      case "height_2":
        return limits.min_height_2!;
      case "width_2":
        return 0;
      case "width_a":
        return limits.min_width_a!;
      case "width_b":
        return limits.min_width_b!;
      case "width_c":
        return limits.min_width_c!;
      default:
        return undefined;
    }
  }
  return undefined;
};
const inputIsNotA_B_C = (inputType: string) => {
  return inputType === "width_a" || inputType === "width_b" || inputType === "width_c";
};
const betweenLimits = (number: number, input: InputObject) => {
  if (inputIsNotA_B_C(input.inputType)) return;
  const _min_measure = min_measure(input.inputType, input.limits);
  let _max_measure = max_measure(input.inputType, input.limits);
  if (Number(_max_measure) === 0) {
    _max_measure = 99999;
  }
  if (_min_measure !== undefined && _max_measure !== undefined) {
    return number <= _max_measure && number >= _min_measure;
  }
};
const InputsMeasures: React.FC<InputMeasuresProps> = props => {
  const {
    t
  } = useTranslation();
  const {
    inputs,
    disabled,
    quantity,
    editable,
    hideCalculateButton = false,
    select,
    onChangeQuantity,
    onChangeInput,
    onClickCalculate,
    onClickEditMeasure,
    onError
  } = props;
  const [Error, setError] = useState<{
    inputType: Input;
    isValid: boolean;
    value?: number | string;
  }[]>([]);
  const hasMinAndIsNotFullfiled = (input: InputObject) => {
    switch (input.inputType) {
      case "height":
        return input.limits?.min_height && !input.value ? true : input.limits?.min_height && input.value ? input.limits?.min_height > input.value ? true : false : false;
      // case "height_2":
      //   return input.limits?.min_height_2 && !input.value
      //     ? true
      //     : input.limits?.min_height_2 && input.value
      //     ? input.limits?.min_height_2 > input.value
      //       ? true
      //       : false
      //     : false;

      case "width":
        return input.limits?.min_width && !input.value ? true : input.limits?.min_width && input.value ? input.limits?.min_width > input.value ? true : false : false;
      // case "width_a":
      //   return input.limits?.min_width_a && !input.value
      //     ? true
      //     : input.limits?.min_width_a && input.value
      //     ? input.limits?.min_width_a > input.value
      //       ? true
      //       : false
      //     : false;
      // case "width_b":
      //   return input.limits?.min_width_b && !input.value
      //     ? true
      //     : input.limits?.min_width_b && input.value
      //     ? input.limits?.min_width_b > input.value
      //       ? true
      //       : false
      //     : false;
      // case "width_c":
      //   return input.limits?.min_width_c && !input.value
      //     ? true
      //     : input.limits?.min_width_c && input.value
      //     ? input.limits?.min_width_c > input.value
      //       ? true
      //       : false
      //     : false;
      default:
        return false;
    }
  };
  const showError = (inputs: InputObject[]) => {
    inputs.forEach(input => {
      if (isEmpty(input.limits)) return;
      if (inputIsNotA_B_C(input.inputType)) return;
      if (hasMinAndIsNotFullfiled(input)) return onError("Not all required measures are fullfiled!");
      if (!input.value && "min_" + input.inputType) if (input.value && !betweenLimits(Number(input.value), input)) {
        const max = max_measure(input.inputType, input.limits);
        const min = min_measure(input.inputType, input.limits);
        input.inputType !== "height_2" && setError(prev => {
          const index = prev.findIndex(i => i.inputType === input.inputType);
          if (index > -1) {
            prev[index].isValid = false;
            prev[index].value = input.value;
            return [...prev];
          } else {
            return [...prev, {
              inputType: input.inputType,
              isValid: false,
              value: input.value ? Number(input.value) : undefined
            }];
          }
        });
        max && input.inputType !== "height_2" && onError(t(`The value of ${input.inputType} must be between ${min}cm ${max && max > 0 ? `and ${max}cm` : null}`));
        !max && input.inputType !== "height_2" && onError(t(`The value of ${input.inputType} must be minimum ${min}cm`));
      } else if (input.value && betweenLimits(Number(input.value), input)) {
        setError(prev => {
          const index = prev.findIndex(i => i.inputType === input.inputType);
          if (index !== -1) {
            prev[index].isValid = true;
            prev[index].value = undefined;
            return [...prev];
          } else {
            return [...prev, {
              inputType: input.inputType,
              isValid: true,
              value: undefined
            }];
          }
        });
      }
    });
  };
  const printLimits = (limits: InputLimits, type: string): string => {
    const validLimits: number[] = [];
    const definedLimits = Object.keys(limits).filter(l => l !== undefined).map((key, i) => {
      return {
        value: Object.values(limits)[i],
        limit: key
      };
    });
    definedLimits.forEach(l => {
      if (l.limit === "min_" + type || l.limit === "max_" + type) {
        validLimits.push(l.value ?? 0);
      }
    });
    return validLimits[1] !== undefined ? `${validLimits[0]} - ${validLimits[1]} ` : `Max - ${validLimits[0]}`;
  };
  const type = select?.product_type === "partclassic_width" ? "width" : "height";
  return <>
      <IonItem disabled={disabled}>
        <IonRow className="align-items-end">
          <IonCol size={"6"}>
            <IonLabel position={"floating"}>{t("Quantity")}</IonLabel>
            <IonInput onIonChange={e => onChangeQuantity(Number(e.detail.value!))} type={"number"} value={quantity} min={"1"} />
          </IonCol>
          <IonCol style={{
          alignItems: "center",
          display: "flex"
        }} size={"6"}>
            <IonButton fill="outline" onClick={() => onChangeQuantity(quantity - 1 > 1 ? quantity - 1 : 1)} className={"spacing-quantity-buttons"}>
              -
            </IonButton>
            <IonButton onClick={() => onChangeQuantity(quantity + 1 > 1 ? quantity + 1 : 1)} fill="outline">
              +
            </IonButton>
          </IonCol>
        </IonRow>
      </IonItem>
      {(select?.product_type === "partclassic_width" || select?.product_type === "partclassic_height") && <IonItem>
          <IonLabel position={"floating"}>
            {t(select?.product_type === "partclassic_width" ? "width" : "height")}
          </IonLabel>
          <IonSelect disabled={disabled} onIonChange={e => {
        onChangeInput({
          inputType: type,
          limits: {},
          value: e.detail.value ? e.detail.value! : undefined
        });
      }} value={select?.product_type === "partclassic_width" ? inputs.find(i => i.inputType === "width")?.value : select?.product_type === "partclassic_height" ? inputs.find(i => i.inputType === "height")?.value : undefined} placeholder={`Select ${type}`}>
            {select.options.map((option, i) => <IonSelectOption key={option + i} value={option}>
                {option}
              </IonSelectOption>)}
          </IonSelect>
        </IonItem>}
      {inputs.filter(input => {
      if (select?.product_type === "partclassic_width") {
        return input.inputType !== "width";
      } else if (select?.product_type === "partclassic_height") {
        return input.inputType !== "height";
      } else {
        return input;
      }
    }).map((input, i) => <IonItem key={input.inputType + i} disabled={disabled} style={!Error.find(e => e.inputType === input.inputType)?.isValid && !inputIsNotA_B_C(input.inputType) && !betweenLimits(Number(input.value) ?? 0, input) && input.value !== undefined ? {
      color: "red"
    } : undefined}>
            <IonLabel position={"floating"}>{t(input.inputType)}</IonLabel>
            <IonInput placeholder={printLimits(input.limits ?? {}, input.inputType)} inputMode={"decimal"}
      // debounce={800}
      onIonChange={e => {
        onChangeInput({
          inputType: input.inputType,
          limits: input.limits,
          value: e.detail.value ? e.detail.value! : undefined
        });
      }}
      // type={"number"}
      min={"0"} value={isNaN(Number(input.value?.toString().replace(/,/g, "."))) ? undefined : input.value?.toString().replace(/,/g, ".")} clearOnEdit />
          </IonItem>)}
      <IonItem lines={"none"}>
        {!disabled ? <IonButton fill={"outline"} className="measure-button"
      // disabled={
      //   Error.filter((e) => e.value).filter((e) => !e.isValid).length > 0
      // }
      onClick={() => {
        //check if the value is a number and if it is between the limits
        showError(inputs);
        onClickCalculate(Error.filter(e => e.value).filter(e => !e.isValid).length > 0);
      }}>
            <IonIcon icon={calculatorOutline} />
            {t("Calculate")}
          </IonButton> : <IonButton fill={"outline"} className="measure-button" onClick={onClickEditMeasure} disabled={!editable}>
            {!hideCalculateButton ? t("Edit Measures") : t("Edit")}
          </IonButton>}
      </IonItem>
    </>;
};
export default InputsMeasures;