import { IonCol, IonRow, IonList, IonItem, IonLabel, IonIcon, IonText, IonTextarea, IonChip } from "@ionic/react";
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState, useCallback } from "react";
import "./OfferForm.css";
import { cloneDeep, flattenDeep, uniq } from "lodash";
import { closeCircle, informationCircleOutline } from "ionicons/icons";
import { ChoosenAttributes, ChoosenAttributesWithoutArray, ChoosenProduct, getAttributeGroups, getDNA, getFinalPrice, getProductBasicPrice, OfferAttributes } from "./offerCalculation";
import { Attributes_API, Configuration_API, DNA_API, PriceList_API, Products_API } from "../../helpers/api";
import { useTranslation } from "react-i18next";
import ModalSelectProduct from "../ModalSelectProduct";
import { readyToBeSent, allInputs, getPrice, saveOffer, getChoosenProduct, initOffer, offerHasMultiselectAttribute } from "./helpers";
import { InputsMeasures } from "..";
import { InputMeasuresProps, Input, InputLimits } from "../InputsMeasures/InputsMeasures";
import ManualProduct from "../ManualProduct";
import AttributeList from "../AttributeList";
export interface IOffer {
  date: Date;
  sent: boolean;
  note: string | null;
  total: number;
}
export interface Offer {
  product_id?: number;
  sent?: number;
  copied?: boolean;
  offer_id: number;
  order_id: number;
  choosen_attributes_id: {
    [index: number]: number | undefined | number[];
  };
  product_attributes_id: {
    [index: number]: number | undefined | number[];
  };
  quantity: number;
  width?: number;
  width_a?: number;
  width_b?: number;
  width_c?: number;
  height?: number;
  width_2?: number;
  height_2?: number;
  comment?: string;
  manual?: {
    production: string;
    customer: string;
    width: number | string;
    price: number | string;
    id_manual_manufacturer: number | string;
  };
}
export type Configs = {
  dna?: DNA_API;
  global_config?: Configuration_API;
  products?: Products_API;
  pricelist?: PriceList_API;
  attributes?: Attributes_API;
};
export interface IPropsOffer {
  onError: (message: string, obj?: Object) => void;
  offer?: Offer;
  saved: boolean;
  editing: boolean;
  configs?: Configs;
  offer_new?: boolean;
  commentMessages: string[];
  readyToSend: (ready: boolean) => void;
  multipleAttributes?: number[];
}
export interface SaveOffer {
  onSave: () => {
    offer: OfferAttributes;
  };
  onGetPrice: () => {
    price: number;
  };
  onCheckIfReadyToSend: () => {
    readyToSend: boolean;
  };
}

// comp
const OfferForm = forwardRef((props: IPropsOffer, ref) => {
  const {
    onError,
    offer,
    saved,
    configs,
    editing,
    offer_new,
    commentMessages,
    readyToSend,
    multipleAttributes
  } = props;
  const {
    t
  } = useTranslation();
  const [EditingMeasures, setEditingMeasures] = useState(false);
  const [Inputs, setInputs] = useState<InputMeasuresProps["inputs"]>([]);
  const [MultipleAttributes, setMultipleAttributes] = useState<{
    [key: number]: number[];
  }>([]);
  useEffect(() => {
    setMultipleAttributes(offerHasMultiselectAttribute(offer));
    return () => {
      setMultipleAttributes([]);
    };
  }, [offer]);
  useImperativeHandle(ref, (): SaveOffer => ({
    onCheckIfReadyToSend: () => {
      return {
        readyToSend: memoReadyToBeSent
      };
    },
    onSave() {
      const finalPrice = getFinalPrice(basicProductPrice!, ((choosenAttributes! as unknown) as ChoosenAttributesWithoutArray[]));
      if (!memoReadyToBeSent) {
        onError(t("ProductNotReadyToBeSent"));
      }
      return saveOffer(choosenProduct!, Inputs!, choosenAttributes!, quantity!, comment!, memoReadyToBeSent!, finalPrice, manualProduct, MultipleAttributes);
    },
    onGetPrice: () => {
      return getPrice(offer!, configs!, choosenProduct!, Inputs!, basicProductPrice!);
    }
  }));
  const product = offer?.product_id ? configs?.products?.products.find(p => p.id_product === offer.product_id) : undefined; //to populate..
  const [choosenProduct, setChoosenProduct] = useState<ChoosenProduct | undefined>(getChoosenProduct(product));
  const [choosenAttributes, setChoosenAttributes] = useState<ChoosenAttributes[]>([]);
  const [hiddenAttributes, setHiddenAttributes] = useState<{
    id: number;
    group_id: number;
    attr_id: number;
  }[]>([]);
  const [modal, setModal] = useState(false);
  const [calculate, setcalculate] = useState<boolean>(false);
  const [quantity, setQuantity] = useState<number>(offer?.quantity ? offer.quantity : 1);
  const [comment, setComment] = useState<string>(offer && offer.comment ? offer.comment : "");
  const [manualProduct, setManualProduct] = useState<{
    production: string;
    customer: string;
    price?: number;
    id_manual_manufacturer?: number;
  }>({
    production: offer && offer.manual ? offer.manual.production : "",
    customer: offer && offer.manual ? offer.manual.customer : "",
    price: offer && offer.manual ? Number(offer.manual.price) : 0,
    id_manual_manufacturer: offer && offer.manual ? Number(offer.manual.id_manual_manufacturer) : 0
  });
  const [basicProductPrice, setBasicProductPrice] = useState<number>();
  const allProducts = configs?.products;
  useEffect(() => {
    if (offer) {
      const {
        basicProductPrice,
        choosenAttributes,
        hiddenAttributes
      } = initOffer(configs!, parseInputs(), offer!, choosenProduct);
      setBasicProductPrice(basicProductPrice);
      setChoosenAttributes(choosenAttributes);
      setHiddenAttributes(hiddenAttributes);
      setcalculate(true);
      readyToSend(readyToBeSent(choosenProduct ? getAttributeGroups(configs?.attributes, choosenProduct.id).filter(g => !hiddenAttributes.find(a => Number(a.group_id) === Number(g.id_attribute_group))) : [], ((choosenAttributes as unknown) as ChoosenAttributesWithoutArray[]), choosenProduct?.id));
    }
    setEditingMeasures(editing);
    //if it's coppied reset widths

    return () => {
      setcalculate(false);
    };
    // I want this to run once on component mount only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const allAttributeGroups = choosenProduct ? getAttributeGroups(configs?.attributes, choosenProduct.id).filter(g => !hiddenAttributes.find(a => Number(a.group_id) === Number(g.id_attribute_group))) : [];
  const memoReadyToBeSent = useMemo(() => readyToBeSent(choosenProduct ? getAttributeGroups(configs?.attributes, choosenProduct.id).filter(g => !hiddenAttributes.find(a => Number(a.group_id) === Number(g.id_attribute_group))) : [], ((choosenAttributes as unknown) as ChoosenAttributesWithoutArray[]), choosenProduct?.id), [choosenAttributes, choosenProduct, configs?.attributes, hiddenAttributes]);
  const parseInputs = useCallback(() => {
    const array: {
      inputType: Input;
      value?: number;
      limits?: InputLimits;
    }[] = [];
    if (choosenProduct) {
      choosenProduct.inputs?.forEach(input => {
        array.push({
          inputType: (input as Input),
          value: undefined,
          limits: choosenProduct.limits ?? undefined
        });
      });
      if (offer) {
        array.forEach(input => {
          const type = input.inputType === "partclassic_height" ? "height" : input.inputType === "partclassic_width" ? "width" : input.inputType;
          if (offer[type]) {
            input.value = Number(offer[type]);
          }
        });
      }
    }
    return array;
  }, [offer, choosenProduct]);
  const handleUpdate = (index: number, val?: number | string, limits?: InputLimits) => {
    const newInputs = [...Inputs];
    newInputs[index].value = val;
    newInputs[index].limits = limits;
    const newPrice = getProductBasicPrice(configs?.products?.products!, configs?.pricelist, choosenProduct!.id, ...allInputs(newInputs));
    if (newPrice !== basicProductPrice) {
      setBasicProductPrice(newPrice);
    }
    setInputs(newInputs);
  };
  useEffect(() => {
    setInputs(parseInputs());
  }, [choosenProduct, parseInputs]);
  const isArrayAndEmpty = (arr: any) => Array.isArray(arr) && arr.length === 0;
  return <>
      {!!!offer_new && <>
          {memoReadyToBeSent && choosenProduct && calculate ? null : <IonText className="required-fields-empty" color={"warning"}>
              {t("RequiredFieldsEmpty")}
            </IonText>}
        </>}
      <ModalSelectProduct allProducts={allProducts ?? null} choosenProduct={choosenProduct ?? null} modal={{
      isOpen: modal,
      onClose: () => setModal(false),
      title: t("SelectProduct")
    }} onSetChoosenProduct={newChoosenProduct => {
      if (newChoosenProduct.price && newChoosenProduct.price === 0) {
        onError("Price product is 0! It is not possible", newChoosenProduct);
      }
      setChoosenProduct(newChoosenProduct);
      setChoosenAttributes([]);
      setMultipleAttributes([]);
      setModal(false);
      if (!choosenProduct?.inputs || choosenProduct?.inputs?.length === 0) {
        setcalculate(true);
      }
    }} />
      <IonRow>
        <IonCol size={"4"}>
          <IonList id={"properties-list"}>
            <IonItem disabled={saved || !EditingMeasures} id={"select-product"} button detail onClick={() => {
            setModal(true);
            setcalculate(false);
          }} className={"pointer"}>
              {!choosenProduct?.name ? <>{t("SelectProduct")}</> : choosenProduct.name}
            </IonItem>

            {
            //partclassic_height
            //partclassic_width
            // If partclassic_width:
            // height should be input.
            // width should be dropdown.
            // If partclassic_height:
            // height should be dropdown.
            // width should be input.
          }
            <InputsMeasures select={{
            options: choosenProduct && choosenProduct.inputs && !isArrayAndEmpty(choosenProduct.inputs) ? choosenProduct?.product_type === "partclassic_width" ? uniq(configs?.pricelist![choosenProduct!.id].filter(c => c.width_to).map(c => c.width_to)) : uniq(configs?.pricelist![choosenProduct!.id].filter(c => c.height_to).map(c => c.height_to)) : [],
            product_type: choosenProduct?.product_type ?? ""
          }} onError={message => {
            onError(message);
          }} hideCalculateButton={choosenProduct?.id === 485} disabled={!EditingMeasures || !choosenProduct} editable={editing} inputs={Inputs} onChangeInput={obj => {
            const index = Inputs.findIndex(i => i.inputType === obj.inputType);
            index > -1 && handleUpdate(index, obj.value, obj.limits);
          }} onChangeQuantity={number => setQuantity(number)} quantity={quantity} onClickEditMeasure={() => {
            setEditingMeasures(true);
          }} onClickCalculate={_hasError => {
            if (_hasError) {
              setChoosenAttributes([]);
              return setMultipleAttributes([]);
            }
            // if (!hasError) {
            const hiddenAttributes = flattenDeep(getDNA(configs?.dna, choosenProduct?.id!, (choosenAttributes as ChoosenAttributesWithoutArray[]), ...allInputs(Inputs)));
            setHiddenAttributes(hiddenAttributes);
            setEditingMeasures(false);
            setcalculate(true);
            choosenAttributes.forEach((a: any) => {
              const noLongerValidAttr = hiddenAttributes.find(h => h.attr_id === a.attr.id);
              if (noLongerValidAttr) {
                const stateCopy = cloneDeep(choosenAttributes);
                setChoosenAttributes(stateCopy.filter((c: any) => c.attr.id !== noLongerValidAttr.attr_id));
              }
            });
          }}
          // }
          />
          </IonList>
        </IonCol>
        <IonCol size={"8"}>
          {
          // I have to select the product, get if it has width,heigh,width2,height2 or not, fill the values of dimensions if it has
          // then i call the attributes groups and do the validations based on the dimensions
        }
          {!calculate && <>
              <IonText className={"flex-center full-height"} color="light">
                <h1>
                  <IonIcon className="margin-right-icon" icon={informationCircleOutline} />
                  {choosenProduct ? t("HelperTextMeasures") : t("HelperTextOffer")}
                </h1>
              </IonText>
            </>}
          <IonList>
            {calculate && choosenProduct && <AttributeList onSaveMultiSelectAttributes={obj => setMultipleAttributes(obj)} chooseMultipleAttribute={(multipleAttributes as any[])} onError={(msg, obj) => onError(msg, obj)} allAttributesGroups={allAttributeGroups} basicProductPrice={basicProductPrice!} choosenAttributes={choosenAttributes} choosenProductID={choosenProduct.id} dna={configs?.dna!} hiddenAttributes={hiddenAttributes} inputs={Inputs} disabled={!editing || EditingMeasures} productTax={choosenProduct.tax} onSetChoosenAttributes={choosenAttributes => {
            setChoosenAttributes(choosenAttributes.filter(c => c !== undefined));
          }} onSetHiddenAttributes={hiddenAttributes => {
            setHiddenAttributes(hiddenAttributes);
          }} />}
            {choosenProduct?.id === 485 && <ManualProduct disabled={false} manualProduct={offer?.manual ? {
            customer: offer.manual.customer,
            manufacturer_ID: Number(offer.manual.id_manual_manufacturer) !== 0 ? Number(offer.manual.id_manual_manufacturer) : undefined,
            price: Number(offer.manual.price),
            productName: offer.manual.production
          } : {
            customer: manualProduct.customer,
            manufacturer_ID: Number(manualProduct.id_manual_manufacturer) !== 0 ? Number(manualProduct.id_manual_manufacturer) : undefined,
            price: Number(manualProduct.price) !== 0 ? Number(manualProduct.price) : undefined,
            productName: manualProduct.production
          }} onChange={manual => {
            setManualProduct({
              production: manual.productName,
              price: manual.price,
              customer: manual.customer,
              id_manual_manufacturer: manual.manufacturer_ID
            });
          }} />}
            <IonItem disabled={saved}>
              <IonLabel position={"floating"}>{t("ProductComment")}</IonLabel>
              <IonTextarea value={comment} onIonChange={e => setComment(e.detail.value!)} />
            </IonItem>
            {commentMessages.length > 0 && <IonItem>
                {commentMessages.map((message, i) => <IonChip key={i}>
                    <IonLabel onClick={() => setComment(comment + message)}>
                      {message.substring(0, 50) + "..."}
                    </IonLabel>
                    <IonIcon onClick={() => setComment(comment.replace(message, ""))} icon={closeCircle}></IonIcon>
                  </IonChip>)}
              </IonItem>}
          </IonList>
        </IonCol>
      </IonRow>
    </>;
});
OfferForm.displayName = "OfferForm";
export default React.memo(OfferForm);