import React, { useEffect, useState } from "react";
import "./NoteList.css";
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonIcon, IonItem, IonLabel, IonList, IonText, IonTextarea } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { add, close, pencil, save, trash } from "ionicons/icons";
import { cloneDeep, isEqual } from "lodash";
import { Note, Roles } from "../../models";
import moment from "moment";
export interface NoteListProps {
  notes: Note[];
  editable: boolean;
  onSetEditable: () => void;
  onSave: (newNotes: Note[]) => void;
  author: {
    name: string;
    id: number;
    role: Roles;
  };
  onDelete: (note_id: number) => void;
}
const NoteList: React.FC<NoteListProps> = props => {
  const {
    t
  } = useTranslation();
  const {
    notes,
    editable,
    onSave,
    onSetEditable,
    author,
    onDelete
  } = props;
  const [newNotes, setNewNotes] = useState<Note[]>([]);
  useEffect(() => {
    setNewNotes(notes);
    return () => {
      setNewNotes([]);
    };
  }, [notes]);
  const handleNewNotes = (index: number, newNote: Note) => {
    const notesCopy: Note[] = cloneDeep(newNotes);
    if (!isEqual(newNote, notesCopy[index]) && !newNote.new) {
      newNote.edited = true;
      newNote.new = false;
    }
    notesCopy[index] = newNote;
    setNewNotes(notesCopy);
  };
  const handleAddNewNote = () => {
    const notesCopy: Note[] = cloneDeep(newNotes);
    if (!editable) {
      onSetEditable();
    }
    notesCopy.unshift({
      id: notesCopy.length + 1,
      text: "",
      author: {
        name: author.name,
        id: author.id
      },
      datetime: new Date(Date.now()),
      new: true
    });
    setNewNotes(notesCopy
    // author.role === "Admin" || author.role === "Booker"
    //   ? notesCopy
    //   : notesCopy.filter((n) => n.author.id === author.id)
    );
  };
  const handleClear = () => {
    setNewNotes(notes);
    onSetEditable();
  };
  return <>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle className={"notes-header"}>
            <span>
              <IonText>{t("Notes")}</IonText>

              {!editable && <IonButton fill={"clear"} onClick={() => handleAddNewNote()}>
                  <IonIcon icon={add} />
                  {t("Add")}
                </IonButton>}
            </span>
            {editable && newNotes.length !== 0 && <span>
                <IonButton fill={"clear"} onClick={handleClear}>
                  <IonIcon icon={close} />
                </IonButton>
                <IonButton fill={"clear"} onClick={() => onSave(newNotes)}>
                  <IonIcon icon={save} />
                </IonButton>
              </span>}
            {!editable && newNotes.length !== 0 && <IonButton fill={"clear"} onClick={onSetEditable}>
                <IonIcon icon={pencil} onClick={onSetEditable} />
              </IonButton>}
          </IonCardTitle>
          {newNotes.length === 0 && !editable && <IonCardSubtitle>{t("NoNotes")}</IonCardSubtitle>}
        </IonCardHeader>
        <IonCardContent id={newNotes.length === 0 ? "noteList-empty" : undefined} className={"ion-no-padding"}>
          {newNotes.length === 0 ? null :
        // <h5>No notes.</h5>
        <IonList id={newNotes.length !== 0 ? "noteList-min-height" : undefined} style={{
          overflowY: "auto"
        }} className={"ion-no-padding"}>
              {newNotes.filter(n => !n.toBeDeleted).map((note, index) => <React.Fragment key={index}>
                    <IonItem>
                      <IonLabel position={"floating"}>
                        {t(note.datetime instanceof Date ? note.datetime.toDateString() : moment(note.datetime).toDate().toDateString())}
                      </IonLabel>
                      <IonTextarea onIonChange={e => handleNewNotes(index, {
                id: note.id,
                author: note.author,
                datetime: note.datetime,
                text: e.detail!.value!,
                new: note.new,
                edited: note.edited,
                toBeDeleted: note.toBeDeleted
              })} value={note.text} readonly={!editable}>
                        {/* {(author.role === "Admin" || author.role === "Booker") && ( */}

                        {/* )} */}
                      </IonTextarea>
                    </IonItem>
                    <span style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: ".2rem 0 .5rem"
            }}>
                      <span className={"author"}>{note.author.name}</span>
                      {note.author.name === author.name && <IonButton disabled={!editable} fill={"clear"} onClick={() => note.id && onDelete(note.id)}>
                          <IonIcon icon={trash} />
                        </IonButton>}
                    </span>
                  </React.Fragment>)}
            </IonList>}
        </IonCardContent>
      </IonCard>
    </>;
};
export default NoteList;