import { Calendar, CalendarOptions } from "@ionic-native/calendar";
import moment from "moment";
import { ICalendar, IOSEvent, Meeting } from "../models";

//
// @Example response from device
//
// [
//   {
//     "id":"29518FD8-839F-4E31-8435-EDA894B23366:BDF75560-595C-47F2-AAF8-971BE9DBD924"
//     ,"title":"Teset",
//     "startDate":"2020-11-13 17:00:00",
//     "endDate":"2020-11-13 18:00:00",
//     "calendar":"UNIG",
//     "lastModifiedDate":"2020-11-13 17:55:56"
//   }
//   ]

const CALENDAR_DEFAULT_NAME: string = "UNIG";

const getMonday = (d: Date) => {
  d = new Date(d);
  var day = d.getDay(),
    diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
  return new Date(d.setDate(diff));
};

export const getDeviceCalendars = async (): Promise<ICalendar[]> => {
  const userCalendars: ICalendar[] = await Calendar.listCalendars().then(
    (calendars) => calendars
  );
  return userCalendars;
};

export const getDeviceCalendarEvent = async (
  calendarName: string = CALENDAR_DEFAULT_NAME
): Promise<IOSEvent[] | string> => {
  const userCalendars = await getDeviceCalendars();
  const AppCalendar = userCalendars.find(
    (calendar) => calendar.name === calendarName
  );
  let userEvents: IOSEvent[] = [];
  let error: string = "";
  if (AppCalendar) {
    await Calendar.listEventsInRange(
      // this returns all events neverthless the calendar, but the objects have a calendar atrr which is the name of it
      getMonday(new Date()),
      moment().startOf("isoWeek").add(1, "week").toDate() // get from monday to next week
    ).then(
      (evt: IOSEvent[]) =>
        (userEvents = evt.filter((e) => e.calendar === calendarName))
    );

    // await Calendar.findAllEventsInNamedCalendar(AppCalendar.name) //this doesn't load events happening in the past!!!!
    //   .then((events) => {
    //     userEvents = events as IOSEvent[];
    //
    //
    //
    //   })
    //   .catch((err) => {
    //
    //     error = err;
    //   });
  }
  return userEvents.length > 0 ? userEvents : error;
};

export const addDeviceCalendarEvent = async (
  event: Meeting,
  deviceCalendar: string = CALENDAR_DEFAULT_NAME
) => {
  const { title, location, start, end, note } = event;
  const calOptions: CalendarOptions = await Calendar.getCalendarOptions();
  calOptions.calendarName = deviceCalendar;

  await Calendar.createEventWithOptions(
    title,
    location,
    // notes ? parseNotesToString(notes) : undefined,
    note,
    start,
    end,
    calOptions
  )
    .then((success) => success)
    .catch((err) => err);
};

export const editDeviceCalendarEvent = async (
  previousEvent: Meeting,
  newEvent: Meeting,
  deviceCalendar: string = CALENDAR_DEFAULT_NAME
) => {
  const filterOptions: CalendarOptions = await Calendar.getCalendarOptions();
  filterOptions.calendarName = deviceCalendar;
  await Calendar.modifyEventWithOptions(
    previousEvent.title,
    previousEvent.location,
    // previousEvent.notes ? parseNotesToString(previousEvent.notes) : undefined,
    previousEvent.note,
    previousEvent.start,
    previousEvent.end,
    newEvent.title,
    newEvent.location,
    // newEvent.notes ? parseNotesToString(newEvent.notes) : undefined,
    newEvent.note,
    newEvent.start,
    newEvent.end,
    filterOptions
  )
    .then((success) => success)
    .catch((err) => err);
};

export const removeDeviceCalendarEvent = async (event: Meeting) => {
  const { title, start, end, note, location } = event;
  await Calendar.deleteEventFromNamedCalendar(
    title,
    location,
    // notes ? parseNotesToString(notes) : undefined,
    note,
    start,
    end,
    CALENDAR_DEFAULT_NAME
  )
    .then((succ) => succ)
    .catch((err) => err);
};
