import React from "react";
import { IonBackButton, IonContent, IonPage } from "@ionic/react";
import { useTranslation } from "react-i18next";
const ErrorComponent: React.FC<{
  error: string;
}> = (props: {
  error: string;
}) => {
  const {
    t
  } = useTranslation();
  return <>
      <IonPage>
        <IonContent>
          <div style={{
          textAlign: "center",
          marginTop: "20%"
        }} className="ion-align-items-center">
            <h1>{t("GenericErrorMessage")} </h1>
            <code>{t("Error")} {props.error}</code>
            <IonBackButton defaultHref="leads">{t("GoBack")} </IonBackButton>
          </div>
        </IonContent>
      </IonPage>
    </>;
};
export default ErrorComponent;