import { PreFilledOffer } from "../../models";
import { parseToChoosenAttribute } from "../../components/ProductCard/helpers";
import {
  getChoosenProduct,
  readyToBeSent,
} from "../../components/OfferForm/helpers";
import { flattenDeep, flatten } from "lodash";
import { useEffect, useState } from "react";
import {
  getDNA,
  getAttributeGroups,
} from "../../components/OfferForm/offerCalculation";
import { Configs } from "../../components/OfferForm/OfferForm";

const useValidateOffers = (offers: PreFilledOffer[], configs?: Configs) => {
  const [OffersState, setOffersState] = useState<boolean[]>([]);

  useEffect(() => {
    if (
      offers.length > 0 &&
      configs &&
      configs.attributes &&
      configs.pricelist &&
      configs.products
    ) {
      for (const offer of offers) {
        if (
          offer.offer_id === 0 ||
          offer.product_id === 0 ||
          offer.product_id === undefined
        )
          return;
        const { choosenAttributes, product } = parseToChoosenAttribute(
          offer,
          configs.attributes!,
          configs.products!,
          configs.pricelist!
        );
        const choosenProduct = getChoosenProduct(product);
        const hiddenAttributes = flatten(
          getDNA(
            configs?.dna,
            choosenProduct?.id!,
            choosenAttributes,
            offer.width,
            offer.height,
            offer.width_2,
            offer.height_2,
            offer.width_a,
            offer.width_b,
            offer.width_c
          )
        );
        const ready = readyToBeSent(
          choosenProduct
            ? getAttributeGroups(configs?.attributes, choosenProduct.id).filter(
                (g) =>
                  !flattenDeep(hiddenAttributes).find(
                    //get dna
                    (a) => Number(a.group_id) === Number(g.id_attribute_group)
                  )
              )
            : [],
          choosenAttributes
        );

        setOffersState((prev) => [...prev, ready]);
      }
    }

    return () => {
      setOffersState([]);
    };
  }, [configs, offers]);

  return { data: OffersState };
};

export default useValidateOffers;
