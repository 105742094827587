import { InputLimits } from "../InputsMeasures/InputsMeasures";

interface Price {
  conversion_rate: string; // "0.80"
  conversion_rate_operator: string; // "*"
  height_from: string; //"220.10"
  height_to: string; // "230.00"
  id_product: string; // "29"
  price: string; //"1389.000000"
  width_from: string; // "220.10"
  width_to: string; //"230.00"
  calculated_price: number;
}
export interface ChoosenProduct {
  id: number;
  name: string;
  inputs: string[] | null;
  price: number;
  tax: number;
  mounting_price: number;
  limits?: InputLimits;
  product_type: string;
}

export type Attr = {
  id: number;
  price: number;
  mounting_price: number;
  attr_group: number;
  id_product_attribute: number;
  optional: boolean;
};

export interface ChoosenAttributesWithoutArray {
  position: number;
  attr: Attr;
}
export interface ChoosenAttributes {
  position: number;
  attr: Attr | Attr[];
}
export interface OfferAttributes {
  choosenProduct: ChoosenProduct;
  multipleAttributes?: { [key: number]: number[] };
  quantity: number;
  width?: number | string;
  width_a?: number | string;
  width_b?: number | string;
  width_c?: number | string;
  height?: number | string;
  width2?: number | string;
  height2?: number | string;
  comment?: string;
  choosenAttributes: ChoosenAttributes[];
  readyToSend: boolean;
  finalPrice: number;
  manual?: {
    production: string;
    customer: string;
    width: number;
    price: number;
    id_manual_manufacturer: number;
  };
}

export interface Attribute {
  conversion_rate_attribute: string;
  id_attribute: string;
  id_product_attribute: string;
  is_default: string;
  mounting_price: string;
  position: string;
  price_impact: string;
  price_impact_value: string;
  price_impact_value_old: string;
  public_name: string;
  fabric_price?: string;
}

export interface GroupAttribute {
  group_position: string;
  group_public_name: string;
  hidden: string;
  id_attribute_group: string;
  id_bracket: null | string;
  id_shape: string;
  is_color_group: string;
  is_dynamic: string;
  multiselectable_group: string;
  optional: null | string;
  quantity_enabled: string;
  attributes: {
    [index: number]: Attribute;
  }[];
}

const attributesToHide = (
  sub_conditions: any,
  attributesSelected: ChoosenAttributesWithoutArray[],
  width?: number,
  height?: number,
  width2?: number,
  height2?: number,
  width_a?: number,
  width_b?: number,
  width_c?: number,
  width_prefix?: string
) => {
  const listOfHiddenAttr: {
    id: number;
    group_id: number;
    attr_id: number;
  }[] = [];
  var allConditionsAreTrue = true;
  let index = 0;
  for (
    index;
    index < Object.values(sub_conditions.subconditions).length;
    index++
  ) {
    const sub_condition_values: any = Object.values(
      sub_conditions.subconditions
    )[index];
    if (sub_condition_values.type === "dimension_restriction") {
      //skip this
      return;
    }
    if (sub_condition_values.type === "id_attribute") {
      switch (sub_condition_values.operator) {
        case "=":
          const attr = attributesSelected.some((a) => {
            return Number(a.attr.id) === Number(sub_condition_values.value);
          });
          if (attr) {
            break;
          } else {
            allConditionsAreTrue = false;
          }
          break;
        case "!=":
          // if any of the attributes selected is this, pass
          const attr2 = !attributesSelected.find((a) => {
            return Number(a.attr.id) === Number(sub_condition_values.value);
          });
          if (attr2) {
            break;
          } else {
            allConditionsAreTrue = false;
          }
          break;
        default:
          break;
      }
    } else if (
      sub_condition_values.type === "width" ||
      sub_condition_values.type === "height" ||
      sub_condition_values.type === "height_2" ||
      sub_condition_values.type === "width_2" ||
      sub_condition_values.type === "width_a" ||
      sub_condition_values.type === "width_b" ||
      sub_condition_values.type === "width_c" ||
      sub_condition_values.type === "width_prefix" ||
      sub_condition_values.type === "square_meters" ||
      sub_condition_values.type === "max_angle" ||
      sub_condition_values.type === "width_width_2_diff" ||
      sub_condition_values.type === "height_2_min"
    ) {
      let square_meters: number | undefined;
      let side_a: number | undefined;
      let side_b: number | undefined;
      let side_c: number | undefined;
      let current_angle: number | undefined;

      width = width ? width : 0;
      height = height ? height : 0;
      width2 = width2 ? width2 : 0;
      height2 = height2 ? height2 : 0;
      square_meters = Number(((width / 100) * (height / 100)).toFixed(2));
      side_a = width > width2 ? width - width2 : width2 - width;
      side_b = height > height2 ? height - height2 : height2 - height;
      side_c = Math.sqrt(Math.pow(side_a, 2) + Math.pow(side_b, 2));
      // Resolve the slope via sin (convert from rad to degrees)
      current_angle = Number(
        Math.round(90 - Math.asin(side_a / side_c) * (180 / Math.PI)).toFixed(0)
      );
      let original_dimension: number | undefined;
      let percentage_calc: number | undefined;
      let condition_formula: boolean;
      switch (sub_condition_values.type) {
        case "width":
          original_dimension = width;
          break;
        case "width_2":
          original_dimension = width2;
          break;
        case "height":
          original_dimension = height;
          break;
        case "height_2":
          original_dimension = height2;
          break;
        case "width_a":
          original_dimension = width_a;
          break;
        case "width_b":
          original_dimension = width_b;
          break;
        case "width_c":
          original_dimension = width_c;
          break;
        case "square_meters":
          original_dimension = square_meters;
          break;
        case "max_angle":
          original_dimension = isNaN(current_angle) ? 0 : current_angle;
          break;
        case "height_2_min":
          original_dimension = width2;
          break;
        case "width_width_2_diff":
          original_dimension = width && width2 ? width - width2 : 0;
        // case "width_prefix":
        //   original_dimension = width_prefix; // this has a dropdown to choose fixed widths from it
        //   break;
      }
      if (
        sub_condition_values.operator === "%" &&
        (original_dimension || original_dimension === 0) &&
        height
      ) {
        percentage_calc = (original_dimension / height) * 100;
        allConditionsAreTrue =
          percentage_calc < sub_condition_values.value ? true : false;
        return allConditionsAreTrue;
      } else {
        if (
          sub_condition_values.operator === "=" &&
          (original_dimension || original_dimension === 0)
        ) {
          sub_condition_values.operator = "==";
        }
        // Compare dimension
        // sometimes the operator is ""
        const formulaToEval =
          Number(original_dimension) +
          sub_condition_values.operator +
          Number(sub_condition_values.value);
        // eslint-disable-next-line no-eval
        condition_formula = eval(formulaToEval); // check later if can change to not use eval
        if (!condition_formula) {
          allConditionsAreTrue = false;
          return allConditionsAreTrue;
        }
      }
    } else if (sub_condition_values.type === "nothing_attribute_group") {
      if (sub_condition_values.operator === "=") {
        sub_condition_values.operator = "==";
      }
      allConditionsAreTrue = !!attributesSelected.find(
        (a) => Number(a.attr.id) === 0
      );
      if (!allConditionsAreTrue) {
        return allConditionsAreTrue;
      }
    }
  } // end of for

  if (allConditionsAreTrue) {
    const id_attrs: number[] = Object.values(
      sub_conditions.to_hide
    )[0] as number[];
    const id_attrs_group: number[] = Object.values(
      sub_conditions.to_hide
    )[1] as number[];

    id_attrs &&
      id_attrs.length > 0 &&
      id_attrs.forEach((id) => {
        listOfHiddenAttr.push({
          id: id,
          attr_id: id,
          group_id: 0,
        });
      });
    id_attrs_group &&
      id_attrs_group.length > 0 &&
      id_attrs_group.forEach((id) =>
        listOfHiddenAttr.push({
          id: id,
          attr_id: 0,
          group_id: id,
        })
      );
    return listOfHiddenAttr;
  }
};

export const getDNA = (
  dna: any,
  product_id: number,
  attrs: any[],
  width?: number,
  height?: number,
  width2?: number,
  height2?: number,
  width_a?: number,
  width_b?: number,
  width_c?: number
) => {
  // var startTime = performance.now();

  const dna_attr: any = dna;
  const product_sub_conditions = dna_attr[product_id];
  const allSubConditions: any[] = product_sub_conditions
    ? Object.values(product_sub_conditions)
    : [];

  const allAttrsToHide: any[] = [];
  allSubConditions.forEach((sub_condition: any) => {
    //     0:
    //      subconditions:
    //      588: {type: "square_meters", operator: ">", value: 5}
    //      to_hide:
    //        id_attribute: [1353]
    //        id_attribute_group: []
    //iterate through all sub_conditions and further down check if type === id_attribute, the value is the attribute id currently selected
    // if it's true either hide the attr value !== 0 or the whole group
    const attrToHide = attributesToHide(
      sub_condition,
      attrs,
      width,
      height,
      width2 ? width2 : 0,
      height2 ? height2 : 0,
      width_a ? width_a : 0,
      width_b ? width_b : 0,
      width_c ? width_c : 0
    );
    if (attrToHide) {
      allAttrsToHide.push(attrToHide);
    }
  });
  // var endTime = performance.now();

  return allAttrsToHide;
};

const compare = (a: any, b: any) => {
  if (Number(a.group_position) < Number(b.group_position)) return -1;
  if (Number(a.group_position) > Number(b.group_position)) return 1;
  return 0;
};

export const getAttribute = (
  attributes: any,
  product_id: number,
  group_id: number,
  attr_id: any
): Attribute | null => {
  const attr: any = attributes;
  // use group_position to display the form in the right positions
  const productExists: any = attr[product_id];
  if (productExists) {
    const groupExists: any = productExists[group_id];
    if (groupExists) {
      const hasAttr: any = attr[product_id][group_id].attributes[attr_id];
      if (hasAttr) {
        return hasAttr[0] as Attribute;
      }
    }
  }
  return null;
};

type AttributeGroups = {
  attributes: { [key: number]: any[] };
  group_position: number;
  group_public_name: string;
  hidden: number;
  id_attribute_group: number;
  id_bracket: null;
  id_shape: number;
  is_color_group: number;
  is_dynamic: number;
  multiselectable_group: number;
  optional: number;
  quantity_enabled: number;
};

export const getAttributeGroups = (
  attributes: any,
  product_id: number
): AttributeGroups[] => {
  const attr: any = attributes;
  // use group_position to display the form in the right positions
  const hasAttr = attr[product_id];
  if (hasAttr) {
    const attrGroups: AttributeGroups[] = Object.values(attr[product_id]);
    return attrGroups.sort(compare);
  }
  return [];
};

export const getAtrributeGroupByGroupId = (
  attributes: any,
  product_id: number,
  group_id: number
): GroupAttribute | null => {
  const attr: any = attributes;
  // use group_position to display the form in the right positions
  const hasAttr: GroupAttribute = attr[product_id][group_id];
  if (hasAttr) {
    //
    return hasAttr;
  }
  return null;
};
export const getFabricPrice = (
  isColorGroup: number,
  basicProductPrice: number,
  priceImpactValue: number,
  conversionRateAttribute: number,
  productTax: number
) => {
  const _tax_value = (100 + productTax) / 100;
  const fabric_price = isColorGroup
    ? Math.round(
        basicProductPrice! *
          (priceImpactValue / conversionRateAttribute) *
          _tax_value
      )
    : 0;
  return isNaN(fabric_price) ? 0 : fabric_price;
};
export const getProductAttributePrice = (
  product_price: number,
  price_impact: string,
  price_impact_value: number,
  conversion_rate: number,
  tax_value = 25,
  height?: number,
  fabric_price?: number
) => {
  const _tax_value = (100 + tax_value) / 100;
  switch (price_impact) {
    case "*":
      const result = Math.round(
        product_price * (price_impact_value / conversion_rate) * _tax_value
      );
      return result;
    case "+":
      return Math.round((price_impact_value / conversion_rate) * _tax_value);
    case "-":
      return -Math.round(price_impact_value);
    case "H":
      if (height) {
        return Math.round((height - 150) * _tax_value);
      }
      break;
    case "f":
      if (fabric_price !== undefined) {
        const result = Math.round(
          price_impact_value * (product_price + fabric_price)
        );

        return result;
      }
      break;
    default:
      return 0;
  }
};

export const getProductBasicPrice = (
  products: any[],
  pricelist: any,
  product_id: number,
  width?: number,
  height?: number,
  width_2?: number,
  height_2?: number,
  width_a?: number,
  width_b?: number,
  width_c?: number
) => {
  const productPrices: any = pricelist;
  //some products don't have width so i just need to get the price from the product
  if (
    !width &&
    !height &&
    !width_2 &&
    !height_2 &&
    !width_a &&
    !width_b &&
    !width_c
  ) {
    const _productObject = products.find((p) => p.id_product === product_id);
    return _productObject ? Number(_productObject.price) : 0;
  }
  const product: Price[] = productPrices[product_id];

  let _width = width ? width : 0;
  let _height = height ? height : 0;
  // # Basic initialization
  if (width && width_2) {
    _width = width_2 > width ? width_2 : width;
  }
  if (height && height_2) {
    _height = height_2 > height ? height_2 : height;
  }

  // # Extra initialization
  width_a = width_a ? width_a : 0;
  width_b = width_b ? width_b : 0;
  width_c = width_c ? width_c : 0;

  if (width_a > 0 || width_b > 0 || width_c > 0) {
    _width = width_a + width_b + width_c;
  }
  const productBasicPrice = product
    ? product.find(
        (p) =>
          Number(p.width_from) <= _width &&
          Number(p.width_to) >= _width &&
          Number(p.height_from) <= _height &&
          Number(p.height_to) >= _height
      )
    : 0;
  // # Return correct dimensions for price calculation

  return productBasicPrice ? productBasicPrice.calculated_price : undefined;
  // $dimensions_to_compare= array(
  //     'width' => (float)$width,
  //     'height' => (float)$height,
  // );
};
export const getFinalPrice = (basicProductPrice: number, attributes: any[]) => {
  let finalPrice = basicProductPrice;
  attributes
    .filter((a) => a !== undefined)
    .forEach((a) => {
      finalPrice += a.attr.price;
    });

  return finalPrice;
};
