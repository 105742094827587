import React from "react";
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import "./MountingsPage.css";
import { RouteComponentProps } from "react-router";
import { MountingsFeature } from "../../features";
interface Props extends RouteComponentProps {
  pageName: string;
}
const MountingsPage: React.FC<Props> = props => {
  return <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{props.pageName}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{props.pageName}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <MountingsFeature {...props} />
      </IonContent>
    </IonPage>;
};
export default MountingsPage;