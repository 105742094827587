import React, { createRef, useState } from "react";
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButton, IonButtons, IonIcon } from "@ionic/react";
import "./LeadsPage.css";
import { RouteComponentProps } from "react-router";
import { ModalAction } from "../../models/";
import { closeOutline, personAdd } from "ionicons/icons";
import { Modal, UserDetail } from "../../components/";
import { LeadsFeature } from "../../features/";
import { useDispatch, useSelector } from "react-redux";
import { addCustomer } from "../../features/Leads/redux/actions";
import { RootState } from "typesafe-actions";
import AddCustomerForm, { CustomerForm, SaveCustomerForm } from "../../components/AddCustomerForm/AddCustomerForm";
import { showNotification } from "../../features/Notification/redux/actions";
import { isString } from "lodash";
import { useTranslation } from "react-i18next";
interface Props extends RouteComponentProps {
  pageName: string;
}
const LeadsPageComponent: React.FC<Props> = props => {
  const {
    pageName
  } = props;
  const tabs = ["Detail", "Offers", "Documents"];
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [showModal, setShowModal] = useState(false);
  const {
    t
  } = useTranslation();
  const renderModal = () => {
    const button: ModalAction = {
      name: t("Close"),
      type: "button",
      fn: () => setShowModal(false),
      icon: closeOutline,
      buttonStyle: "clear"
    };
    return <Modal onClose={() => setShowModal(false)} isOpen={showModal} title={t("UserDetails")} actions={[button]} tabs={tabs} getSelectedTab={(selectedTab: string) => setSelectedTab(selectedTab)}>
        {selectedTab === "Detail" && <UserDetail user={null} colSize={6} />}
        <span>{t("None")}</span>
      </Modal>;
  };
  const [openCustomerModal, setOpenCustomerModal] = useState(false);
  const checkIfEmptyString = (string: string) => {
    return string.trim().length > 0 ? false : true;
  };
  const validateCustomer = (customer: CustomerForm) => {
    let isValid: boolean = true;
    const originalCustomer = customer;
    customer.cvr_ean = (t("NotFilled") as string);
    customer.company = (t("NotFilled") as string);
    Object.values(customer).forEach(value => {
      if (isString(value) && checkIfEmptyString(value)) {
        isValid = false;
        return;
      }
    });
    customer.company = customer.company === (t("NotFilled") as string) ? "" : customer.company;
    customer.cvr_ean = customer.cvr_ean === (t("NotFilled") as string) ? "" : customer.cvr_ean;
    customer.phone = Number(originalCustomer.phone);
    customer.country = Number(originalCustomer.country);
    return isValid;
  };
  const customerRef = createRef<SaveCustomerForm>();
  const customerModal = () => {
    const addNewCustomer: ModalAction = {
      type: "button",
      fn: () => {
        const customer = customerRef.current?.onSave().newCustomer;
        if (customer && validateCustomer(customer)) {
          setOpenCustomerModal(false);
          return dispatch(addCustomer.request({
            token: token!,
            customer: customer,
            fitter: {
              id: userID!,
              name: userName!
            }
          }));
        } else {
          dispatch(showNotification({
            color: "danger",
            message: t("ErrorRequiredFields"),
            type: "Toast"
          }));
        }
      },
      name: t("Add")
    };
    return <Modal title={t("NewCustomer")} isOpen={openCustomerModal} onClose={() => setOpenCustomerModal(false)} actions={[addNewCustomer]}>
        <AddCustomerForm ref={customerRef} />
      </Modal>;
  };
  const dispatch = useDispatch();
  const userID = useSelector((state: RootState) => state.authReducer.userID);
  const token = useSelector((state: RootState) => state.authReducer.token);
  const userName = useSelector((state: RootState) => state.authReducer.name);
  return <IonPage>
      {renderModal()}
      {customerModal()}
      <IonHeader>
        <IonToolbar>
          <IonTitle>{pageName}</IonTitle>
          <IonButtons slot="primary">
            <IonButton onClick={() => setOpenCustomerModal(true)}>
              <IonIcon slot="icon-only" icon={personAdd} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{pageName}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <LeadsFeature {...props} />
      </IonContent>
    </IonPage>;
};
const LeadsPage = React.memo(LeadsPageComponent);
export default LeadsPage;