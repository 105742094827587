import { IonList, IonItem, IonSelect, IonSelectOption, IonLoading } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "typesafe-actions";
import { Modal } from "../../components";
import { showNotification } from "../Notification/redux/actions";
import { getMessages, sendSMS, clearSent } from "./redux/actions";
const SendSMSFeature: React.FC<{
  open: boolean;
  id_order: number;
  onClose: () => void;
}> = props => {
  const {
    open,
    id_order,
    onClose
  } = props;
  const token = useSelector((state: RootState) => state.authReducer.token);
  const loading = useSelector((state: RootState) => state.smsReducer.isLoading);
  const messages = useSelector((state: RootState) => state.smsReducer.messages);
  const sent = useSelector((state: RootState) => state.smsReducer.sent);
  const dispatch = useDispatch();
  useEffect(() => {
    if (sent) {
      onClose();
      dispatch(clearSent());
    }
  }, [dispatch, onClose, sent]);
  useEffect(() => {
    dispatch(getMessages.request({
      token: token!
    }));
  }, [dispatch, token]);
  const [messageID, setMessageID] = useState<number>();
  return loading ? <IonLoading isOpen={loading} /> : <>
      {messages.length > 0 ? <Modal isOpen={open} onClose={() => onClose()} title={"Send SMS"} actions={[{
      name: "Close",
      type: "button",
      buttonStyle: "clear",
      fn: () => onClose()
    }, {
      name: "Send SMS",
      type: "button",
      fn: () => {
        if (messageID) {
          return dispatch(sendSMS.request({
            token: token!,
            id_order,
            id_message: messageID
          }));
        } else {
          dispatch(showNotification({
            message: "Choose a message",
            color: "warning",
            type: "Toast"
          }));
        }
      }
    }]}>
          <IonList>
            <IonItem>
              <IonSelect interface="popover" onIonChange={e => setMessageID(e.detail.value)} placeholder="Select message">
                {messages.filter(m => m.type === "sms").map(message => <IonSelectOption key={message.id_message} value={message.id_message}>
                      {message.message}
                    </IonSelectOption>)}
              </IonSelect>
            </IonItem>
          </IonList>
        </Modal> : <Modal isOpen={open} onClose={onClose} title="Send SMS">
          <h4 style={{
        textAlign: "center"
      }}>No available messages</h4>
        </Modal>}
    </>;
};
export default SendSMSFeature;