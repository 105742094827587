import { combineReducers } from "redux";
import { authReducer } from "../features/Login/redux/reducer";
import { leadsReducer } from "../features/Leads/redux/reducer";
import { detailsReducer } from "../features/Details/redux/reducer";
import { calendarsReducer } from "../features/Calendars/redux/reducer";
import { mountingsReducer } from "../features/Mountings/redux/reducer";
import { settingsReducer } from "../features/Settings/redux/reducer";
import { notificationReducer } from "../features/Notification/redux/reducer";
import { offerDetailReducer } from "../features/OfferDetail/redux/reducer";
import { dashboardReducer } from "../features/Dashboard/redux/reducer";
import { smsReducer } from "../features/SendSMS/redux/reducer";
import { RootAction } from "typesafe-actions";

const appReducer = combineReducers({
  smsReducer,
  authReducer,
  leadsReducer,
  detailsReducer,
  calendarsReducer,
  mountingsReducer,
  settingsReducer,
  notificationReducer,
  offerDetailReducer,
  dashboardReducer,
});

export type RootState = ReturnType<typeof appReducer> | undefined;

const rootReducer = (state: RootState, action: RootAction) => {
  if (action.type === "login/LOGOUT_REQUEST") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
