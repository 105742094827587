import { Epic } from "redux-observable";
import { concat, from, of } from "rxjs";
import { filter, catchError, switchMap, map } from "rxjs/operators";
import { RootAction, RootState, isActionOf } from "typesafe-actions";
import { UserSettingsAPI, UserSettingsAPIDTO } from "../../../models";
import { loginAction } from "../../Login/redux/actions";
import { showNotification } from "../../Notification/redux/actions";
import {
  getUser,
  setCalendar,
  updateUser,
  updateUserPassword,
} from "./actions";
import { apiVersionWrapper } from "../../../helpers/api";

const getUserAPI = (token: string, id_user: number) => {
  const API_URL = `${process.env.REACT_APP_API_URL}getUser/${id_user}/force`;
  return apiVersionWrapper(API_URL, "GET", {
    Authorization: `Bearer ${token}`,
  }).pipe(
    map((response) => {
      const user: UserSettingsAPI = response.response[0];
      return user;
    }),
    catchError((error: Error) => {
      return of(error);
    })
  );
};

const updateUserAPI = (token: string, user: UserSettingsAPIDTO) => {
  const API_URL = `${process.env.REACT_APP_API_URL}updateUser`;
  return apiVersionWrapper(
    API_URL,
    "PUT",
    {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    JSON.stringify({
      name: user.name,
      lastname: user.lastname,
      company: user.company,
      address1: user.address1,
      city: user.city,
      postcode: user.postcode,
      id_country: Number(user.id_country),
      phone: Number(user.phone),
    })
  ).pipe(
    map((response) => {
      const allOk: boolean = response.response;
      return allOk;
    }),
    catchError((error: Error) => {
      return of(
        showNotification({
          color: "danger",
          message: "Error: " + error.message,
          type: "Toast",
        })
      );
    })
  );
};

const updateUserPasswordAPI = (token: string, password: string) => {
  const API_URL = `${process.env.REACT_APP_API_URL}updatePassword`;
  return apiVersionWrapper(
    API_URL,
    "PUT",
    {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    JSON.stringify({
      password: password,
      password_check: password,
    })
  ).pipe(
    map((response) => {
      const allOk: boolean = response.response;
      return allOk;
    }),
    catchError((error: Error) => {
      return of(error);
    })
  );
};

export const getUserSettingsEpic: Epic<RootAction, RootAction, RootState> = (
  action$
) =>
  action$.pipe(
    filter(isActionOf(getUser.request)),
    switchMap((action) => {
      return from(
        getUserAPI(action.payload.token, action.payload.id_user)
      ).pipe(
        switchMap((rsp) => of(getUser.success(rsp as UserSettingsAPI))),
        catchError((err) => of(getUser.failure(err)))
      );
    }),
    catchError((err) => of(getUser.failure(err)))
  );

export const updateUserSettingsEpic: Epic<RootAction, RootAction, RootState> = (
  action$
) =>
  action$.pipe(
    filter(isActionOf(updateUser.request)),
    switchMap((action) => {
      return from(
        updateUserAPI(action.payload.token, action.payload.newUserSettings)
      ).pipe(
        switchMap((rsp) => {
          if (typeof rsp === "boolean") {
            return concat(
              of(updateUser.success(action.payload.newUserSettings)),
              of(
                showNotification({
                  color: "success",
                  message: "Updated successfully",
                  type: "Toast",
                })
              )
            );
          } else {
            return of(
              showNotification({
                color: "warning",
                message: "Couldn't update, check required fields",
                type: "Toast",
              })
            );
          }
        }),
        catchError((err) => of(updateUser.failure(err)))
      );
    }),
    catchError((err) => of(updateUser.failure(err)))
  );
export const updateUserPasswordSettingsEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = (action$) =>
  action$.pipe(
    filter(isActionOf(updateUserPassword.request)),
    switchMap((action) => {
      return from(
        updateUserPasswordAPI(action.payload.token, action.payload.newPassword)
      ).pipe(
        switchMap((rsp) => {
          if (typeof rsp === "boolean") {
            return concat(
              of(updateUserPassword.success(action.payload.newPassword)),
              of(
                loginAction.request({
                  email: action.payload.email,
                  password: action.payload.newPassword,
                })
              )
            );
          } else {
            return of(
              showNotification({
                color: "danger",
                message: "Password not updated",
                type: "Toast",
              })
            );
          }
        }),
        catchError((err) => of(updateUserPassword.failure(err)))
      );
    }),
    catchError((err) => of(updateUserPassword.failure(err)))
  );

export const setCalendarEpic: Epic<RootAction, RootAction, RootState> = (
  action$
) =>
  action$.pipe(
    filter(isActionOf(setCalendar.request)),
    switchMap((action) => {
      // call to api then
      return of(setCalendar.success(action.payload));
    }),
    catchError((err) => of(setCalendar.failure(err)))
  );
