import { createAsyncAction } from "typesafe-actions";
import { UserSettingsAPI, UserSettingsAPIDTO } from "../../../models";

export const setCalendar = createAsyncAction(
  "user/SET_CALENDAR_REQUEST",
  "user/SET_CALENDAR_SUCCESS",
  "user/SET_CALENDAR_FAILURE"
)<string, string, Error | string>();

export const getUser = createAsyncAction(
  "user/GET_USER_REQUEST",
  "user/GET_USER_SUCCESS",
  "user/GET_USER_FAILURE"
)<{ token: string; id_user: number }, UserSettingsAPI, Error | string>();

export const updateUser = createAsyncAction(
  "user/UPDATE_USER_REQUEST",
  "user/UPDATE_USER_SUCCESS",
  "user/UPDATE_USER_FAILURE"
)<
  { token: string; newUserSettings: UserSettingsAPIDTO },
  UserSettingsAPIDTO,
  Error | string
>();

export const updateUserPassword = createAsyncAction(
  "user/UPDATE_USER_PASSWORD_REQUEST",
  "user/UPDATE_USER_PASSWORD_SUCCESS",
  "user/UPDATE_USER_PASSWORD_FAILURE"
)<
  { token: string; newPassword: string; email: string },
  string,
  Error | string
>();
