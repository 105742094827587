import React from "react";
import { useTranslation } from "react-i18next";
import { flatten } from "lodash";
import { RowAction, NewLeads, Measurring, OfferPending, OfferSent, PaymentWaiting, MountingPending } from "../../../models";
import { createHeaders } from "../../../helpers";
import { Row } from "../../../components/ResponsiveTable/ResponsiveTable";
const ResponsiveTable = React.lazy(() => import("../../../components/ResponsiveTable"));
type ColumnFilters = {
  rowName: string;
  filters: string[];
};
interface Props {
  leads: {
    newLeads: {
      data: NewLeads[];
      rowActions: RowAction<any>[];
      rows: Row<any>[];
      hideColumnByKeys: string[];
      onClickSecondaryActions?: (event: any) => void;
    };
    measuringLeads: {
      data: Measurring[];
      rowActions: RowAction<any>[];
      rows: Row<any>[];
      hideColumnByKeys: string[];
      onClickSecondaryActions?: (event: any) => void;
    };
    offerPendingLeads: {
      data: OfferPending[];
      rowActions: RowAction<any>[];
      rows: Row<any>[];
      hideColumnByKeys: string[];
      onClickSecondaryActions?: (event: any) => void;
    };
    offerSentLeads: {
      data: OfferSent[];
      rowActions: RowAction<any>[];
      rows: Row<any>[];
      hideColumnByKeys: string[];
      onClickSecondaryActions?: (event: any) => void;
    };
    paymentWaitingLeads: {
      data: PaymentWaiting[];
      rowActions: RowAction<any>[];
      rows: Row<any>[];
      hideColumnByKeys: string[];
      onClickSecondaryActions?: (event: any) => void;
    };
    mountingPendingLeads: {
      data: MountingPending[];
      rowActions: RowAction<any>[];
      rows: Row<any>[];
      hideColumnByKeys: string[];
      onClickSecondaryActions?: (event: any) => void;
    };
    deliveryPendingLeads: {
      data: any[];
      rowActions: RowAction<any>[];
      rows: Row<any>[];
      hideColumnByKeys: string[];
      onClickSecondaryActions?: (event: any) => void;
    };
    finishedLeads: {
      data: any[];
      rowActions: RowAction<any>[];
      rows: Row<any>[];
      hideColumnByKeys: string[];
      onClickSecondaryActions?: (event: any) => void;
    };
  };
  activeColumnFilters: ColumnFilters[];
  onSetColumnFilters: (fiters: ColumnFilters[]) => void;
  activeTabs: string[];
  onCollapse: (tableName: string) => void;
  onSort: (key: string) => void;
  isLoading: boolean;
  onClickRow: (row: Row<any>) => void;
  onClickShowNotes: (notes: any) => void;
  rowsWithEventsToday: Row<any>[];
}
type LeadsType = "newLeads" | "measuringLeads" | "offerPendingLeads" | "offerSentLeads" | "paymentWaitingLeads" | "mountingPendingLeads" | "deliveryPendingLeads" | "finishedLeads";
export default function Tables(props: Props) {
  const {
    leads,
    onSetColumnFilters,
    activeColumnFilters,
    activeTabs,
    onCollapse,
    onSort,
    isLoading,
    onClickRow,
    onClickShowNotes,
    rowsWithEventsToday
  } = props;
  const {
    t
  } = useTranslation();
  const getTableName = (key: LeadsType) => {
    switch (key) {
      case "newLeads":
        return t("NewLeads");
      case "measuringLeads":
        return t("Meassuring");
      case "offerPendingLeads":
        return t("OfferPending");
      case "offerSentLeads":
        return t("OfferSent");
      case "paymentWaitingLeads":
        return t("PaymentWaiting");
      case "mountingPendingLeads":
        return t("MountingPending");
      case "deliveryPendingLeads":
        return t("DeliveryPending");
      case "finishedLeads":
        return "Finished";
      default:
        return "";
    }
  };
  return <>
      {Object.values(leads).map((lead, i) => {
      const tableName = getTableName((Object.keys(leads)[i] as LeadsType));
      return <ResponsiveTable key={i} onClickSecondaryActions={lead.onClickSecondaryActions ?? undefined} onSetColumnFilters={keys => {
        const clone = activeColumnFilters;
        const object = clone.findIndex(c => c.rowName === tableName);
        if (object !== -1) {
          clone[object].filters = keys;
        } else {
          clone.push({
            rowName: tableName,
            filters: keys
          });
        }
        onSetColumnFilters(clone);
      }} columnFilters={flatten(activeColumnFilters.filter(f => f.rowName === tableName).map(f => f.filters))} collapse={!!!activeTabs.find(tab => tab === tableName)} onCollapse={onCollapse} onSort={onSort} loading={isLoading} onClickRow={onClickRow} tableName={tableName} onClickShowNotes={r => onClickShowNotes((r.notes as string[]))} rowActions={lead.rowActions} columns={createHeaders(lead.data)} rows={lead.rows} stripped={i % 0 ? true : false} hideColumnByKeys={lead.hideColumnByKeys} rowsWithEventsToday={rowsWithEventsToday} />;
    })}
    </>;
}